import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Selectors from '../../selectors';
import * as Actions from '../../actions';
import { bindActionCreators } from 'redux';

class VirtualConversationPanel extends Component {

    render() {
        const {
            conversationId,
            accessToken,
            apiUrl,
        } = this.props;

        return (
            <virtual-conversation-fragment
                conversationid={conversationId}
                campaignapiurl={apiUrl}
                style={{ "height": "calc(100% - 30px)", "zIndex": "0" }}
                token={accessToken}>
            </virtual-conversation-fragment>
        );
    }

    static propTypes = {
        conversationid: PropTypes.string,
        accessToken: PropTypes.string.isRequired,
        apiUrl: PropTypes.string.isRequired,
        panelId: PropTypes.string.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {
    const { customerId, conversationId } = Selectors.getDataForCustomerPanel(state, ownProps.panelId);
    const accessToken = Selectors.backEndAccessToken(state);
    const apiUrl = Selectors.getCampaignManagerUrl(state);
    return {
        accessToken,
        apiUrl,
        conversationId,
    };
};

export default connect(mapStateToProps)(VirtualConversationPanel);
