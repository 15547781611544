import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

import API from '../../api/Api';
import { ICONS } from '../../data/icons';

import messages from '../HeaderComposed/HeaderComposed.messages';

import { getCurrentLayoutIdOfPanelSet, getAvailablePanelSetLayouts, getPanelSetById, getWorkItemsV2 } from '../../selectors';
import * as Actions from '../../actions';

import PanelTabsSubmenu from '../../components/PanelTabsSubmenu/PanelTabsSubmenu';
import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';

import './PanelSetHeader.css';

/**
 * This react component sits between the PanelSet and the Panel in the react tree.
 */
class PanelSetHeader extends PureComponent {

	render() {
		const { intl, panelSetId, panelSetColor, currentLayout, availableLayouts, extractable, label, panelSetStatus, onExtract, onChangeLayout, cancelCallLookupReserveButtonEnabled, onCloseCallLookup, workItemId } = this.props;
		const { formatMessage } = intl;

		// No need for case header on dashboard
		if (_.includes([API.panelSet.statuses.dashboard, API.panelSet.statuses.request], panelSetStatus)) return null;

		const classes = classNames({
			'case-header': true,
			'case-header--incoming': panelSetStatus === API.panelSet.statuses.incoming,
			[`case-header--color-${panelSetColor}`]: !_.isNil(panelSetColor)
		});

		return (
			<div className={classes}>
				<div id={"panelSet_" + panelSetId} className="case-header__label">{label}</div>

				{
					extractable &&
					<div className="case-header__extract">
						<ActionButton id={"removeTaskFromStage_" + panelSetId} clickHandler={() => onExtract(panelSetId)} icon={ICONS.extract} label="" buttonStyle={[BUTTON_STYLE.transparent]} />
					</div>
				}

				{
					availableLayouts.length > 0 &&
					<div className="case-header__layout-mode">
						<PanelTabsSubmenu icon={ICONS['layout_' + currentLayout.toLowerCase()]}>
							{
								_.map(availableLayouts, (layoutId) => {
									const layoutName = formatMessage(messages['HeaderComposed_Layout_' + layoutId]);
									return (
										<ActionButton clickHandler={() => onChangeLayout(panelSetId, layoutId)} key={layoutId} icon={ICONS['layout_' + layoutId.toLowerCase()]} text={layoutName} buttonStyle={[BUTTON_STYLE.transparent]} />
									);
								})
							}
						</PanelTabsSubmenu>
					</div>
				}

				{
					cancelCallLookupReserveButtonEnabled &&
					<ActionButton id={"cancelCallLookupButton_" + panelSetId} clickHandler={() => { onCloseCallLookup(workItemId,'Cancelled','Automatically closed') }} icon={ICONS.close} label="Cancel Call" buttonStyle={[BUTTON_STYLE.transparent]} />
				}

			</div>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		label: PropTypes.string.isRequired,
		panelSetId: PropTypes.string,
		panelSetColor: PropTypes.number,
		currentLayout: PropTypes.string,
		availableLayouts: PropTypes.array,
		extractable: PropTypes.bool,
		panelSetStatus: PropTypes.string,
		onExtract: PropTypes.func,
		onChangeLayout: PropTypes.func
	};

	static defaultProps = {
		label: ''
	};
}

const mapStateToProps = (state, ownProps) => {

	const { panelSetId } = ownProps;
	const currentLayout = getCurrentLayoutIdOfPanelSet(state, panelSetId);
	const availableLayouts = getAvailablePanelSetLayouts(state, panelSetId) || [];


	const panelSet = getPanelSetById(state, panelSetId);
	var cancelCallLookupReserveButtonEnabled = false;
	const workItemId = panelSet[API.panelSet.workItemIdV2];

	if (workItemId) {
		const workItems = getWorkItemsV2(state);
		if (workItemId in workItems) {
			if (workItems[workItemId][API.workItemV2.workItemState] === API.workItemV2.workItemStates.lookUp) {
				cancelCallLookupReserveButtonEnabled = true;
			}
		}
	}

	return {
		currentLayout,
		availableLayouts,
		cancelCallLookupReserveButtonEnabled,
		workItemId
	};
};


const mapDispatchToProps = (dispatch) => {
	return {
		onExtract: bindActionCreators(Actions.extractPanelSet, dispatch),
		onChangeLayout: bindActionCreators(Actions.changePanelSetLayout, dispatch),
		onCloseCallLookup: bindActionCreators(Actions.requestCancelOutboundCall, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PanelSetHeader));