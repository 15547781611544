import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import Icon, {ICONS} from '../Icon/Icon';

import './Dropdown.css';

export const DROPDOWN_STYLE = {
	raised: 'raised',
	stroked: 'stroked',
	fullWidth: 'fullWidth'
};

const arrow = (
	<div className="dropdown__arrow">
		<Icon name={ICONS.downarrow} />
	</div>
);

class Dropdown extends PureComponent {

    render() {
        const {inputRef, value, defaultValue, options, label, styles, onChange} = this.props;
		const classes = classNames({
			'dropdown': true,
			'dropdown--full-width': _.includes(styles, DROPDOWN_STYLE.fullWidth)
		});

		const labelClasses = classNames({
			'dropdown-with-label': true,
			'dropdown-with-label--full-width': _.includes(styles, DROPDOWN_STYLE.fullWidth)
		});

		const select = (
			<select ref={inputRef} className="dropdown__select" onChange={onChange} value={value}>
				<option value={defaultValue} disabled hidden>{defaultValue}</option>
				{
					_.map(options, (val, key) => {
						return (<option value={key} key={'option_' + key}>{val}</option>);
					})
				}
			</select>
		);

		return (
			label ?
				(<label className={labelClasses}>
					<span className="dropdown-label">{label}</span>
					<span className="dropdown dropdown__select-container">
						{select}
						{arrow}
					</span>
				</label>)
				:
				<div className={classes}>
					{select}
					{arrow}
				</div>
		);
    }

    static propTypes = {
		value: PropTypes.string,
		label: PropTypes.string,
		options: PropTypes.object,
		styles: PropTypes.array,
		inputRef: PropTypes.func,
		onChange: PropTypes.func,
		defaultValue: PropTypes.string
    };
}

export default Dropdown;