import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

import {ICONS} from '../Icon/Icon';
import ActionButton, {BUTTON_STYLE} from '../ActionButton/ActionButton';

import './RolldownMenu.css';

class RolldownMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}

	close = () => {
		this.setState(() => ({open: false}));
	};

	handleClickOutside = (e) => {
		this.close();
	};

	togglePanel = () => {
		this.setState((prevState) => {
			return {open: !prevState.open};
		});
	};


	render() {
        const {text, children, label} = this.props;
		const {open} = this.state;

		const classes = classNames({
			'rolldown': true,
			'rolldown--open': open
		});


		return (
			<div className={classes}>
				<ActionButton id={"changeStatusButtonId"} clickHandler={this.togglePanel} text={text} label={label} icon={ICONS.downarrow} buttonStyle={[BUTTON_STYLE.reverseOrder]}/>

				<div className="rolldown__panel toolbar-shadow">
					{children}
				</div>
			</div>
		);
    }

    static propTypes = {
		value: PropTypes.string,
		options: PropTypes.object,
		text: PropTypes.string,
		label: PropTypes.string,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		])
    };
}

export default onClickOutside(RolldownMenu);