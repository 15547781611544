import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Selectors from '../../selectors';
import { connect } from 'react-redux';

class CallPanel extends Component {

    constructor(props) {
        super(props);
        this.fragmentRef = React.createRef();
    }

    componentDidMount() {
        this.fragmentRef.current.addEventListener("oncallstatechange", e => console.log(e));
    }

    render() {
        const { panelId, callControlUrl, accessToken, conversationId, directoryUrl, customerApiUrl, conversationApiUrl } = this.props;
        console.log("CallFragment ConversationId: ", conversationId);
        return <callcontrol-fragment
            id={panelId}
            conversationId={conversationId}
            apiUrl={callControlUrl}
            token={accessToken}
            ref={this.fragmentRef}
            style={{ "height": "calc(100% - 30px)" }}
            directoryApiUrl={directoryUrl}
            customerApiUrl={customerApiUrl}
            conversationApiUrl={conversationApiUrl}>
        </callcontrol-fragment>;
    }

    static propTypes = {
        accessToken: PropTypes.string.isRequired,
        callControlUrl: PropTypes.string.isRequired,
        conversationId: PropTypes.string,
        directoryUrl: PropTypes.string.isRequired,
        customerApiUrl: PropTypes.string.isRequired,
        conversationApiUrl: PropTypes.string.isRequired,
        panelId: PropTypes.string.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {
    const panelId = ownProps.panelId;
    const conversationId = Selectors.getDataForCallPanel(state, panelId);
    const callControlUrl = Selectors.getSIPConnectorUrl(state) + "/hubs/agenthub";
    const accessToken = Selectors.backEndAccessToken(state);
    const directoryUrl = Selectors.getAksApiUrl(state) + "/directory";
    const customerApiUrl = Selectors.getAksApiUrl(state) + "/customer-manager/v2";
    const conversationApiUrl = Selectors.getAksApiUrl(state) + "/conversation-monitor";
    return {
        callControlUrl,
        accessToken,
        directoryUrl,
        customerApiUrl,
        conversationId,
        conversationApiUrl
    };
};

export default connect(mapStateToProps)(CallPanel);
