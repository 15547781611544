import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, intlShape} from 'react-intl';
import ContainerDimensions from 'react-container-dimensions';
import _ from 'lodash';

import API from '../../api/Api';
import {BREAKPOINT_IDS} from '../../data/breakpoints';
import {STAGE_POSITON, LAYOUT_BY_ITEMS, PANEL_EXTENDS_TO_SIDE, PANEL_EXTENDS_TO_BOTTOM} from '../../data/grid';

import * as Selectors from '../../selectors';
import * as Actions from '../../actions';

import Panel from '../Panel/Panel';
import PanelSetPanelTabs from '../PanelSetPanelTabs/PanelSetPanelTabs';
import GridView from '../../components/GridView/GridView';
import GridItem from '../../components/GridItem/GridItem';
import {PANEL_ICONS} from '../../components/PanelIcon/PanelIcon';
import ICONS from '../../data/icons';
import './PanelSetPanels.css';

import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';
import messages from '../PanelSetPanelTabs/PanelSetPanelTabs.messages';
import PanelTabsSubmenu from '../../components/PanelTabsSubmenu/PanelTabsSubmenu';
import ReduxModels from '../../api/ReduxModels';

/**
 * This react component sits between the PanelSet and the Panel in the react tree.
 */
class PanelSetPanels extends PureComponent {

	addPanel = (panelType) => {
		this.props.onAddPanelToPanelSet(this.props.panelSetId, panelType);	
	}

	addPanelMenu = (side, index) => {

		const {intl, panelSetStatus, layout, availablePanelTypes, panelSetId} = this.props;
		const {formatMessage} = intl;
		const layoutId = layout.id;
		const addPanelDisabled = [API.panelSet.statuses.incoming, API.panelSet.statuses.request, API.panelSet.statuses.dashboard];
		const extendOptions = side === STAGE_POSITON.side ? PANEL_EXTENDS_TO_SIDE : PANEL_EXTENDS_TO_BOTTOM;
		const panelTabsSubmenuId = `panelTabsSubmenu_${side}_${index}`

		let button;

		if (availablePanelTypes.length && !_.includes(addPanelDisabled, panelSetStatus) && _.has(extendOptions, layoutId) && _.includes(extendOptions[layoutId], index)) {
			button = (
				<div className={`interactions__extend interactions__extend--${side}`} key={`add_interaction_menu_${side}`}>
					<PanelTabsSubmenu id={panelTabsSubmenuId} icon={ICONS.add} dividers={false} side={side}>
						{
							_.map(availablePanelTypes, (panelType) =>
								<ActionButton
									id={panelType+"_buttonId_"+panelSetId}
									key={'add_interaction_menuitem_' + panelType}
									clickHandler={() => {this.addPanel(panelType)}}
									icon={PANEL_ICONS[panelType]}
									text={formatMessage(messages['InteractionTab_' + panelType])}
									buttonStyle={[BUTTON_STYLE.transparent]}
								/>
							)
						}
					</PanelTabsSubmenu>
				</div>
			);
		}

		return button;
	};

	setDefaultLayout = (props) => {
		const {layout, panelSetId, panelGroups, onChangePanelSetLayout} = props;
		if (!layout || !layout.grid || layout.grid.length !== panelGroups.length) {
			onChangePanelSetLayout(panelSetId, LAYOUT_BY_ITEMS[panelGroups.length - 1][0]);
		}
	};

	activateDefaultTab = () => {
		const {currentPanel, panelSetId, panelGroups, onActivate} = this.props;

		// Activate first tab if nothing is active yet, or the active interaction is obsolete
		if (!currentPanel) {
			const panelGroupId = panelGroups[0][API.panel.id];
			const panelId = panelGroups[0][API.panel.panels][0];

			onActivate(panelSetId, panelGroupId, panelId);
		}
	};

	UNSAFE_componentWillMount() {
		this.setDefaultLayout(this.props);
		this.activateDefaultTab(this.props);
	}

	UNSAFE_componentWillUpdate(nextProps) {
		this.setDefaultLayout(nextProps);
	}

    render() {
        const {panelSetId, layout, panelSetColor, panelSetStatus, panelGroups, panels, onUpdateLayout} = this.props;

		// Build panels collection
		const panelContainers = _.reduce(panelGroups, (result, panelGroup, index) => {

			const panelsGroupId = panelGroup[API.panel.id];
			const groupPanels = _.transform(panelGroup[API.panel.panels], (result, id) => {
				result[id] = panels[id];
				return result;
			}, {});
			const activePanel = panels[panelGroup[API.panel.active]] || panels[panelGroup[API.panel.panels][0]];
			const activeType = activePanel[API.panel.type];

			const panelTabsEnabled = !_.includes([API.panelSet.statuses.incoming, API.panelSet.statuses.search], panelSetStatus);

			const addPanelSide =  this.addPanelMenu(STAGE_POSITON.side, index);
			const addPanelBottom = this.addPanelMenu(STAGE_POSITON.bottom, index);

			result.push(
				<GridItem key={panelsGroupId}>
					{
						panelTabsEnabled &&
							<ContainerDimensions>
								<PanelSetPanelTabs panelSetColor={panelSetColor} onlyGroup={panelGroups.length === 1} panelSetId={panelSetId} panelGroup={panelGroup} panels={groupPanels} activeType={activeType} />
							</ContainerDimensions>
					}
					<Panel panelId={activePanel[API.panel.id]} panelSetColor={panelSetColor} panelSetId={panelSetId} />
					{addPanelSide}
					{addPanelBottom}
				</GridItem>
			);
			return result;
		}, []);

		return (
			<div className="interactions">
				<ContainerDimensions>
					<GridView layout={layout} onUpdateLayout={(grid) => onUpdateLayout(panelSetId, grid)}>
						{panelContainers}
					</GridView>
				</ContainerDimensions>
			</div>
		);
    }

	static propTypes = {
		intl: intlShape.isRequired,
		panelSetId: PropTypes.string.isRequired,
		workItemId: PropTypes.string,
		panelGroups: PropTypes.array.isRequired,
		panels: PropTypes.object.isRequired,
		layout: PropTypes.object,
		panelSetColor: PropTypes.number,
		panelSetStatus: PropTypes.string,
		addpanelsideDisabled: PropTypes.bool,
		onActivate: PropTypes.func,
		onUpdateLayout: PropTypes.func,
		onChangePanelSetLayout: PropTypes.func,
	};
}

const mapStateToProps = (state, ownProps) => {

	//TODO: rewrite this code to use selectors (but not the buggy re-reselectors)
	const panelStore = state[ReduxModels.nodes.panel];
	const panelSet = state[ReduxModels.nodes.panelSet][ownProps.panelSetId];
	let panelGroups = panelSet[API.panelSet.panels];
	let currentPanel = Selectors.getUserInfo(state, API.user.currentPanel);

	// Flatten panels to tab layout
	if (state.browser.lessThan[BREAKPOINT_IDS.desktop]) {
		const panels = _.flattenDeep(_.map(panelGroups, API.panel.panels));

		panelGroups = [
			{
				[API.panel.id]: panelGroups[0][API.panel.id],
				[API.panel.active]: currentPanel,
				[API.panel.panels]: panels
			}
		];
	}

	// Select all required panels
	const panelIds = Selectors.getInteractionIds(state, ownProps.panelSetId);
	const panels = {};
	panelGroups.forEach(panelGroup => {
		panelGroup[API.panelGroup.panels].forEach(panel => {
			panels[panel] = panelStore[panel];
		})
	});

	if (!_.includes(panelIds, currentPanel)) {
		currentPanel = null;
	}

	const panelSetStatus = Selectors.getPanelSetInfo(state, ownProps.panelSetId, API.panelSet.status);

	const workItemId = ownProps.workItemId ? ownProps.workItemId : Selectors.getPanelSetWorkItemId(state, ownProps.panelSetId);


	const availablePanelTypes = Selectors.calculateAvailablePanelTypes(state, ownProps.panelSetId);



	

	return {
		panels,
		panelGroups,
		panelSetStatus,
		currentPanel,
		addpanelsideDisabled: state.browser.lessThan[BREAKPOINT_IDS.desktop],
		availablePanelTypes,
		workItemId
	}
};


const mapDispatchToProps = (dispatch) => {
	return {
		onActivate: bindActionCreators(Actions.activateTab, dispatch),
		onUpdateLayout: bindActionCreators(Actions.updateLayout, dispatch),
		onChangePanelSetLayout: bindActionCreators(Actions.changePanelSetLayout, dispatch),
		onAddPanelToPanelSet: bindActionCreators(Actions.addPanelToPanelSet, dispatch) 
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PanelSetPanels));