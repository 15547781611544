import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import timer from '../../hocs/Timer/Timer';

import './Timer.css';

class Timer extends Component {

	componentWillUnmount() {
		this.props.timer.stop();
	};

	start = () => {
		this.props.timer.start();
	};

	stop = () => {
		this.props.timer.stop();
	};

	resume = () => {
		this.props.timer.resume();
	};

	reset = (value) => {
		this.props.timer.reset(value);
	};

	render() {

		const {timer} = this.props;
		let {formatted} = timer;

		let classes = "";

		if(this.props.size == "")
		{
			classes = classNames({
				'timer': true,
				'timer--hours': true})
		} 
		else if(this.props.size == "small")
		{
			classes = classNames({
				'small-timer': true,
				'small-timer--hours': true})
		}
		else if(this.props.size == "top")
		{
			classes = classNames({
				'user-timer': true,
				'user-timer--hours': true})
		}

		return (
			<div className={classes}>{formatted}</div>
		);
	};

	static propTypes = {
		timer: PropTypes.object.isRequired,
		size: PropTypes.string
	};

	static defaultProps = {
		timer: {},
		size: ""
	};
}

export default timer(1000)(Timer);
export const PureTimer = Timer;
