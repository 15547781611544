import createCachedSelector from 're-reselect';

import API from '../api/Api';
import { getAgentProperties, getUserStatus } from './capability';
import { getSupervisorStatus, wasLogOutRequested } from './user';

const state = (state) => state;
const uiStateSelect = (state) => state.uiState;
const infoTypeSelect = (state, infoType) => infoType;

export const getUiStateInfo = createCachedSelector(
	[uiStateSelect, infoTypeSelect],
	(uiState, infoType) => uiState[infoType]
)(
	(state, infoType) => 'uiState_' + infoType
);

export const getInitialDataLoaded = createCachedSelector(
	[state],
	(state) => getUiStateInfo(state, API.uiState.initialDataLoaded)
)(
	() => API.uiState.initialDataLoaded
);

export const getUIProcess = createCachedSelector(
	[state],
	(state) => getUiStateInfo(state, API.uiState.uiProcess)
)(
	() => API.uiState.uiProcess
);

/**
 * Returns an array of every panel of the specified type.
 * Returns an empty array if none found
 * @param {*} state 
 * @param {*} panelType 
 */
export const getPanelsByType = (state, panelType) => {
	const panels = state[API.panel._key];
	const result = [];
	for (var panelId in panels){
		if (panels.hasOwnProperty(panelId)){
			if (panels[panelId][API.panel.type] === panelType){
				result.push(panels[panelId]);
			}
		}
	}
	return result;
};

export const loadSupervisorLoginCard = (state) => {
	return state[API.uiState._key][API.uiState.loadSuperVisorBreakCard];
}