import {createAction} from 'redux-act';

export const activateTab = createAction('Activate tab', (panelSetId, panelGroupId, panelId) => ({panelSetId, panelGroupId, panelId}));
export const closeTab = createAction('Close tab', (panelSetId, panelGroupId, panelId) => ({panelSetId, panelGroupId, panelId}));
export const activatePanelSet = createAction('Activate panel set', (panelSetId) => ({panelSetId}));
export const extractPanelSet = createAction('Extract panel set to new stage', (panelSetId) => ({panelSetId}));
export const changePanelSetLayout = createAction('Change panel set layout', (panelSetId, layoutId) => ({panelSetId, layoutId}));
export const swapGridItems = createAction('Swap grid items', (stageId, index, newIndex) => ({stageId, index, newIndex}));
export const addStageItem = createAction('Add stage item', (panelSetId, layoutId) => ({panelSetId, layoutId}));
export const changeLayout = createAction('Change layout', (stageId, layoutId) => ({stageId, layoutId}));
export const updateLayout = createAction('Update layout', (panelSetId, grid, stage) => ({panelSetId, grid, stage: stage}));
export const stageLayoutChanged = createAction('Stage layout changed', (stageId, layoutId) => ({stageId, layoutId}));
export const addPanelToPanelSet = createAction('Add panel to panelSet', (panelSetId, panelType) => ({panelSetId, panelType}));
