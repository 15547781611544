import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {IntlProvider, addLocaleData} from 'react-intl';
import hu from 'react-intl/locale-data/hu';
import de from 'react-intl/locale-data/de';

import API from '../../api/Api';

import {setLanguage} from '../../actions';
import DEFAULT_THEME from '../../data/themes';
import DEFAULT_LANGUAGE from '../../data/languages';

import Theme from '../../components/Theme/Theme';

/**
 * Contains the internationalization module and the Theme
 * Needs a redux store (Provider in the parent tree)
 */
class ThemeManager extends Component {

	constructor(props) {
		super(props);
		const {language, translations, dispatch} = this.props;

		addLocaleData([...hu, ...de]);

		if (language && !translations) {
			dispatch(setLanguage(language));
		}
	}

	render() {
		const {theme, language, translations, children} = this.props;

		// language + '_' + theme
		return (
			<IntlProvider key={language} locale={language} messages={translations}>
				<Theme theme={theme}>
					{children}
				</Theme>
			</IntlProvider>
		);
	}

	static propTypes = {
		theme: PropTypes.string.isRequired,
		language: PropTypes.string.isRequired,
		translations: PropTypes.object,
		dispatch: PropTypes.func,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		])
	};

	static defaultProps = {
		theme: DEFAULT_THEME,
		language: DEFAULT_LANGUAGE,
		translations: null
	};
}

const mapStateToProps = (state) => {
	return {
		theme: state[API.user._key][API.user.theme],
		language: state[API.user._key][API.user.language],
		translations: state.uiState.translations
	};
};

export default connect(mapStateToProps)(ThemeManager);
