import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../actions';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';
import Label from '../../components/Label/Label';

import './SupervisorBreakCard.css';
class SupervisorBreakCard extends Component {

    onRequestAgentMode = () =>{
        this.props.onCloseSuperVisorCard();
        
    }


    onRequestSuperVisorMode = () =>{
        this.props.onSupervisorBreakRequest();
    }


    render() {
        return (

            <div>
                <Popup position="center" open={true} modal={true}>
                    <h2 className={"supervisorbreakcardtitle"}>{"Please select an option"}</h2>
                    <ActionButton clickHandler={() => this.onRequestAgentMode()} text="Supervisor & Agent" buttonStyle={[BUTTON_STYLE.accept, BUTTON_STYLE.fullsize]}/>
                    <ActionButton clickHandler={() => this.onRequestSuperVisorMode()} text="Supervisor only" buttonStyle={[BUTTON_STYLE.accept, BUTTON_STYLE.fullsize]}/>
                </Popup>
            </div>
         
         );
        
    }

    static propTypes = {

    };
}
const mapDispatchToProps = (dispatch) => {
	return {
        onSupervisorBreakRequest: bindActionCreators(Actions.moveToSupervisorBreak, dispatch),
        onCloseSuperVisorCard: bindActionCreators(Actions.closeSupervisorBreakCard, dispatch)
	};
};
const mapStateToProps = (state, ownProps) => {

    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorBreakCard);
