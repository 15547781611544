import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import * as Selectors from '../../selectors';
import { guid } from "../../services/utils";

/**
 * Redux container for the customerView react component. Used when a customerview panel is rendered.
 */
class CustomerPanel extends Component {

  constructor(props) {
    super(props);
    this.fragmentRef = React.createRef();

    this.onSwap = this.onSwap.bind(this);
    this.onCall = this.onCall.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
  }

  componentDidMount() {
    this.fragmentRef.current.addEventListener("onSwap", this.onSwap);
    this.fragmentRef.current.addEventListener("onCall", this.onCall);
    this.fragmentRef.current.addEventListener("onCustomerChange", this.onCustomerChange);
  }

  componentWillUnmount() {
    this.fragmentRef.current.removeEventListener("onSwap", this.onSwap);
    this.fragmentRef.current.removeEventListener("onCall", this.onCall);
    this.fragmentRef.current.removeEventListener("onCustomerChange", this.onCustomerChange);
  }

  onCustomerChange(ev) {
    if (ev) {
      const { type, detail } = ev;
      if (ev.detail) {
        this.props.onCustomerDataReceived(ev.detail.customerId, ev.detail.name)
      }
    }
  }

  onSwap(ev) {
    //todo: rewrite this with redux-saga
    const newCustomerId = ev.detail.targetCustomer.customerId;
    const { workflowApiUrl, conversationId, accessToken } = this.props;
    if (!conversationId) {
      return;
    }
    fetch(`${workflowApiUrl}/api/conversation/${conversationId}/setData`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify([{ Key: 'CustomerId', Value: newCustomerId }]),
    });
  }

  onCall(ev) {
    const phoneNum = ev.detail.phone;
    const { sipUrl, conversationId, customerId, channelId, accessToken, hasCall } = this.props;
    if (hasCall) {
      this.props.onError({ id: guid(''), timestamp: new Date(), text: 'Error: There is an active call' });
      return;
    }
    if (!channelId) {
      this.props.onError({ id: guid(''), timestamp: new Date(), text: 'Error: No channelId' });
      return;
    }
    if (!conversationId) {
      this.props.onError({ id: guid(''), timestamp: new Date(), text: 'Error: No conversationId' });
      return;
    }
    fetch(`${sipUrl}/api/v1/call/initiate`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ ChannelId: channelId, ToNumber: phoneNum, ToCustomer: customerId, OriginalConversationId: conversationId }),
    })
      .then(async resp => {
        var txt = await resp.json();
        console.log(resp, txt);
        if (!resp.ok) {
          this.props.onError({ id: guid(''), timestamp: new Date(), text: `Error: ${txt.status} - ${txt.title}` });
        }
      });
  }

  render() {
    const {
      customerId,
      accessToken,
      apiUrl,
      historyApiUrl,
      directoryApiUrl,
      contentApiUrl,
      gumApiUrl,
      hasCall,
      isConversationClosed
    } = this.props;

    return (
      <customer-data-fragment
        ref={this.fragmentRef}
        customerid={customerId}
        apiurl={apiUrl}
        historyapiurl={historyApiUrl}
        contentapiurl={contentApiUrl}
        directoryapiurl={directoryApiUrl}
        gumapiurl={gumApiUrl}
        style={{ "height": "calc(100% - 30px)", "zIndex": "0" }}
        token={accessToken}
        disablephoneactions={hasCall}
        readonlymode={isConversationClosed}
      >
      </customer-data-fragment>
    );
  }

  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    historyApiUrl: PropTypes.string.isRequired,
    directoryApiUrl: PropTypes.string.isRequired,
    contentApiUrl: PropTypes.string.isRequired,
    workflowApiUrl: PropTypes.string.isRequired,
    gumApiUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string,
    conversationId: PropTypes.string,
    sipUrl: PropTypes.string.isRequired,
    channelId: PropTypes.string,
    hasCall: PropTypes.bool,
    isConversationClosed: PropTypes.bool,
    panelId: PropTypes.string.isRequired,
  };
}

const mapStateToProps = (state, ownProps) => {
  const { customerId, conversationId } = Selectors.getDataForCustomerPanel(state, ownProps.panelId);
  const channelId = Selectors.getChannelIdForConversation(state, ownProps.panelId);
  const accessToken = Selectors.backEndAccessToken(state);
  const aksUrl = Selectors.getAksApiUrl(state);
  const apiUrl = aksUrl + "/customer-manager";
  const directoryApiUrl = aksUrl + "/directory";
  const historyApiUrl = Selectors.getOdataUrl();
  const contentApiUrl = aksUrl + "/content/v2";
  const workflowApiUrl = Selectors.getWorkflowUrl(state);
  const gumApiUrl = Selectors.getGumApiUrl(state);
  const sipUrl = Selectors.getSIPConnectorUrl(state);
  const hasCall = Selectors.hasCall(state);
  const isConversationClosed = Selectors.isConversationClosed(state, ownProps.panelId);

  return {
    accessToken,
    apiUrl,
    historyApiUrl,
    directoryApiUrl,
    contentApiUrl,
    workflowApiUrl,
    gumApiUrl,
    customerId,
    conversationId,
    sipUrl,
    channelId,
    hasCall,
    isConversationClosed
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    onCustomerDataReceived: bindActionCreators(Actions.customerNameReceived, dispatch),
    onError: bindActionCreators(Actions.addMessage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPanel);
