/**
 * Describes how the redux store's first level of nodes look like.
 * 
 * Todo: replace the references to API.<nodeName>._key references to ReduxModels.<nodename> in the code for better clarity
 */

const ReduxModels = {

  //these are the top level nodes in the redux store
  nodes: {
    uiState: "uiState",
    panel: "panel",
    panelSet: "panelSet",
    user: "user",
    login: "login",
    backEnd: "backEnd",
    chat: "chat",
    email: "email",
    composingEmail: "composingEmail",
    breakNames: "breakNames",
    notifications: "notifications",
    environment: "environment",
    browser: "browser",
    scripting: "scripting",
    customerNames: "customerNames",
    messages: "messages",
  },

  scripting: {
    url: "url",
    loading: "loading"
  }


}

export default ReduxModels;