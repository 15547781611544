import {defineMessages} from 'react-intl';

const messages = defineMessages({


	LogoutRequest_title: {
		defaultMessage: 'Logout request',
		id: 'LogoutRequest_title',
		description: 'Request / logout request title'
	},

	LogoutRequest_instructions: {
		defaultMessage: 'Close all open tasks to complete your logout request',
		id: 'LogoutRequest_instructions',
		description: 'Request / instructions'
	},

	LogoutRequest_requestBreakButtonsTitle: {
		defaultMessage: 'Request break:',
		id: 'LogoutRequest_requestBreakButtonsTitle',
		description: 'Request / Request break buttons title'
	},

	BreakRequest_title: {
		defaultMessage: 'Break request',
		id: 'BreakRequest_title',
		description: 'Request / Break request title'
	},

	BreakRequest_instructions: {
		defaultMessage: 'Close all open tasks to complete your break request',
		id: 'BreakRequest_instructions',
		description: 'Request / instructions'
	},

	BreakRequest_requestBreakButtonsTitle: {
		defaultMessage: 'Change break request to:',
		id: 'BreakRequest_requestBreakButtonsTitle',
		description: 'Request / Request break buttons title'
	},

	BreakRequest_defaultBreakName: {
		defaultMessage: 'You\'re on break',
		id: 'BreakRequest_defaultBreakName',
		description: 'Request / Break title'
	},

	BreakRequest_cancel: {
		defaultMessage: 'Cancel request',
		id: 'BreakRequest_cancel',
		description: 'Request / Break title'
	},

	LogoutRequest_cancel: {
		defaultMessage: 'Cancel logout request',
		id: 'LogoutRequest_cancel',
		description: 'Request / Break title'
	}
});

export default messages;