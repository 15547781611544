import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { httpGet } from '../services/http';

const isValidUrl = val => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(val);

/**
 * request to grab the scripting url
 */
function* requestUrl(action) {
	const authHeader = yield select(Selectors.getAuthHeader);
	const baseUrl = (yield select(Selectors.getAksApiUrl)) + "/script-configurator/Conversation/";
	const WIConvPairs = yield select(Selectors.getExternalUrlThatNeedsToBeRetrieved);
	yield all(WIConvPairs.map(pair => call(getUrl, pair.workItemId, pair.conversationId, authHeader, baseUrl)));
}



function* getUrl(workItemId, conversationId, authHeader, baseUrl) {
	const backendUrl = baseUrl + conversationId + "/url";
	try {
		const result = yield call(httpGet, backendUrl, authHeader);
		if (result && result.url && isValidUrl(result.url)) {
			yield put(Actions.ScriptingUrlReceived(workItemId, result.url));
		}
	}
	catch (error) {
		console.log(error);
	}
}


export default function* () {

	yield takeLatest(Actions.stateDataRetrieved.getType(), requestUrl)
}
