export const AgentBusyWithChatInteraction = {
	"agentProperties": {
		"id": "5f0495a0a7bc9e000104d0ce",
		"state": "Busy",
		"breakCode": null
	},
	"messages": [],
	"workItems": {
		"bf9d3dddfe614f428daabfcb12858e2a": {
			"conversations": {
				"117819300c9f4bd591e8e45cd8f93fb4": {
					"conversationId": "117819300c9f4bd591e8e45cd8f93fb4",
					"channelType": "webchat"
				}
			},
			"workItemId": "bf9d3dddfe614f428daabfcb12858e2a",
			"customerId": null,
			"workItemState": "Connected",
			"stateSince": "0001-01-01T00:00:00"
		}
	}
}

export const AgentBusyWithVoice = {
	"agentProperties": {
		"id": "5f0495a0a7bc9e000104d0ce",
		"state": "Busy",
		"breakCode": null
	},
	"messages": [],
	"workItems": {
		"bf9d3dddfe614f428daabfcb12858e2a": {
			"conversations": {
				"2982d5de17524d00860469a1d219c220":
				{
					"conversationId": "2982d5de17524d00860469a1d219c220",
					"channelType": "virtual"
				},
				// "consult117819300c9f4bd591e8e45cd8f93fb4":
				// {
				// 	"conversationId": "consult117819300c9f4bd591e8e45cd8f93fb4",
				// 	"channelType": "voice"
				// }
			},
			"workItemId": "bf9d3dddfe614f428daabfcb12858e2a",
			"customerId": "adasdasdda",
			"workItemState": "Connected",
			"stateSince": "0001-01-01T00:00:00"
		}
	}
}

