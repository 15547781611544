import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';

class SupervisorPanel extends Component {

	render() {
		const { chatHubUrl, accessToken, directoryApiUrl, conversationMonitorApiUrl, contentApiUrl, callHubUrl, sipConnectorUrl, conversationApiUrl } = this.props;

		return (
			<supervisor-fragment
				id={"SuperVisorFragmentId"}
				chathuburl={chatHubUrl}
				token={accessToken}
				directoryapiurl={directoryApiUrl}
				conversationmonitorapiurl={conversationMonitorApiUrl}
				contentapiurl={contentApiUrl}
				callhuburl={callHubUrl}
				sipconnectorurl={sipConnectorUrl}
				conversationApiUrl={conversationApiUrl}
				style={{ "height": "calc(100% - 30px)" }}
			/>
		);
	}

	static propTypes = {
		conversationId: PropTypes.string
	};
}

const mapStateToProps = (state) => {
	const accessToken = Selectors.backEndAccessToken(state);
	const conversationMonitorApiUrl = Selectors.getAksApiUrl(state) + "/conversation-monitor";
	const directoryApiUrl = Selectors.getAksApiUrl(state) + "/directory";
	const contentApiUrl = Selectors.getDocumentStoreUrl(state) + "/api";
	const chatHubUrl = Selectors.getAksApiUrl(state) + "/chat/hub";
	const callHubUrl = Selectors.getSIPConnectorUrl(state) + "/hubs/agenthub";
	const sipConnectorUrl = Selectors.getSIPConnectorUrl(state) + "/api/v1.0";
	const conversationApiUrl = Selectors.getAksApiUrl(state) + "/conversation-monitor";
	return {
		directoryApiUrl,
		accessToken,
		conversationMonitorApiUrl,
		contentApiUrl,
		chatHubUrl,
		callHubUrl,
		sipConnectorUrl,
		conversationApiUrl,
	}

};

export default connect(mapStateToProps)(SupervisorPanel);
