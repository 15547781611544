import {put, call, takeLatest, all, select} from 'redux-saga/effects';

import API from '../api/Api';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import {httpGet, httpPost, httpPut, httpPatch, httpDelete} from '../services/http';
/**
 * request to retrieve break names
 * @param {*} action 
 */
export function* retrieveBreakNames(action) {

	try {

		// invoke BuzzPlus Management SVC APIs and retrieve the settings

		const blenderServiceUrl = yield select((state) => Selectors.getBlenderServiceUrl(state));
		const token = yield select((state) => Selectors.backEndAccessToken(state));

		if (blenderServiceUrl) {

			const breakNames = yield call(sendRequest, blenderServiceUrl + '/Resource/BreakNames?api-version=3', token, API.capability.methods.get);

			if(breakNames) {

                yield put(Actions.setBreakNames(breakNames));
                
                return;
			}
			else {
				console.log("'BreakNamesSaga", "No break names are retrieved from BlenderService");
			}
		}
		else {

			console.log("BreakNamesSaga", "Failed to retrieve break code. No BlenderService url is found");
		}

	} catch (error) {

		console.error("BreakNamesSaga", "Failed to retrieve break names. error:" + error);
    }
    
    yield put(Actions.setBreakNames(null));
}
/**
 * Sends a request to the given url with the given httpMethod with the accessToken attached to the header
 * @param {string} url 
 * @param {string} token 
 * @param {string} method 
 */
function* sendRequest(url, token, method, data=null) {

    var authHeaders = {};
    
    if (token) {
        authHeaders = {
			'Authorization' : 'Bearer ' + token,
			'access-token' : token
        };
    }


    if(data !== null) {
        authHeaders["content-type"] = "application/json";
    }

    try {
        switch(method) {

            case API.capability.methods.get:
                return yield call(httpGet, url, authHeaders);

            case API.capability.methods.post:
                return yield call(httpPost, url, data, authHeaders);

            case API.capability.methods.put:
                return yield call(httpPut, url, data, authHeaders);

            case API.capability.methods.patch:
                return yield call(httpPatch, url, data, authHeaders);

            case API.capability.methods.del:
                return yield call(httpDelete, url, authHeaders);

            default:
                console.log("BackEndConnection", "sendRequest - Unknown method requested", { method: method });
                break;
        }
    } catch (ex) {       
        throw ex;
    }
}


export default function* () {
	
	yield all([
		takeLatest(Actions.retrieveBreakNames.getType(), retrieveBreakNames),
	]);
}
