import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import Label from '../../components/Label/Label';
import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';

import './UserMessage.css';

class UserMessage extends PureComponent {

    render() {

        const {intl, message, timestamp, clickHandler, link} = this.props;

		const dismissButtonLabel = "Dismiss";

		let timeMoment = moment(timestamp).local();
		let timeString = intl.formatTime(timeMoment.toDate(), { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
		let redirect = link == '' ? null : <a target="_blank" href={link}> <h1 style={{color:"#4752b8"}}>[learn more]</h1></a>;

		return (
			<div className="usermessage">
				{timestamp && 
					<div className="usermessage--timestamp">
						<Label text={timeString} />
					</div>
				}
				<div className="usermessage--text">
				<Label text={message}/> {redirect}
				</div>
				{clickHandler && 
					<div className="usermessage--button">
						<ActionButton
							clickHandler={clickHandler}
							text={dismissButtonLabel}
							buttonStyle={[BUTTON_STYLE.primary, BUTTON_STYLE.raised]} />
					</div>
				}
			</div>
		);
    }

    static propTypes = {
		intl: intlShape.isRequired,
		message: PropTypes.string.isRequired,
		timestamp: PropTypes.object,
		clickHandler: PropTypes.func,
		link: PropTypes.string
    };

    static defaultProps = {
		intl: intlShape,
		message: '',
		link: '',
	}
}

export default injectIntl(UserMessage);
