import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { bindActionCreators } from 'redux';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import _ from 'lodash';

import API from '../../api/Api';

import messages from './PanelSetContainer.messages';

import { getPanelSet, getPanelSetInfo, getCustomerName } from '../../selectors';
import { activatePanelSet } from '../../actions';

import PanelSetHeader from '../PanelSetHeader/PanelSetHeader';
import PanelSetPanels from '../PanelSetPanels/PanelSetPanels';

import Config from '../../config/config';

import './PanelSetContainer.css';

/**
 * The react component that is rendered when you want to show a PanelSet and it's content
 */
class PanelSetContainer extends PureComponent {

	onDragStart = () => {
		this.props.onDragStart(this.props.panelSetId);
	};

	onDrag = () => {
	};

	onDragStop = (e, { node }) => {
		return this.props.onDragged(this.props.panelSetId, this.props.index, node); // Return false so that we can unmount or true to snap back
	};

	focusCase = () => {
		this.props.onActivatePanelSet(this.props.panelSetId);
	};

	render() {
		const { extractable, dragged, panelSetStatus, panelSetColor, panelSetId, layout, label, containerRef } = this.props;

		const classes = classNames({
			'panelset-container': true,
			'card-shadow': true,
			'capanelsetse-container--show-droptarget': dragged && dragged !== panelSetId
		});

		return (
			<Draggable
				axis="both"
				disabled={!extractable}
				handle=".panelset-header"
				cancel=".panelset-header .action-button"
				defaultPosition={{ x: 0, y: 0 }}
				position={{ x: 0, y: 0 }}
				onStart={this.onDragStart}
				onDrag={this.onDrag}
				onStop={this.onDragStop}>

				<div tabIndex={-1} onFocus={this.focusCase} ref={containerRef} className={classes} key={'panelsetcontainer_' + panelSetId}>
					<PanelSetHeader panelSetColor={panelSetColor} key={'panelsetheader_' + panelSetId} extractable={extractable} panelSetId={panelSetId} label={label} panelSetStatus={panelSetStatus} />
					<PanelSetPanels panelSetColor={panelSetColor} key={'panelsetpanels_' + panelSetId} panelSetId={panelSetId} layout={layout} />
					<div className="panelset-container__droptarget" />
				</div>

			</Draggable>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		panelSetId: PropTypes.string.isRequired,
		panelSetItem: PropTypes.object,
		extractable: PropTypes.bool,
		panelSetStatus: PropTypes.oneOf(_.values(API.panelSet.statuses)),
		layout: PropTypes.object,
		label: PropTypes.string,
		dragged: PropTypes.bool,
		panelSetColor: PropTypes.number,
		containerRef: PropTypes.func,
		onActivatePanelSet: PropTypes.func,
		onDragStart: PropTypes.func,
		onDragged: PropTypes.func,
		index: PropTypes.number
	};

	static defaultProps = {
		extractable: false
	};
}

const mapStateToProps = (state, ownProps) => {

	const { formatMessage } = ownProps.intl;
	const panelSetItem = getPanelSet(state, ownProps.panelSetId);

	const layout = panelSetItem[API.panelSet.layout];
	const panelSetStatus = getPanelSetInfo(state, ownProps.panelSetId, API.panelSet.status);
	const panelSetColor = getPanelSetInfo(state, ownProps.panelSetId, API.panelSet.color);
	const customerName = getCustomerName(state, panelSetItem[API.panelSet.workItemId]);

	let label;
	switch (panelSetStatus) {
		case API.panelSet.statuses.incoming:
			label = formatMessage(messages.CaseContainer_IncomingHeading);
			break;

		case API.panelSet.statuses.search:
			label = formatMessage(messages.CaseContainer_SearchCaseHeading);
			break;

		case API.panelSet.statuses.inprogress:
			label = customerName;
			break;

		default:
			{
				label = "";
			}
	}

	if (process.env.REACT_APP_LOGGING === 'true') {
		label = label + " panelSetId: " + panelSetItem[API.panelSet.id] + " workItemId: " + panelSetItem[API.panelSet.workItemId]
	}


	return {
		panelSetItem,
		panelSetStatus,
		panelSetColor,
		layout,
		label
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onActivatePanelSet: bindActionCreators(activatePanelSet, dispatch)
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PanelSetContainer));