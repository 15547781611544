import { defineMessages } from 'react-intl';

const messages = defineMessages({
	UserProfile_extension_abbreviated: {
		defaultMessage: 'extension',
		id: 'UserProfile_extension_abbreviated',
		description: 'Extension label'
	},
	UserProfile_agentId_abbreviated: {
		defaultMessage: 'agent id',
		id: 'UserProfile_agentId_abbreviated',
		description: 'Agent id label'
	},
});

export default messages;