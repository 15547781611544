const loadScriptFile = (url, name) => {
  if (url){
    const s = document.createElement('script');
    s.async = true;
    s.src = url;
    s.type="module"
    s.title=name
    document.body.appendChild(s);
  }
};


export default loadScriptFile;
