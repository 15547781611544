import axios from 'axios';

export const httpGet = (url, headers) => {

	let t_options = null;
	
	if(headers) {

		t_options = {
			method: 'get',
			url: url,
			headers: headers
		};
	}
	else {

		t_options = {
			method: 'get',
			url: url
		};
	}

	const options = t_options;

	return new Promise((resolve, reject) => {
		axios(options)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};

export const httpPost = (url, data, headers) => {
	
	let options = null;
	
	if(headers) {

		options = {
			headers: headers
		};
	}

	return new Promise((resolve, reject) => {
		axios.post(url, data, options)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};

export const httpPut = (url, data, headers) => {
	
	let options = null;
	
	if(headers) {

		options = {
			headers: headers
		};
	}

	return new Promise((resolve, reject) => {
		axios.put(url, data, options)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};

export const httpPatch = (url, data, headers) => {
	
	let options = null;
	
	if(headers) {

		options = {
			headers: headers
		};
	}

	return new Promise((resolve, reject) => {
		axios.patch(url, data, options)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};

export const httpDelete = (url, headers) => {
	
	let options = null;
	
	if(headers) {

		options = {
			headers: headers
		};
	}

	return new Promise((resolve, reject) => {
		axios.delete(url, options)
			.then(function (response) {
				resolve(response.data);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};
