import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxModels from '../../api/ReduxModels';
import Label from '../../components/Label/Label';
import * as Selectors from '../../selectors';

class ScriptingPanel extends Component {

    render() {
        const { url, loading, panelId } = this.props;
        
        if (loading){
            return (<Label text={"Loading..."} />)
        }
        else{
            if (url){
                return (<iframe title={panelId} src={url} style={{ "height": "calc(100% - 30px)" }} frameBorder="0" />)
            }
            else{

                return (<Label text={"Loading..."} />)
            }
        }
    }

    static propTypes = {
        panelId: PropTypes.string.isRequired,
    };
}

const mapStateToProps = (state, ownProps) => {

    const panelId = ownProps.panelId;
    const scriptingReduxObject = Selectors.getDataForScriptingPanel(state, panelId);
    
    return {
        loading: scriptingReduxObject? scriptingReduxObject[ReduxModels.scripting.loading] : false,
        url: scriptingReduxObject? scriptingReduxObject[ReduxModels.scripting.url] : null

    };
};

export default connect(mapStateToProps)(ScriptingPanel);
