import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DEFAULT_THEME from '../../data/themes';

import './Theme.css'

class Theme extends Component {
	render() {
		const { theme, children } = this.props;

		return (
			<div className={`theme theme-${theme}`}>
				{children}
			</div>
		);
	};

	static propTypes = {
		theme: PropTypes.string.isRequired
	};

	static defaultProps = {
		theme: DEFAULT_THEME
	};
}

export default Theme;
