import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, intlShape} from 'react-intl';
import _ from 'lodash';

import API from '../../api/Api';
import ICONS from '../../data/icons';

import messages from './UserStatus.messages';

import {getUserStatus, getBreakNames, getCurrentBreakName, getAvailableStateChanges} from '../../selectors';
import * as Actions from '../../actions';

import RolldownMenu from '../../components/RolldownMenu/RolldownMenu';
import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';

import './UserStatus.css';

const STATUS_ICONS = {
	[API.resourceStateChanges.leaveBreak]: ICONS.tick,
	[API.resourceStateChanges.logOut]: ICONS.close
};

class UserStatus extends PureComponent {

	constructor(props) {
		super(props);

		this.rolldownMenu = null;
	}

	/**
	 * It initiates invoking a capability othen than the Break capability.
	 */
	handleCapabilityClick = (stateChange) => {

		const {onActivate, onRequestLogout} = this.props;

		this.rolldownMenu.instanceRef.close();

		switch (stateChange) {
			case API.resourceStateChanges.leaveBreak:
				onActivate();
				break;

			case API.resourceStateChanges.logOut:
				onRequestLogout();
				break;

			default:				
		}
	};

	/**
	 * It initiates invoking the Break capability.
	 */
	handleBreakCapabilityClick = (breakName) => {

		const {onRequestBreak} = this.props;

		this.rolldownMenu.instanceRef.close();

		onRequestBreak(breakName);
	};

	render() {

		const {intl, availableStateChanges, breakNames, currentBreakName, currentStatus} = this.props;
		const {formatMessage} = intl;

		const rolldownText = formatMessage(messages.UserStatusChange_changeStatus);

		let buttons = null;

		if (availableStateChanges.length < 1) {
			const noCapText = formatMessage(messages.UserStatus_noAvailableCapability);
			buttons = [<ActionButton disabled={true} text={noCapText} buttonStyle={[BUTTON_STYLE.transparent]} key={'statusButton_noCapability'} />]
		}
		else {
			buttons = _.map(availableStateChanges, (stateChange) => {
			
				const defaultCapabilityText = formatMessage(messages['UserStatusChange_' + stateChange]);

				if(stateChange === API.resourceStateChanges.goToBreak) {

					if(breakNames && breakNames.length > 0) {

						// Let's render button for all break names.
						// NOTE: we pass the proper break name to handleBreakCapabilityClick()

						let breakButtons = _.map(breakNames, (breakName) => {
							let shortBreakName = breakName.length > 43 ? breakName.substr(0,40)+"..." : breakName;
							const breakNameId = `${shortBreakName.replace(/\s+/g, '')}_ButtonId`
							return (
								<ActionButton 
								    id={breakNameId}
									clickHandler={() => this.handleBreakCapabilityClick(breakName)}
									key={'statusButton_' +  stateChange + '_' + breakName} 
									icon={STATUS_ICONS[stateChange] || null} text={shortBreakName} label={breakName}
									buttonStyle={(currentBreakName && (breakName === currentBreakName)) ? [BUTTON_STYLE.transparent, BUTTON_STYLE.effective] : [BUTTON_STYLE.transparent]}/>
							);
						});

						return breakButtons;
					}
					else {
						// Let's render the default break button.
						// NOTE: we pass the default breakName to handleBreakCapabilityClick()
					
						return (
							<ActionButton 
							    id={"defaultBreakButtonId"}
								clickHandler={() => this.handleBreakCapabilityClick()}
								key={'statusButton_' +  stateChange} 
								icon={STATUS_ICONS[stateChange] || null}
								text={defaultCapabilityText}
								buttonStyle={(currentStatus === API.agentStatusTypes.break || currentStatus === API.agentStatusTypes.pendingBreak) ? [BUTTON_STYLE.transparent, BUTTON_STYLE.effective] : [BUTTON_STYLE.transparent]}/>
						);
					}
				}
				else {
					return (
						<ActionButton id={stateChange +"ButtonId"} clickHandler={() => this.handleCapabilityClick(stateChange)} key={'statusButton_' +  stateChange} icon={STATUS_ICONS[stateChange] || null} text={defaultCapabilityText} buttonStyle={[BUTTON_STYLE.transparent]}/>
					);
				}
			});
		}
	
		return (
			<div className="header__group user-status">
				<RolldownMenu label={rolldownText} ref={(el) => this.rolldownMenu = el}>{		
					<div className="user-status__group">	
						{buttons}
					</div>
				}
				</RolldownMenu>
			</div>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		availableStateChangeCapabilities: PropTypes.array,
		breakNames: PropTypes.array,
		currentBreakName: PropTypes.string,
		currentStatus: PropTypes.string,
		onStartShiftWithMode: PropTypes.func,
		onActivate: PropTypes.func,
		onRequestBreak: PropTypes.func,
		onRequestLogout: PropTypes.func,
		onRequestCloseWorkItem: PropTypes.func
	};
}

const mapStateToProps = (state) => {

	const currentStatus = getUserStatus(state);
	const breakNames = getBreakNames(state);
	const currentBreakName = getCurrentBreakName(state);
	const availableStateChanges = getAvailableStateChanges(state);

	return {
		currentStatus,
		availableStateChanges,
		breakNames,
		currentBreakName
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onActivate: bindActionCreators(Actions.leaveBreak, dispatch),
		onRequestBreak: bindActionCreators(Actions.goToBreak, dispatch),
		onRequestLogout: bindActionCreators(Actions.logOut, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserStatus));
