import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';

import uiState from './partialReducers/uiState';
import panelSet from './partialReducers/panelSet';
import panel from './partialReducers/panel';
import user from './partialReducers/user';
import backEnd from './partialReducers/backEnd';
import breakNames from './partialReducers/breakNames';
import notifications from './partialReducers/notifications';
import BREAKPOINTS from '../data/breakpoints';
import environment from './partialReducers/environment';
import scripting from './partialReducers/scripting';
import chat from './partialReducers/chat';
import customerNames from './partialReducers/customerNames';
import backendSyncReducer from './completeReducers/backendSync';
import { closeTabReducer } from './completeReducers/windowmanagement';
import { reducer as oidcReducer } from 'redux-oidc';
import login from './partialReducers/login';
import messages from './partialReducers/messages';

const appReducer = combineReducers({
  panel,
  panelSet,
  user,
  login,
  uiState,
  backEnd,
  breakNames,
  notifications,
  environment,
  scripting,
  chat,
  customerNames,
  messages,
  oidc: oidcReducer,
  browser: createResponsiveStateReducer(
    BREAKPOINTS,
    {
      extraFields: () => ({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  )
});

const rootReducer = (state, action) => {

  // NOTE: action name is hardcoded; keep the action name in synch here!!!
  if (action.type.includes("Store state data retrieved from the back-end")) {
    return backendSyncReducer(state, action);
  }

  if (action.type.includes("Close tab")) {
    return closeTabReducer(state, action);
  }


  return appReducer(state, action);
};

export default rootReducer;
