import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import keydown, { Keys } from 'react-keydown';
import classNames from 'classnames';
import ContainerDimensions from 'react-container-dimensions';
import * as Selectors from '../../selectors';
import API from '../../api/Api';
import * as Actions from '../../actions'
import store from '../../services/createStore';
import { loadInitialElements } from '../../actions';
import Stage from '../Stage/Stage';
import SidebarComposed from '../SidebarComposed/SidebarComposed';
import HeaderComposed from '../HeaderComposed/HeaderComposed';
import UserMessageBar from '../UserMessageBar/UserMessageBar';
import SupervisorBreakCard from '../SupervisorBreakCard/SupervisorBreakCard';
import './EditCaseLayout.css';
import AgentPerformanceDashboard from '../AgentPerformanceDashboard/AgentPerformanceDashboard';
import { downloadUserPhoto } from '../../services/profilePhoto';


/**
 * This is the first react item which actually starts rendering things. Contains the 3 big components, one for hidebar, one for the header and the middle part.
 */
class EditCaseLayout extends Component {

  componentDidMount() {

    const { loadInitialElements, initialElementsLoaded, token } = this.props;
    if (!initialElementsLoaded) {

      loadInitialElements(this.props.isSupervisor);
    }
    downloadUserPhoto(token);
  }

  render() {

    const { initialElementsLoaded, activeStageId, stages, overlayOpen, loadSupervisorBreakCard, token } = this.props;

    if (!initialElementsLoaded || !stages || !stages.length || !activeStageId || !activeStageId.length) return null;

    const classes = classNames({
      'layout': true,
      'layout--faded': overlayOpen
    });

    const header = (<HeaderComposed />);
    const sidebar = (<SidebarComposed />);
    const messageBar = (<UserMessageBar />);

    return (
      <div className={classes} >
        {messageBar}
        {loadSupervisorBreakCard && <SupervisorBreakCard />}

        <div className="layout__sidebar-and-content">
          {sidebar}

          <div className="layout__header-and-content">
            {header}

            <div className="layout__content">
              {stages.map(stage => (
                <ContainerDimensions
                  key={'dim_' + stage.id}>
                  <Stage key={'stage_' + stage.id} stageId={stage.id} hidden={stage.id !== activeStageId} />
                </ContainerDimensions>
              ))}
              <AgentPerformanceDashboard accessToken={token} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    initialElementsLoaded: PropTypes.bool.isRequired,
    activeStageId: PropTypes.string.isRequired,
    stages: PropTypes.array.isRequired,
    loadInitialElements: PropTypes.func.isRequired,
    showMessages: PropTypes.bool.isRequired,
    token: PropTypes.string
  };

  static defaultProps = {
    initialElementsLoaded: false,
    stageId: '',
    showMessages: false
  };
}

const mapStateToProps = (state) => {

  const activeStageId = Selectors.getActiveStageId(state) || '';
  const stages = Selectors.getPanelSetStages(state) || [];
  const initialElementsLoaded = Selectors.getInitialDataLoaded(state);
  const overlayOpen = Selectors.getUiStateInfo(state, API.uiState.overlay);
  const isSupervisor = Selectors.getSupervisorStatus(state);
  const loadSupervisorBreakCard = Selectors.loadSupervisorLoginCard(state);
  const token = Selectors.backEndAccessToken(state);

  return {
    activeStageId,
    stages,
    initialElementsLoaded,
    overlayOpen,
    isSupervisor,
    loadSupervisorBreakCard,
    token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadInitialElements: bindActionCreators(loadInitialElements, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(keydown(Keys.esc, Keys.backspace)(EditCaseLayout));