import API from "../../api/Api";
import {removePanelSetFromStages} from "../partialReducers/user";

export const closeTabReducer = (state, action) => {

	const {panelSetId, panelGroupId, panelId} = action.payload;
	const newState = {...state};

	const panelStore = {...newState[API.panel._key]}
	newState[API.panel._key] = panelStore;
	const panelSetStore = {...newState[API.panelSet._key]}
	newState[API.panelSet._key] = panelSetStore;

	if (panelStore.hasOwnProperty(panelId)) {
		delete panelStore[panelId];
	}


	const panelSet = {...panelSetStore[panelSetId]};
	if (panelSet){
		panelSetStore[panelSetId] = panelSet;
		const oldPanelGroups = panelSet[API.panelSet.panels];
		if (oldPanelGroups){
			if (oldPanelGroups.length === 1){
				delete panelSetStore[panelSetId];
				newState[API.user._key] = removePanelSetFromStages(newState[API.user._key], panelSetId);
			}
			else{
				//Todo: again here we make the assumption that each panelGroup only contains one panel		
				const newPanelGroups = oldPanelGroups.filter(x => x[API.panelGroup.id] !== panelGroupId);
				panelSet[API.panelSet.panels] = newPanelGroups;
				const firstPanelGroup = {...newPanelGroups[0]};
				newPanelGroups[0] = firstPanelGroup;
				firstPanelGroup[API.panelGroup.active] = firstPanelGroup[API.panelGroup.panels][0];				
				panelSet[API.panelSet.layout] = {};
			}
		}
		
	}
		
	return newState;
}

