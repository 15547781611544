import React, {PureComponent, cloneElement} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import Icon, {ICONS} from '../../components/Icon/Icon';

import './OverlayPanel.css';

class OverlayPanel extends PureComponent {

	render() {

		const {overlayType, icon, title, subtitle, instructions, children} = this.props;

		const classes = classNames({
			'overlay': true,
			[`overlay--${overlayType}`]: true
		});

		return (
			<div className={classes}>
				<div className="overlay__panel card-shadow">
					{icon && <div className="overlay__icon"><Icon name={icon}/></div>}
					{title && <div className="overlay__title">{title}</div>}
					{subtitle && <div className="overlay__subtitle">{subtitle}</div>}
					{instructions && <div className="overlay__instructions">{instructions}</div>}
					{cloneElement(children, {className: 'overlay__content'})}
				</div>
			</div>
		);
	}

	static propTypes = {
		overlayType: PropTypes.string.isRequired,
		icon: PropTypes.oneOf(_.values(ICONS)),
		title: PropTypes.string,
		subtitle: PropTypes.string,
		instructions: PropTypes.string,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		])
	};
}

export default OverlayPanel;
