import {createReducer} from 'redux-act';

import * as Actions from '../../actions';
import API from '../../api/Api';

export default createReducer({

    [Actions.windowIsNotInFocus]: (state, payload) => {
        const newState = {...state};
        const {date} = payload;
        newState[API.notifications.isFocused] = false;
        newState[API.notifications.lastFocusDate] = date;
        return newState;
    },

    [Actions.windowIsInFocus]: (state, payload) => {
        const newState = {...state};
        const {date} = payload;
        newState[API.notifications.isFocused] = true;
        newState[API.notifications.lastFocusDate] = date;
        return newState;
    }   

}, {});