export const guid = function (separator) {

	if (separator === undefined || separator === null) {

		separator = '';
	}

	function s4() {

		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	return s4() + s4() + separator + s4() + separator + s4() + separator + s4() + separator + s4() + s4() + s4();
}

export const validatePhoneNumber = function (phoneNumber) {

	// Valid phone numbers: non empty string with numerical characters, asterisk and pound only.
	return /^[0-9*#]+$/.test(phoneNumber);
}