import React, {Component, cloneElement} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import keydown, {Keys} from 'react-keydown';

import ICONS from '../../data/icons';

import PanelTitle from '../../components/PanelTitle/PanelTitle';

import './TaskManagerLayout.css';

/**
 * Used by the TaskManager container.
 */
class TaskManagerLayout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: props.open ? true : false
		};
	}

	close = () => {
		if (this.state.open === true) {
			this.setState(() => ({open: false}));
		}
	};

	open = () => {
		if (this.state.open === false) {
			this.setState(() => ({open: true}));
		}
	};

	toggle = () => {
		if (this.state.open === true) {
			this.close();
			this.props.onCloseTaskManager();
		} else {
			this.open();
			this.props.onOpenTaskManager();
		}
	};

	handleClickOutside = (e) => {
		if (this.state.open === true) {
			this.close();
			this.props.onCloseTaskManager();
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {keydown} = nextProps;

		if (keydown.event && keydown.event.which === Keys.esc) {
			this.close();
		}

		if (nextProps.open !== this.props.open) {
			if (nextProps.open) {
				this.open();
			} else {
				this.close();
			}
		}
	}

	render() {
		const {incomingAlert, title, waves, children, panelSetName, panelSetId, closePanelSetButton, timer} = this.props;
		const {open} = this.state;

		const classes = classNames({
			'task-manager': true,
			'task-manager--open': open,
			'task-manager--incoming': incomingAlert
		});

		return (
			<nav className={classes}>
				{
					incomingAlert &&
						incomingAlert
				}
				<div className="task-manager__list card-shadow--hoffset"> 
					<div className="task-manager__list-content">
						{waves}
						{
							panelSetName && panelSetId &&
								<div className="task-manager__customer">
									{panelSetName} 
									<span className="customer-id">{panelSetId}</span>
								</div>
						}
						<div style={{"textAlign":"center"}}>{timer}</div>

						{cloneElement(children, {className: 'task-manager__actions'})}

						<div className="task-manager__close-task">
							{closePanelSetButton}
						</div>

					</div>
				</div>

				<PanelTitle clickHandler={this.toggle} icon={ICONS.tasklist} label={title}/>

			</nav>
		);
	}

	static propTypes = {
		title: PropTypes.string.isRequired,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]).isRequired,
		onOpenTaskManager: PropTypes.func,
		onCloseTaskManager: PropTypes.func,
		closePanelSetButton: PropTypes.node.isRequired,
		open: PropTypes.bool,
		panelSetId: PropTypes.string,
		panelSetName: PropTypes.element,
		waves: PropTypes.element,
		incomingAlert: PropTypes.element,
		keydown: PropTypes.object,
		timer: PropTypes.object
	};

	static defaultProps = {
		open: false
	};
}

export default keydown(Keys.esc)(onClickOutside(TaskManagerLayout));
