import { getAksApiUrl } from '../selectors';
import store from '../services/createStore';
import * as Actions from '../actions';

export const  downloadUserPhoto = async (accessToken) => {

    const url = getAksApiUrl()+"/directory/me/photo"
    var headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`)
    let initObj = {method: 'GET', headers: headers,responseType : 'arraybuffer'};

    const response = await fetch(url, initObj);
    if(response.ok){
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        store.dispatch(Actions.saveUserPhoto(objectURL));
    }
}


