import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Actions from '../../actions';
import * as Selectors from '../../selectors';
import { bindActionCreators } from 'redux';
/**
 * Encapsulates the email fragment
 */
class EmailPanel extends Component {

  constructor(props) {
    super();
    this.fragmentRef = React.createRef();
  }

  componentDidMount() {
    this.fragmentRef.current.addEventListener('onUnreadMessageCountChanged', ev => {
      var count = ev.detail.count;
      this.props.unreadMessageCountChanged(this.props.conversationId, count);
    });
  }

  render() {
    const { emailHubUrl, accessToken, conversationId, contentMgrUrl, panelId, customerApiUrl, directoryApiUrl } = this.props;
    return (
      <email-fragment
        ref={this.fragmentRef}
        id={panelId}
        emailhuburl={emailHubUrl}
        token={accessToken}
        conversationid={conversationId}
        contentapiurl={contentMgrUrl}
        customerapiurl={customerApiUrl}
        directoryapiurl={directoryApiUrl}
        style={{ "height": "calc(98% - 30px)" }}>
      </email-fragment>
    );
  }

  static propTypes = {
    conversationId: PropTypes.string.isRequired,
    panelId: PropTypes.string.isRequired,
  };
}

const mapStateToProps = (state, ownProps) => {
  const aksUrl = Selectors.getAksApiUrl(state);
  const panelId = ownProps.panelId;
  const conversationId = Selectors.getDataForEmailPanel(state, panelId);
  const emailHubUrl = aksUrl + "/email/hub"
  const customerApiUrl = aksUrl + "/customer-manager/v3"
  const directoryApiUrl = aksUrl + "/directory";
  const accessToken = Selectors.backEndAccessToken(state);
  const contentMgrUrl = aksUrl + "/content";
  return {
    emailHubUrl,
    accessToken,
    contentMgrUrl,
    conversationId,
    customerApiUrl,
    directoryApiUrl,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    unreadMessageCountChanged: bindActionCreators(Actions.unreadMessageCountChanged, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPanel);
