import { put, call, takeLatest, all } from 'redux-saga/effects';

import * as Actions from '../actions';
import { httpGet } from '../services/http';

export function* setLanguage(action) {

	//Todo: this is an enormous hack!
	//either fix react-int or remove it properly and move these strings to their proper place
	const hardCodedLocales = {
		"CaseListItem_panelSetType_general": "General inquiry",
		"CaseListItem_panelSetType_billing": "Billing inquiry",
		"CaseListItem_panelSetType_technical": "Technical issue",
		"CaseListItem_panelSetType_complaint": "Complaint",
		"CaseListItem_panelSetType_new": "New task",
		"Day": "day",
		"Days": "days",
		"PanelTitle_TaskList": "Task list",
		"PanelTitle_TaskManager": "Task manager",
		"UserProfile_extension_abbreviated": "extension",
		"UserProfile_agentId_abbreviated": "agent id",
		"ChatPanel_NoBackendUrl": "[Chat window is not available]",
		"HeaderComposed_Layout_fullsize": "Full size",
		"HeaderComposed_Layout_twoColumns": "Two columns",
		"HeaderComposed_Layout_twoRows": "Two rows",
		"HeaderComposed_Layout_rightColumnSplit": "Right column split",
		"HeaderComposed_Layout_leftColumnSplit": "Left column split",
		"HeaderComposed_Layout_topRowSplit": "Top row split",
		"HeaderComposed_Layout_bottomRowSplit": "Bottom row split",
		"HeaderComposed_Layout_crossSplit": "Cross split",
		"IncomingInteraction_WaitingTitle": "Waiting for incoming interaction...",
		"IncomingInteraction_IncomingTitle": "Incoming interaction",
		"IncomingInteraction_ManualModeButton": "Search mode",
		"IncomingInteraction_BreakRequestButton": "Request break",
		"CaseContainer_SearchCaseHeading": "Search panel sets",
		"CaseContainer_IncomingHeading": "Waiting for incoming interaction",
		"InteractionTab_chat": "Chat",
		"InteractionTab_voice": "Call",
		"InteractionTab_test": "Test",
		"InteractionTab_any": "Interactions",
		"InteractionTab_incoming": "Waiting for interaction",
		"InteractionTab_startShift": "Start shift",
		"InteractionTab_break": "Break",
		"InteractionTab_cqManager": "Cherry Pick List",
		"InteractionTab_pendingLogout": "Pending Logout",
		"InteractionTab_pendingBreak": "Pending Break",
		"InteractionTab_loadingScreen": "loadingScreen",
		"InteractionTab_emptyWorkItem": "New Task",
		"InteractionTab_userSettings": "User Settings",
		"InteractionTab_dialpad": "New voice call",
		"InteractionTab_customerview": "Customer Data",
		"InteractionTab_email": "Email",
		"InteractionTab_callLookup": "New Call",
		"InteractionTab_teamsCall": "New Call",
		"InteractionTab_closedCall": "Closed Conversation",
		"InteractionTab_scripting": "scripting",
		"InteractionTab_supervisor": "Supervisor Dashboard",
		"LogoutRequest_title": "Logout request",
		"LogoutRequest_instructions": "Close all open tasks to complete your logout request",
		"LogoutRequest_requestBreakButtonsTitle": "Request break:",
		"BreakRequest_title": "Break request",
		"BreakRequest_instructions": "Close all open tasks to complete your break request",
		"BreakRequest_requestBreakButtonsTitle": "Change break request to:",
		"BreakRequest_defaultBreakCodeName": "You're on break",
		"SidebarComposed_Request": "Request",
		"SidebarComposed_Logout": "Logout",
		"SidebarComposed_Incoming": "Incoming interaction",
		"SidebarComposed_cqManager": "Live Queue",
		"SidebarComposed_IncomingWaiting": "waiting...",
		"SidebarComposed_search": "Search tasks",
		"SidebarComposed_pendingBreakSubtitle": "Pending Break",
		"SidebarComposed_AddTask": "Add task",
		"SidebarComposed_NewVoiceCall": "New outbound call",
		"SidebarComposed_UserSettings": "User settings",
		"SidebarComposed_CallLookup": "New Call",
		"SidebarComposed_Supervisor": "Supervisor Dashboard",
		"StartShift_welcome": "Hello Agent!",
		"StartShift_shiftStarts": "Please wait, application is loading...",
		"StartShift_shiftEnds": "Your shift ends in:",
		"StartShift_startInAutoMode": "Start in Auto mode",
		"StartShift_startInSearchMode": "Start in Search mode",
		"StartShift_continueInAutoMode": "Continue in Auto mode",
		"StartShift_continueInSearchMode": "Continue in Search mode",
		"StartShift_requestBreakButtonsTitle": "Request break:",
		"TaskManager_DispositionCodeNotSpecified": "Not Specified",
		"TaskManager_DispositionCodesDisabled": "Codes are disabled",
		"TaskManager_DispositionCodeLabel": "Disposition Code",
		"TaskManager_Title": "Task Manager",
		"TaskManager_SelectDispositionCode": "Select a Code",
		"TaskManager_AddNotes": "Add Notes",
		"TaskManager_CloseTask": "Close Task",
		"BackendConnectionLost": "Connection lost!",
		"UnknownError": "Unknown error occurred.",
		"MoreMessages": "...",
		"Unspecified": "Unspecified error occurred.",
		"InvalidRequestId": "Technical error occurred (invalid request id). Please refer to the following error code:",
		"InvalidResourceId": "Technical error occurred (invalid resource id). Please refer to the following error code:",
		"InvalidExtension": "Invalid Avaya phone is specified. Please verify it in the user settings.",
		"InvalidBreakCode": "Technical error occurred (invalid break code). Please refer to the following error code:",
		"InvalidAgentId": "Invalid Avaya agent id is specified. Please verify it in the user settings.",
		"InvalidPassword": "Invalid Avaya agent password is specified. Please verify it in the user settings.",
		"DeviceMonitoringIssue": "Technical error occurred (device monitoring failed). Please refer to the following error code:",
		"ExtensionAlreadyUsed": "The specified Avaya phone is already used. Please verify it in the user settings.",
		"AgentIdAlreadyUsed": "The specified Avaya agent id is already used. Please verify it in the user settings.",
		"AgentAlreadySignedIn": "The specified Avaya agent id is already signed in. Please verify it in the user settings.",
		"DuplicatedRequest": "Technical error occurred (duplicated request). Please refer to the following error code:",
		"GenericDeviceIssue": "Error occurred while using the specified Avaya phone (generic device issue). Please refer to the following error code:",
		"CoveragePathIssue": "Technical error occurred (missing coverage path). Please refer to the following error code:",
		"LoginRequiredAgain": "You need to logout and then login in order to be get synchronized with Avaya again",
		"CallControllerUnavailable": "Technical error occurred (external call service unavailable). Call features may not work.",
		"InitiateInteractionRequestFailed": "Failed to initiate the interaction.",
		"TerminateInteractionRequestFailed": "Failed to drop the interaction.",
		"JoinInteractionRequestFailed": "Failed to answer the interaction.",
		"HoldInteractionRequestFailed": "Failed to put the interaction on hold.",
		"ResumeInteractionRequestFailed": "Failed to resume the interaction.",
		"TransferInteractionRequestFailed": "Failed to transfer the interaction.",
		"UserStatusChange_changeStatus": "Change status",
		"UserStatusChange_leaveBreak": "Leave break",
		"UserStatusChange_logOut": "Log out",
		"UserStatusChange_goToBreak": "Take a break",
		"UserStatus_Break": "On Break",
		"UserStatus_Busy": "Busy",
		"UserStatus_Idle": "Idle",
		"UserStatus_Logout": "Logged Out",
		"UserStatus_PendingBreak": "Pending Break",
		"UserStatus_PendingLogout": "Pending Logout",
		"UserStatus_noAvailableCapability": "No status change available",
		"WorkItemManager_AddCallButton": "Add Call"
	}

	const { language } = action.payload;

	try {

		//const response = yield call(httpGet, './locales/' + language + '/locale.json');

		yield put(Actions.languageSet(language, hardCodedLocales));
	} catch (error) {

		throw new Error('saga/User/setLanguage() error: ' + error);
	}
}


export default function* () {

	yield all([
		takeLatest(Actions.setLanguage.getType(), setLanguage)
	]);
}
