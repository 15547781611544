import {createReducer} from 'redux-act';

import * as Actions from '../../actions';
export default createReducer({

    [Actions.unreadMessageCountChanged]: (state, payload) => {
        const newState = {...state};
        const {conversationId, count} = payload;
        newState[conversationId] = count;
        return newState;
    },

}, {});