import _ from 'lodash';
import createCachedSelector from 're-reselect';
import API from '../api/Api';
import ReduxModels from '../api/ReduxModels';
import { getWorkItemsV2 } from './conversations';
import { getPanelSetOfPanel } from './panelSets';



const state = (state) => state;
const interactionsSelect = (state) => state[API.panel._key];
const interactionIdSelect = (state, interactionId) => interactionId;
const interactionTypeSelect = (state, interactionId, interactionType) => interactionType;

export const getInteraction = createCachedSelector(
	[interactionsSelect, interactionIdSelect],
	(allInteractions, id) => allInteractions[id]
)(
	(state, interactionId) => 'panel_' + interactionId
);

export const getInteractionsById = createCachedSelector(
	[state, interactionsSelect, interactionIdSelect],
	(state, allInteractions, interactionIds) => _.map(interactionIds, (id) => getInteraction(state, id))
)(
	(state, interactionIds) => 'getInteractionsById_' + interactionIds.join('_')
);

export const getInteractionData = createCachedSelector(
	[getInteraction, interactionTypeSelect],
	(interaction, interactionType) => interaction[API.panel.content][interactionType]
)(
	(state, interactionId, interactionType) => 'interactionData_' + interactionId + '_' + interactionType
);


export const getDataForCustomerPanel = (state, panelId) => {
	const panelSet = getPanelSetOfPanel(state, panelId);
	if (API.panelSet.workItemId in panelSet) {
		const workItemId = panelSet[API.panelSet.workItemId];
		const workItems = getWorkItemsV2(state);
		if (workItemId in workItems) {
			const workItem = workItems[workItemId];
			if (API.workItemV2.customerId in workItem) {
				return {
					customerId: workItem[API.workItemV2.customerId],
					conversationId: workItem[API.workItemV2.primaryConversationId]
				}
			}
		}
	}
	return {};
}

export const hasCall = (state) => {
	var result = false;
	const workItems = getWorkItemsV2(state);
	Object.keys(workItems).forEach(i => {
		const workItem = workItems[i];
		const conversations = workItem[API.workItemV2.conversations];
		Object.keys(conversations).forEach(k => {
			const c = conversations[k];
			if (c[API.conversation.channelType] == API.conversationChannelTypes.voice) {
				result = true;
			}
		});
	});
	return result;
}

export const getDataForScriptingPanel = (state, panelId) => {
	const panelSet = getPanelSetOfPanel(state, panelId);
	if (API.panelSet.workItemId in panelSet) {
		const workItemId = panelSet[API.panelSet.workItemId];
		return state[ReduxModels.nodes.scripting][workItemId];
	}
	return null;
}

export const getDataForCallPanel = (state, panelId) => {
	const panel = state[ReduxModels.nodes.panel][panelId];
	return panel[API.panel.conversationId];
}


export const getDataForChatPanel = (state, panelId) => {
	const panel = state[ReduxModels.nodes.panel][panelId];
	return panel[API.panel.conversationId];
}

export const getDataForEmailPanel = (state, panelId) => {
	const panel = state[ReduxModels.nodes.panel][panelId];
	return panel[API.panel.conversationId];
}

export const getPanelById = (state, panelId) => state[ReduxModels.nodes.panel][panelId];

export const isConversationClosed = (state, panelId) => {
	const panelSet = getPanelSetOfPanel(state, panelId);
	return panelSet.panels.some(p => p.id.includes('afterwork'))
}