import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import _ from 'lodash';

import API from '../../api/Api';

import {getUserInfo} from '../../selectors';

import OverlayPanel from '../../components/OverlayPanel/OverlayPanel';
import IdlePanel from '../../components/IdlePanel/IdlePanel';
import Timer from '../../components/Timer/Timer';

/**
 * Don't know what this is
 */
class Overlay extends PureComponent {


	confirm = () => {
		const {overlayType, onConfirm} = this.props;

		switch (overlayType) {
			case API.uiState.overlayTypes.requestBreak:
				onConfirm();
				break;

			default:
		}
	};

	render() {
		const {overlayType, incomingInteraction} = this.props;

		if (!overlayType) return null;

		let icon, title, subtitle, instructions, content;

		switch (overlayType) {
			case API.uiState.overlayTypes.incomingInteraction:
			{
				const {interaction} = incomingInteraction;
				const interactionType = interaction[API.panel.type];

				const timer = <Timer autoStart={true} startTime={Date.now()} />;

				const infoBlocks = [
					{label: 'Case type:', value: 'Billing query'}
				];

				content = <IdlePanel timer={timer} interactionType={interactionType} title={title} infoBlocks={infoBlocks} />
			}
			break;

			default:
			break;
		}

		return (
			<OverlayPanel overlayType={overlayType} icon={icon} title={title} subtitle={subtitle} instructions={instructions}>
				{content}
			</OverlayPanel>
		);
	}

	static propTypes = {
		overlayType: PropTypes.oneOf(_.values(API.uiState.overlayTypes).concat([false])),
		incomingInteraction: PropTypes.object,
	};

}

const mapStateToProps = (state) => {
	const overlayType = state[API.uiState._key][API.uiState.overlay];

	let incomingInteraction;
	if (overlayType === API.uiState.overlayTypes.incomingInteraction) {
		incomingInteraction = getUserInfo(state, API.user.incomingInteraction);
	}

	return {
		overlayType,
		incomingInteraction
	}
};

export default connect(mapStateToProps, null)(Overlay);