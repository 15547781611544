import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import './TextField.css';

export const TEXTFIELD_TYPE = {
	text: 'text',
	password: 'password',
	number: 'number'
};

export const TEXTFIELD_STYLE = {
	raised: 'raised',
	stroked: 'stroked',
	fullWidth: 'fullWidth',
	phoneInput: 'phoneInput'
};

class TextField extends PureComponent {

	state = {
		// Boolean flag to indicate if the TextField currently holds a validated value or not.
		hasValidValue: true
	};

	/**
	 * Event handler invoked to indicate that the value of the TextField is being changed.
	 */
	onValueChange = (e) => {

		if(e.target) {

			if(this.props.onChange) {

				// The parent is subscribed to the onChange event. Let's notified it.
				// We are going to pass the new value only. No need to pass the entire
				// event.

				this.props.onChange(e.target.value);
			}

			if(this.props.onValidate) {

				// Value validation is requested by the parent.
				// Let's execute the parent's value validation logic. We are going to pass 
				// the new value only. No need to pass the entire event.

				this.setState({ hasValidValue: this.props.onValidate(e.target.value)});
			}
		}
	};

	render() {
		const {inputRef, fieldType, placeholder, value, label, styles, onKeyDown, id} = this.props;
		const {hasValidValue} = this.state;
		
		const fieldClasses = classNames({
			'textfield': true,
			['textfield--' + (hasValidValue ? 'valid' : 'invalid') + '-value']: true,
			'textfield--raised': _.includes(styles, TEXTFIELD_STYLE.raised),
			'textfield--stroked': _.includes(styles, TEXTFIELD_STYLE.stroked),
			'textfield--full-width': _.includes(styles, TEXTFIELD_STYLE.fullWidth),
			'textfield--phoneInput': _.includes(styles, TEXTFIELD_STYLE.phoneInput)
		});

		const labelClasses = classNames({
			'textfield-with-label': true,
			'textfield-with-label--full-width': _.includes(styles, TEXTFIELD_STYLE.fullWidth)
		});

		const field = <input ref={inputRef} id={id} type={fieldType} value={value} placeholder={placeholder} className={fieldClasses} 
			onKeyDown={(event) => onKeyDown ? onKeyDown(event) : {}} 
			onChange={(event) => this.onValueChange(event)} />;

		return (
			label ?
				(<label className={labelClasses}>
					<span className="textfield-label">{label}</span>
					{field}
				</label>)
				:
				field
		);
	}

	static propTypes = {
		id: PropTypes.string,
		fieldType: PropTypes.oneOf(_.values(TEXTFIELD_TYPE)),
		placeholder: PropTypes.string,
		value: PropTypes.string,
		label: PropTypes.string,
		styles: PropTypes.array,
		inputRef: PropTypes.func,
		onKeyDown: PropTypes.func,
		onChange: PropTypes.func,
		onValidate: PropTypes.func
	};

	static defaultProps = {
		fieldType: TEXTFIELD_TYPE.text
	};
}

export default TextField;
