import {defineMessages} from 'react-intl';
import API from '../../api/Api';

const messages = defineMessages({

	// User state change localizations

	UserStatusChange_changeStatus: {
		defaultMessage: 'Change status',
		id: 'UserStatusChange_changeStatus',
		description: 'Change status rolldown menu label'
	},

	['UserStatusChange_' + API.resourceStateChanges.leaveBreak]: {
		defaultMessage: 'Leave break',
		id: 'UserStatusChange_leaveBreak',
		description: 'Change status rolldown menu label'
	},

	['UserStatusChange_' + API.resourceStateChanges.logOut]: {
		defaultMessage: 'Log out',
		id: 'UserStatusChange_logOut',
		description: 'Change status rolldown menu label'
	},

	['UserStatusChange_' + API.resourceStateChanges.goToBreak]: {
		defaultMessage: 'Take a break',
		id:  'UserStatusChange_goToBreak',
		description: 'Change status rolldown menu label'
	},

	// User state localizations

	['UserStatus_' + API.agentStatusTypes.break]: {
		defaultMessage: 'On Break',
		id: 'UserStatus_Break',
		description: 'User status'
	},

	['UserStatus_' + API.agentStatusTypes.busy]: {
		defaultMessage: 'Busy',
		id: 'UserStatus_Busy',
		description: 'User status'
	},

	['UserStatus_' + API.agentStatusTypes.idle]: {
		defaultMessage: 'Idle',
		id: 'UserStatus_Idle',
		description: 'User status'
	},

	['UserStatus_' + API.agentStatusTypes.logout]: {
		defaultMessage: 'Logged Out',
		id: 'UserStatus_Logout',
		description: 'User status'
	},

	['UserStatus_' + API.agentStatusTypes.pendingBreak]: {
		defaultMessage: 'Pending Break',
		id: 'UserStatus_PendingBreak',
		description: 'User status'
	},

	['UserStatus_' + API.agentStatusTypes.pendingLogout]: {
		defaultMessage: 'Pending Logout',
		id: 'UserStatus_PendingLogout',
		description: 'User status'
	},
	UserStatus_noAvailableCapability: {
		defaultMessage: 'No status change available',
		id: 'UserStatus_noAvailableCapability',
		description: 'User status'
	},
});

export default messages;