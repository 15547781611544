import createCachedSelector from 're-reselect';
import _, { isNil } from 'lodash';

import API from '../api/Api';
import { LAYOUT_BY_ITEMS } from '../data/grid';
import { getPanelSetInfo } from './panelSets';
import ReduxModels from '../api/ReduxModels';

const state = (state) => state;
const stageIdSelect = (state, stageId) => stageId;
const getStages = (state) => state[API.user._key][API.user.stages];
const userSelect = (state) => state[API.user._key];
const oidcUserSelect = (state) => state[API.oidc._key][API.oidc.user._key];
const userNameSelect = (state) => isNil(state[API.oidc._key][API.oidc.user._key]) ? state[API.user._key][API.user.name] : state[API.oidc._key][API.oidc.user._key][API.oidc.user.profile._key][API.oidc.user.profile.name];
const stageByIdSelect = (allStages, id) => _.find(allStages, { [API.stage.id]: id })
const infoTypeSelect = (state, infoType) => infoType;

export const wasLogOutRequested = (state) => {
	return state[API.user._key][API.user.logoutRequested];
};

export const getUserInfo = createCachedSelector(
	[userSelect, infoTypeSelect],
	(user, infoType) => user[infoType] ? user[infoType] : null
)(
	(state, infoType) => 'user_' + infoType
);

export const getPanelSetStages = createCachedSelector(
	[getStages],
	(stages) => _.reject(stages, { [API.stage.id]: API.stage.defaultStage })
)(
	() => 'getPanelSetStages'
);

export const getStageIds = createCachedSelector(
	[getStages],
	(allStages) => _.map(allStages, API.stage.id)
)(
	() => 'stageIds'
);

export const getStageById = createCachedSelector(
	[getStages, stageIdSelect],
	(allStages, stageId) => stageByIdSelect(allStages, stageId)
)(
	(state, stageId) => 'stageById_' + stageId
);

export const getActiveStageId = createCachedSelector(
	[state],
	(state) => getUserInfo(state, API.user.currentStage)
)(
	() => 'user_' + API.user.currentStage
);

export const getOpenPanelSetIds = createCachedSelector(
	[getStages],
	(stages) => _.flatMap(stages, API.panelSet._key)
)(
	() => 'userOpenPanelSetIds'
);

export const hasOpenPanelSet = createCachedSelector(
	[getOpenPanelSetIds, state],
	(panelSetIds, state) => {
		const caseStatuses = _.map(panelSetIds, (panelSetId) => getPanelSetInfo(state, panelSetId, API.panelSet.status));
		return _.without(caseStatuses, API.panelSet.statuses.dashboard, API.panelSet.statuses.request).length > 0;
	}
)(
	() => 'userHasOpenPanelSet'
);

export const getPanelSetsOfStage = createCachedSelector(
	[getStageById],
	(stage) => stage[API.stage.panelSets]
)(
	(state, stageId) => 'panelSetsOfStage_' + stageId
);

export const getCurrentLayoutId = createCachedSelector(
	[state, getActiveStageId],
	(state, activeStageId) => {

		const stage = getStageById(state, activeStageId);
		const panelSets = stage[API.stage.panelSets];
		const panelSetCount = panelSets.length;

		const layoutId = _.get(stage, `${API.stage.layout}.id`, LAYOUT_BY_ITEMS[panelSetCount - 1][0]);

		return layoutId;
	}
)(
	() => 'currentLayoutId'
);

export const getAvailableLayouts = createCachedSelector(
	[getStageById, getCurrentLayoutId],
	(stage, currentLayoutId) => {

		// Find possible other layouts
		const layoutGroupIndex = _.findIndex(LAYOUT_BY_ITEMS, (group) => _.includes(group, currentLayoutId));
		const otherLayouts = _.without(LAYOUT_BY_ITEMS[layoutGroupIndex], currentLayoutId);

		return otherLayouts;

	}
)(
	(state, stageId) => 'availableLayouts_' + stageId
);

/**
 * returns the PanelSets that are part of the specified stage
 * @param {*} state 
 * @param {*} stageId 
 */
export const getPanelSetIdsOfStage = (state, stageId) => {
	const stage = getStageById(state, stageId);
	return stage[API.stage.panelSets];
}

/**
 * returns the index of the stage in the user.stages array
 * @param {*} state 
 * @param {*} stageId 
 */
export const getStageIndex = (state, stageId) => {
	const stages = getStages(state);
	var found = null;;
	stages.forEach(stage => {
		if (stage[API.stage.id] === stageId) {
			found = stage;
		}
	})
	if (found !== null) {
		return (stages.indexOf(found));
	}
	else {
		return -1;
	}

}

/**
 * returns the user's id
 * @param {*} state 
 */
export const getUserId = (state) => {

	const user = userSelect(state);

	return user[API.user.id];
}

/**
 * returns the user's name
 * @param {*} state 
 */
export const getUserName = state => userNameSelect(state) ?? "Agent name goes here"

export const getUserPhoto = (state) => state[API.user._key][API.user.photo];

/**
 * Returns a boolean value based on the supervisor value
 * @param {*} state 
 */
export const getSupervisorStatus = (state) => {
	return state[ReduxModels.nodes.user][API.user.isSupervisor];
}


/**
 * returns the user's tenant
 * @param {*} state 
 */
export const getUserTenant = (state) => {

	const user = userSelect(state);

	return user[API.user.tenant];
}

/**
 * returns the user settings
 * @param {*} state 
 */
export const getUserSettings = (state) => {

	const user = userSelect(state);

	return user[API.user.settings._key];
}

/**
 * returns the extension currently used by the agent
 * @param {*} state 
 */
export const getUserExtension = (state) => {

	const userSettings = getUserSettings(state);

	if (userSettings) {

		return userSettings[API.user.settings.extension];
	}

	return null;
}

/**
 * returns the Avaya agentId currently used by the agent
 * @param {*} state 
 */
export const getUserAgentId = (state) => {

	const userSettings = getUserSettings(state);

	if (userSettings) {

		return userSettings[API.user.settings.agentId];
	}

	return null;
}


/**
 * returns the Avaya agent password currently used by the agent
 * @param {*} state 
 */
export const getUserPassword = (state) => {

	const userSettings = getUserSettings(state);

	if (userSettings) {

		return userSettings[API.user.settings.password];
	}

	return null;
}

/**
 * returns the Avaya voice enabled flag of the agent
 * @param {*} state 
 */
export const getUserVoiceEnabled = (state) => {

	const userSettings = getUserSettings(state);

	if (userSettings) {

		return userSettings[API.user.settings.voiceEnabled];
	}

	return null;
}

export const getOidcUser = (state) => oidcUserSelect(state);

