import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, intlShape} from 'react-intl';

import API from '../../api/Api';

import messages from './IdlePanelContainer.messages';

import * as Actions from '../../actions';

import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';
import Timer from '../../components/Timer/Timer';
import IdlePanel from '../../components/IdlePanel/IdlePanel';
import Waves, {WAVE_TYPES} from '../Waves/Waves';

import './IdlePanelContainer.css';

/**
 * One of the things the Interaction (which is Panel) can render. If you are Idle, this is what gets rendered.
 */
class IdlePanelContainer extends Component {

	componentDidUpdate(prevProps) {
		if (prevProps.incomingInteraction !== this.props.incomingInteraction) {
			this.props.onWave()
		}
	}

	render() {

		const {intl, onRequestBreak, agentStateSince} = this.props;
		const {formatMessage} = intl;

		let waitingPanel, title;
		title = formatMessage(messages.IncomingInteraction_WaitingTitle);
		const requestBreakButtonLabel = formatMessage(messages.IncomingInteraction_BreakRequestButton);
		const timer = <Timer autoStart={true} startTime={agentStateSince}/>;

		// NOTE: we always use the default breakCode when the agent goes to
		// Break via the IdlePanelContainer. Aka. only the default break functionality
		// is exposed to the IdlePanelContainer. This might change in the future ...

		waitingPanel = (
			<IdlePanel timer={timer} interactionType={API.panel.types.incoming} title={title}>
				<ActionButton id={"requestBreakButtonId"} clickHandler={() => onRequestBreak()} text={requestBreakButtonLabel} buttonStyle={[BUTTON_STYLE.transparent]}/>
			</IdlePanel>
		);

		return (
			<section className="incoming scrollbar">
				<Waves waveType={WAVE_TYPES.incomingInteraction} />
				{waitingPanel}
			</section>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		incomingInteraction: PropTypes.object,
		onWave: PropTypes.func,
		onRequestBreak: PropTypes.func,
		agentStateSince: PropTypes.number
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		onWave: bindActionCreators(Actions.increaseWavePhase, dispatch),
		onRequestBreak: bindActionCreators(Actions.goToBreak, dispatch)
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(IdlePanelContainer));