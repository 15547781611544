// Auto-generated file, created by 'npm run icon-sprite'. Do not modify!

const ICONS = {
	add: 'add',
	addcall: 'addcall',
	book: 'book',
	addcircle: 'addcircle',
	caselist: 'caselist',
	change: 'change',
	chart: 'chart',
	chat: 'chat',
	chatbubbleleft: 'chatbubbleleft',
	chatbubbleright: 'chatbubbleright',
	clock: 'clock',
	close: 'close',
	cross: 'cross',
	collapse: 'collapse',
	dial: 'dial',
	edit: 'edit',
	downarrow: 'downarrow',
	email: 'email',
	extract: 'extract',
	facebook_colour: 'facebook_colour',
	facebook_greyscale: 'facebook_greyscale',
	fileattachment: 'fileattachment',
	folder: 'folder',
	instagram_greyscale: 'instagram_greyscale',
	hangup: 'hangup',
	instagram_colour: 'instagram_colour',
	layout: 'layout',
	layout_bottomrowsplit: 'layout_bottomrowsplit',
	layout_crosssplit: 'layout_crosssplit',
	layout_leftcolumnsplit: 'layout_leftcolumnsplit',
	layout_fullsize: 'layout_fullsize',
	layout_rightcolumnsplit: 'layout_rightcolumnsplit',
	layout_toprowsplit: 'layout_toprowsplit',
	layout_tworows: 'layout_tworows',
	layout_twocolumns: 'layout_twocolumns',
	leftarrow: 'leftarrow',
	menu: 'menu',
	mic: 'mic',
	micmute: 'micmute',
	note: 'note',
	paperclip: 'paperclip',
	pause: 'pause',
	phone: 'phone',
	play: 'play',
	rightarrow: 'rightarrow',
	robot: 'robot',
	settings: 'settings',
	search: 'search',
	sms_colour: 'sms_colour',
	sms_greyscale: 'sms_greyscale',
	submenu: 'submenu',
	tasklist: 'tasklist',
	tick: 'tick',
	transfercall: 'transfercall',
	twitter_colour: 'twitter_colour',
	user: 'user',
	userchange: 'userchange',
	twitter_greyscale: 'twitter_greyscale',
	viber_colour: 'viber_colour',
	webchat_colour: 'webchat_colour',
	webchat_greyscale: 'webchat_greyscale',
	whatsapp_colour: 'whatsapp_colour',
	videocall: 'videocall',
	whatsapp_greyscale: 'whatsapp_greyscale',
	instagram_colour: 'instagram_colour',
	instagram_greyscale: 'instagram_greyscale'
};

export default ICONS;
export {ICONS};