import {put, takeLatest, all} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import * as Actions from '../actions';

import {LOGIN_PHASE} from '../data/states';
import {setLoginPhase, loginPhaseChanged} from '../actions';

function* onLoginPhaseChange(action) {

	const {phase} = action.payload;

	switch (action.payload.phase) {
		
		case LOGIN_PHASE.init:
			yield put(loginPhaseChanged(phase));
			yield put(setLoginPhase(LOGIN_PHASE.login));
			break;

		case LOGIN_PHASE.login:
			yield delay(1500);
			yield put(loginPhaseChanged(phase));
			break;

		case LOGIN_PHASE.loginForm:
			yield put(loginPhaseChanged(phase));
			break;

		default:
	}
}

export default function*() {
	yield all([
		takeLatest(Actions.setLoginPhase.getType(), onLoginPhaseChange)
	]);
}
