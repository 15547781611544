import {createReducer} from 'redux-act';
import _ from 'lodash';
import * as Actions from '../../actions';

import API from '../../api/Api';

export default createReducer({
	[Actions.customerNameReceived]: (state, payload) => {
        const newState = {...state};
        const {customerId, customerName} = payload;

        newState[customerId] = customerName;

        return newState;
    },

	
}, {});

