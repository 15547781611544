import { select, put, takeLatest, all } from 'redux-saga/effects';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { checkBackendLoggedIn, wasLogOutRequested, getCapabilityResponse } from '../selectors'

function* stateDataRetrieved(action) {

    let { data } = action.payload;

    const capabilityPreStore = yield select((state) => getCapabilityResponse(state));

    yield put.resolve(Actions.storeStateData(data));

    const capabilityPostStore = yield select((state) => getCapabilityResponse(state));

    if ((capabilityPreStore === null || capabilityPreStore === undefined) &&
        (capabilityPostStore !== null || capabilityPostStore !== undefined)) {
        // We got the first agent state snapshot with the infrastructure urls. Let's 
        // fetch and initialize those data in the Redux state which are not received
        // as part of the agent state snapshot.
        //
        // TODO: move out the logic to somewhere else as the scope of this method
        // has nothing to do with that.
        yield put(Actions.retrieveBreakNames());
    }

    // auto login
    const loggedIn = yield select(state => checkBackendLoggedIn(state));

    if (loggedIn !== true) {

        const logoutRequested = yield select(state => wasLogOutRequested(state));

        if (logoutRequested) {

            //we shouldn't log back in, nothing to do here
        }
        else {
            const superVisor = yield select(Selectors.getSupervisorStatus);
            yield put(Actions.logIn(superVisor));
        }
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.stateDataRetrieved.getType(), stateDataRetrieved)
    ]);
}
