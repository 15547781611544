import { createReducer } from 'redux-act';

import * as Actions from '../../actions';
import API from '../../api/Api';
// cleanup
export default createReducer({

	[Actions.storeBackEndInfo]: (state, payload) => ({
		...state,
		[API.backEnd.stateUrl]: payload.backEndUrl,
		[API.backEnd.loginUrl]: payload.loginUrl,
		[API.backEnd.instrumentationKey]: payload.instrumentationKey,
		[API.backEnd.sipConnectorUrl]: payload.sipConnectorUrl
	}),

	[Actions.stateDataRetrievalFailed]: (state) => {
		const newState = { ...state };
		newState[API.backEnd.failedAttempt] = newState[API.backEnd.failedAttempt] + 1;
		return newState;
	},

	[Actions.stateDataRetrieved]: (state) => {
		const newState = { ...state };
		newState[API.backEnd.failedAttempt] = 0;
		return newState;
	},

	[Actions.saveSignalR]: (state, payload) => {
		const newState = { ...state };
		newState["signalR"] = payload.connection;
		return newState;
	}
}, {});