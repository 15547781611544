import API from '../api/Api';
import ReduxModels from '../api/ReduxModels';
import { backEndProps } from '../selectors/backEnd';
import { getWorkItemsV2 } from './conversations';

/**
 * Retrives the (latest) capability snapshot from the store
 * @param {*} state 
 */
export const getCapabilityResponse = (state) => {
	return backEndProps(state)[API.backEnd.stateData];
}

/**
 * Checks if backend is connected
 * @param {*} state 
 */
export const checkReceivedBackendStateAtLeastOnce = (state) => {
	const capSnap = getCapabilityResponse(state);
	return !(capSnap === null || capSnap === "" || capSnap === undefined);
}

/**
 * Checks if the Client is logged in
 * @param {*} state 
 */
export const checkBackendLoggedIn = (state) => {
	if (!(checkReceivedBackendStateAtLeastOnce(state))) {
		return false;
	}
	return (getUserStatus(state) !== API.agentStatusTypes.loggedOut);
}

/**
 * Checks if the Client is logged in
 * @param {*} state 
 */
export const checkBackendConnected = (state) => {
	if (!(checkReceivedBackendStateAtLeastOnce(state))) {
		return false;
	}
	return (backEndProps(state)[API.backEnd.failedAttempt] < 5);
}

/**
 * Checks the stateData and returns whether the agent state is known by the backend service
 * @param {*} state 
 */
export const checkBackendState = (state) => {
	if (state) {
		const agentProps = getAgentProperties(state);
		if (agentProps) {
			if (agentProps[API.agentProperties.state]) {
				return true;
			}
		}
	}
	return false;
}

/**
 * returns the agent's state
 * @param {*} state 
 */
export const getUserStatus = (state) => {
	const agentProps = getAgentProperties(state);
	return agentProps[API.agentProperties.state];
}

export const getUserStateSince = (state) => {
	const agentProps = getAgentProperties(state);
	let time = Date.parse(agentProps[API.agentProperties.stateSince]);
	if(!time)
	{
		return 0;
	}
	return time;
}

/**
 * return the agent properties part of the capability Response 
 * @param {*} state 
 */
export const getAgentProperties = (state) => {
	const capSnap = getCapabilityResponse(state);
	return capSnap[API.capabilityResponse.agentProperties];
}

/**
 * returns the agent's profile image URL
 * @param {*} state 
 */
export const getUserProfileImage = (state) => {
	return "";
}

/**
 * Returns whether resource has a workItem on them
 * @param {*} state 
 */
export const hasOpenWorkItem = (state) => {
	return getWorkItemIds(state).length;
}

export const getWorkItemIds = (state) => {
	const workItems = getWorkItemsV2(state);
	return Object.getOwnPropertyNames(workItems);
}

/**
 * Returns the customerName
 * @param {*} state 
 * @param {*} workItemId 
 */
export const getCustomerName = (state, workItemId) => {
	const workItems = getWorkItemsV2(state);
	if (workItemId in workItems) {
		const customerId = workItems[workItemId][API.workItemV2.customerId];
		if (customerId) {
			if (customerId in state[ReduxModels.nodes.customerNames]) {
				const customerName = state[ReduxModels.nodes.customerNames][customerId];
				if (customerName) {
					return customerName;
				}
			}
		}
	}
	return "Customer Task"
}

/**
 * returns the resourceId belonging to this agent
 * @param {*} state 
 */
export const getResourceId = (state) => {
	const agentProperties = getAgentProperties(state);
	return agentProperties[API.agentProperties.id];
}

/**
 * Returns the possible disposition Codes
 * @param {*} state 
 */
export const getDispositionCodes = (state) => {
	const result = {};

	const workItems = getWorkItemsV2(state);
	const workItemIds = Object.getOwnPropertyNames(workItems);
	workItemIds.forEach(workItemId =>{
		if (workItemId in workItems){
			result[workItemId] = workItems[workItemId][API.workItemV2.usableClosureCodes];
		}
	});
	return result;
}


export const autoOpenTaskManager = (state, workItem) => {
	const workItemId = workItem[API.workItemV2.workItemId];	
	const timeDiff = new Date(state[ReduxModels.nodes.backEnd][API.backEnd.stateData][API.capabilityResponse.timeStamp]) - new Date(workItem[API.workItemV2.lastStateChangeDate]) ;
	if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.afterwork && 
		(timeDiff < 1000) &&
		state[ReduxModels.nodes.user][API.user.isTaskManagerOpen] !== true &&
		state[ReduxModels.nodes.user][API.user.currentPanelSet] === workItemId)
	{
		return true;
	}
	return false;
}