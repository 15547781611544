import API from "../api/Api";
import { getUserStatus } from "./capability";

const resourceStateChangeMapping = {  

    [API.agentStatusTypes.break]: [
        API.resourceStateChanges.leaveBreak, 
        API.resourceStateChanges.logOut,
        //for switching between break codes
        API.resourceStateChanges.goToBreak
    ],
        
    [API.agentStatusTypes.idle]: [
        API.resourceStateChanges.goToBreak, 
        API.resourceStateChanges.logOut
    ],

    [API.agentStatusTypes.busy]: [
        API.resourceStateChanges.logOut,
        API.resourceStateChanges.goToBreak,
    ], 

    [API.agentStatusTypes.pendingBreak]: [
        API.resourceStateChanges.logOut
    ], 

    [API.agentStatusTypes.loggedOut]: [
        API.resourceStateChanges.logIn
    ],    
    
    [API.agentStatusTypes.pendingLogout]: [],
}


export const getAvailableStateChanges = (state) =>{
    const currentState = getUserStatus(state);
    return currentState in resourceStateChangeMapping? resourceStateChangeMapping[currentState] : [];
}