import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import ContainerDimensions from 'react-container-dimensions';
import classNames from 'classnames';
import _ from 'lodash';
import mezr from 'mezr';

import API from '../../api/Api';
import { STAGE_POSITON, STAGE_TRANSITIONS, LAYOUT_BY_ITEMS, EXTENDS_TO_BOTTOM, EXTENDS_TO_SIDE } from '../../data/grid';
import ICONS from '../../data/icons';
import {BREAKPOINT_IDS} from '../../data/breakpoints';

import sidebarMessages from '../SidebarComposed/SidebarComposed.messages';
import breakStatusMessages from '../UserStatus/UserStatus.messages';

import {getOpenPanelSetList, getPanelSetsOfStage, getStageById, getUserInfo, hasOpenPanelSet, getUserStatus, getCurrentBreakName} from '../../selectors';
import {swapGridItems, addStageItem, updateLayout, changeLayout, extractPanelSet} from '../../actions';

import PanelSetContainer from '../PanelSetContainer/PanelSetContainer';
import GridView from '../../components/GridView/GridView';
import GridItem from '../../components/GridItem/GridItem';
import PanelTabsSubmenu from '../../components/PanelTabsSubmenu/PanelTabsSubmenu';
import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';
import {getCustomerName} from '../../selectors/capability';


import './Stage.css';

/**
 * Stage that contains panelsets, panels, and panelgroups (tabs)
 */
class Stage extends PureComponent {

	constructor(props) {
		super(props);
		this.containers = {};
		this.state = {
			dragged: false
		};
	}

	onDragStart = (panelSetId) => {
		this.setState(() => ({ dragged: panelSetId }));
	};

	onDragStop = () => {
		this.setState(() => ({ dragged: false }));
	};

	repositionCase = (panelSetId, index, node) => {

		this.setState(() => ({ dragged: false }));

		const otherContainers = _.omit(this.containers, [index]);

		let currentOverlap = {
			index: -1,
			overlap: 0
		};

		if (_.size(otherContainers) > 0) {
			// Compare dragged node to all other nodes

			_.forOwn(otherContainers, (container, index) => {
				const intersection = mezr.intersection(node, container);

				if (intersection) {
					const overlapSize = Math.min(intersection.width, intersection.height);
					if (overlapSize > currentOverlap.overlap) {
						currentOverlap = {
							index: index,
							overlap: overlapSize
						}
					}
				}
			});
		}

		if (currentOverlap.index > -1 && currentOverlap.overlap > 100) {
			this.props.onSwapGridItems(this.props.stageId, index, currentOverlap.index);
			return false;
		} else {
			// Snap back to position
			node.removeAttribute('style');
		}

		return true;
	};
	
	addButton = (side) => {
		const {intl, layout, openPanelSets, status, onAddStageItem, currentBreakName} = this.props;
		const {formatMessage} = intl;

		const layoutId = layout.id;
		const nextLayout = STAGE_TRANSITIONS[layoutId][side];

		let	button = (
				<PanelTabsSubmenu side={side} dividers={true} icon={ICONS.add}>
				{
					_.map(openPanelSets, (openPanelSet) => {

						if (!openPanelSet) {
							return null;
						}
			
						let title, subtitle;

						const panelSetId = openPanelSet[API.panelSet.id];
						const panelSetStatus = openPanelSet[API.panelSet.status];
						const panelSetColor = openPanelSet[API.panelSet.color];			
						const customerName = openPanelSet[API.panelSet.customerName];
			
						switch (panelSetStatus) {
			
							case API.panelSet.statuses.incoming:
								title = formatMessage(sidebarMessages.SidebarComposed_Incoming);
								subtitle = formatMessage(sidebarMessages.SidebarComposed_IncomingWaiting);
								break;
			
							case API.panelSet.statuses.search:
								title = formatMessage(sidebarMessages.SidebarComposed_search);
								break;
			
							case API.panelSet.statuses.request:
								if (status === API.agentStatusTypes.pendingLogout) {
									subtitle = formatMessage(sidebarMessages.SidebarComposed_Logout);
								} 
								else if (status === API.agentStatusTypes.pendingBreak) {
									if(!currentBreakName) {
										subtitle = formatMessage(breakStatusMessages['UserStatus_' + API.agentStatusTypes.pendingBreak]);
									}
									else {
										subtitle = currentBreakName;
									}
								}
			
								title = formatMessage(sidebarMessages.SidebarComposed_Request);
								break;
			
							case API.panelSet.statuses.inprogress:
								title = customerName;
								break;

							case API.panelSet.statuses.callLookup:
								title =  formatMessage(sidebarMessages.SidebarComposed_NewVoiceCall);
								break;
							
							case API.panelSet.statuses.supervisor:
								title = formatMessage(sidebarMessages.SidebarComposed_Supervisor);
								break;
										
							//Todo: extend this ro properly name stages
							default:
								title = 'defaultStageTitle';
								subtitle = null;
						}
			
						const classes = classNames({
							'panel-tab-submenu-item': true,
							[`panel-tab-submenu-item--color-${panelSetColor}`]: !_.isNil(panelSetColor)
						});
						
						return (
							<span className={classes} key={"caseTab_span_" + panelSetId}>
							{
								<ActionButton clickHandler={() => onAddStageItem(panelSetId, nextLayout)} key={"caseTab_button_" + panelSetId} text={title} buttonStyle={[BUTTON_STYLE.transparent]}>
									{
										subtitle &&
											<span className="main-stage__add-caseinfo" key={"caseTab_subtitle_" + panelSetId}>
												{subtitle}
											</span>
									}
								</ActionButton>
							}
							</span>
						);
					})
				}
				</PanelTabsSubmenu>
			);
		
		return button;
	};

	setDefaultLayout = (props) => {
		const { layout, panelSetIds, onChangeLayout, stageId } = props;
		if (!layout || !layout.grid || layout.grid.length !== panelSetIds.length) {
			onChangeLayout(stageId, LAYOUT_BY_ITEMS[panelSetIds.length - 1][0]);
		}
	};

	autoExtract = () => {
		// Auto extract panelSets that will not fit into layout
		const { tablet, panelSetIds, currentPanelSet, onExtract } = this.props;
		if (tablet && panelSetIds.length > 1) {
			const extractable = _.without(panelSetIds, currentPanelSet);
			_.forEach(extractable, (panelSetId) => onExtract(panelSetId));
		}
	};

	UNSAFE_componentWillMount() {
		this.setDefaultLayout(this.props);
		this.autoExtract();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setDefaultLayout(nextProps);
		if (this.props.width !== nextProps.width) {
			this.autoExtract();
		}
	}

    render() {
        const {tablet, stageId, layout, panelSetIds, openPanelSets, onUpdateLayout, hidden} = this.props;

		if (!panelSetIds || panelSetIds.length === 0 || !layout || !layout.id) {
			return null;
		}

		const layoutId = layout.id;
		const classes = classNames({
			'main-stage': true,
			'main-stage--faded': false,
			'hidden': hidden
		});
		
		this.containers = {};

		// Build panelSet collection
		const panelSetContainers = _.reduce(panelSetIds, (result, panelSetId, index) => {
			result.push(
				<GridItem key={'panelSet_' + panelSetId + index} caseContainer={true} panelSetId={panelSetId}>
					<PanelSetContainer dragged={this.state.dragged} onDragStart={this.onDragStart} onDragStop={this.onDragStop} containerRef={(el) => this.containers[index] = el} onDragged={this.repositionCase} extractable={panelSetIds.length > 1} panelSetId={panelSetId} index={index} />
				</GridItem>
			);
			return result;
		}, []);

		const grid = (
			<ContainerDimensions>
				<GridView layout={layout} panelSetLayout={true} onUpdateLayout={(grid) => onUpdateLayout(stageId, grid, true)}>
					{panelSetContainers}
				</GridView>
			</ContainerDimensions>
		);

		let addButtonSide, addButtonBottom;
		if (openPanelSets.length > 0) {

			if (_.includes(EXTENDS_TO_SIDE, layoutId)) {
				addButtonSide = this.addButton(STAGE_POSITON.side);
			}

			if (_.includes(EXTENDS_TO_BOTTOM, layoutId)) {
				addButtonBottom = this.addButton(STAGE_POSITON.bottom);
			}
		}

		return (
			<main className={classes}>
				<div className="main-stage__container">
					{grid}
					{
						!tablet &&
						<div className="main-stage__add-bar main-stage__add-bar--side">
							{addButtonSide}
						</div>
					}
				</div>
				{
					 !tablet &&
					<div className="main-stage__add-bar main-stage__add-bar--bottom">
						{addButtonBottom}
					</div>
				}
			</main>
		);
	}

	static propTypes = {
		intl: intlShape.isRequired,
		stageId: PropTypes.string.isRequired,
		panelSetIds: PropTypes.array.isRequired,
		layout: PropTypes.object.isRequired,
		openPanelSets: PropTypes.array,
		status: PropTypes.string,
		currentBreakName: PropTypes.string,
		currentPanelSet: PropTypes.string,
		tablet: PropTypes.bool,
		onAddStageItem: PropTypes.func,
		onSwapGridItems: PropTypes.func,
		onUpdateLayout: PropTypes.func,
		onChangeLayout: PropTypes.func,
		onExtract: PropTypes.func,
		width: PropTypes.number,
		hidden: PropTypes.bool,
	};

	static defaultProps = {
		layout: {}
	};
}

const mapStateToProps = (state, ownProps) => {

	const user = state[API.user._key];
	const stages = user[API.user.stages];

	if (!stages) return {};

	const stageId = ownProps.stageId;
	const currentStage = getStageById(state, stageId);
	const currentPanelSet = getUserInfo(state, API.user.currentPanelSet);
	const panelSetIds = getPanelSetsOfStage(state, stageId);
	const status = getUserStatus(state);
	const currentBreakName = getCurrentBreakName(state);
	const layout = currentStage[API.stage.layout];
	const openPanelSets = getOpenPanelSetList(state, stageId);

	openPanelSets.forEach(p=>{
		p[API.panelSet.customerName] = getCustomerName(state,p[API.panelSet.workItemId]);		
	});
	const tablet = state.browser.lessThan[BREAKPOINT_IDS.desktop];
	return {
		stageId,
		panelSetIds,
		layout,
		openPanelSets,
		status,
		currentPanelSet,
		tablet,
		currentBreakName
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddStageItem: bindActionCreators(addStageItem, dispatch),
		onSwapGridItems: bindActionCreators(swapGridItems, dispatch),
		onUpdateLayout: bindActionCreators(updateLayout, dispatch),
		onChangeLayout: bindActionCreators(changeLayout, dispatch),
		onExtract: bindActionCreators(extractPanelSet, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Stage));