import { defineMessages } from 'react-intl';

const messages = defineMessages({
	CaseContainer_SearchCaseHeading: {
		defaultMessage: 'Search panel sets',
		id: 'CaseContainer_SearchCaseHeading',
		description: 'Search panel header / default title'
	},

	CaseContainer_IncomingHeading: {
		defaultMessage: 'Waiting for incoming interaction',
		id: 'CaseContainer_IncomingHeading',
		description: 'Incoming interaction header / default title'
	}
});

export default messages;