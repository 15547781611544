import { createReducer } from 'redux-act';

import * as Actions from '../../actions';
import API from '../../api/Api';

export default createReducer({

  [Actions.addMessage]: (state, { message }) => [...state, message],

  [Actions.removeMessage]: (state, { id }) => state.filter(m => m.id !== id),

}, {});