import {createAction} from 'redux-act';

export const increaseWavePhase = createAction('Increase wave phase');

// Used to load default stage, dashboard panelset, breakpanel (or startshift panel)
export const loadInitialElements = createAction("Loads Initial components", (isSupervisor) => ({isSupervisor}));

// Used to open the user settings panel
export const openUserSettings = createAction("Opens the user settings panel");
export const closeSupervisorBreakCard = createAction("Close Supervisor Break Card");

