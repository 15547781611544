import API from '../api/Api';

import _ from 'lodash';
import { getAgentProperties } from './capability';
import { getSupervisorStatus } from './user';

export const getBreakNames = (state) => {
	var breakNames = [];
	if  (state && API.breakNames._key in state && Array.isArray(state[API.breakNames._key])){
		breakNames = state[API.breakNames._key].slice();
	} 

	if (getSupervisorStatus(state)){
		breakNames.push("Supervisor Mode")
	}
	return breakNames;
};


/**
 * returns the agent's current break Name
 * @param {*} state 
 */
export const getCurrentBreakName = (state) => {

	const agentProps = getAgentProperties(state);
	if (API.agentProperties.breakName in agentProps){
		const breakName = agentProps[API.agentProperties.breakName];
		return breakName ?? null;
	}

	// const currentBreakNameId = getCurrentBreakNameId(state);
	// const breakNames = getBreakNames(state);

	// const currentBreakName = _.find(breakNames, {[API.breakName.id]: currentBreakNameId});

	// return currentBreakName;
}
