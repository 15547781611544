import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {injectIntl, intlShape} from 'react-intl';

import API from '../../api/Api';
import ICONS from '../../data/icons';

import messages from './Request.messages';
import startShiftMessages from '../StartShift/StartShift.messages';

import {getUserStatus, getCurrentBreakName, getUserStateSince} from '../../selectors';
import {increaseWavePhase} from '../../actions';
import * as Actions from '../../actions';
import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';
import Timer from '../../components/Timer/Timer';
import Waves, {WAVE_TYPES} from '../Waves/Waves';
import StartShiftLayout from '../../layouts/StartShiftLayout/StartShiftLayout';


/**
 * If you want to switch between states but can't right away, a panel renders one of these things.
 * Example being trying to go to break while you have a task on, rather than getting the default brake screen, you get this one
 */
class Request extends Component {

	constructor(props) {
		super(props);
		this.waves = <Waves waveType={WAVE_TYPES.startShift} />;
	}

	componentDidMount() {
	this.props.onWave();
	}

	render() {

		const {intl, status, currentBreakName, onRequestFabricActivate, onRequestLogout, stateSince} = this.props;
		const {formatMessage} = intl;
		const layoutProps = {};

		const autoButtonLabel = formatMessage(startShiftMessages.StartShift_continueInAutoMode);
		switch (status) {
			case API.agentStatusTypes.break:					

				layoutProps.requestBreakButtonsTitle = formatMessage(messages.BreakRequest_requestBreakButtonsTitle);
				layoutProps.logoutButton = <ActionButton clickHandler={() => onRequestLogout()} text="Log out" buttonStyle={[BUTTON_STYLE.transparent]}/>;

				layoutProps.timer = <Timer key="timer_break" autoStart={true} startTime={stateSince}/>;	
				if(!currentBreakName) {
					layoutProps.title = formatMessage(messages.BreakRequest_defaultBreakName);
				}
				else {
					layoutProps.title = currentBreakName;
					layoutProps.timer = <Timer key={stateSince} autoStart={true} startTime={stateSince}/>;	
				}
				layoutProps.autoModeButton = <ActionButton id={"automodeButtonId"} clickHandler={() => onRequestFabricActivate()} icon={ICONS.tick} text={autoButtonLabel} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]}/>;
				layoutProps.instructions = '';
			break;

			case API.agentStatusTypes.pendingLogout:
				layoutProps.title = formatMessage(messages.LogoutRequest_title);
				layoutProps.instructions = formatMessage(messages.LogoutRequest_instructions);
				layoutProps.requestBreakButtonsTitle = formatMessage(messages.LogoutRequest_requestBreakButtonsTitle);
				layoutProps.autoModeButton = <ActionButton id={"automodeButtonId"} clickHandler={() => onRequestFabricActivate()} icon={ICONS.close} text={formatMessage(messages.LogoutRequest_cancel)} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]}/>;
			break;

			case API.agentStatusTypes.pendingBreak:
				layoutProps.title = formatMessage(messages.BreakRequest_title);
				layoutProps.instructions = formatMessage(messages.BreakRequest_instructions);
				layoutProps.requestBreakButtonsTitle = formatMessage(messages.BreakRequest_requestBreakButtonsTitle);
				layoutProps.autoModeButton = <ActionButton id={"automodeButtonId"} clickHandler={() => onRequestFabricActivate()} icon={ICONS.close} text={formatMessage(messages.BreakRequest_cancel)} buttonStyle={[BUTTON_STYLE.negative, BUTTON_STYLE.raised]}/>;
			break;
			default:
			return null;			
		}

		layoutProps.waves = this.waves;
		
		return <StartShiftLayout statusType={status} {...layoutProps} />;
	}

	static propTypes = {
		intl: intlShape.isRequired,
		history: PropTypes.object,
		status: PropTypes.string,
		currentBreakName: PropTypes.string,
		onRequestFabricActivate: PropTypes.func,
		onRequestLogout: PropTypes.func,
		onWave: PropTypes.func,
		stateSince: PropTypes.number
	};
}

const mapStateToProps = (state) => {
	const status = getUserStatus(state);
	const currentBreakName = getCurrentBreakName(state);
	const stateSince = getUserStateSince(state);
	return {
		status,
		currentBreakName,
		stateSince
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRequestFabricActivate: bindActionCreators(Actions.leaveBreak, dispatch),		
		onRequestLogout: bindActionCreators(Actions.logOut, dispatch),
		onWave: bindActionCreators(increaseWavePhase, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Request));