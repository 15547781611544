import {
  getInitialDataLoaded, getPanelSetIdsWithoutStages, getUserStatus, getStageIds, getStageIndex, checkBackendLoggedIn, autoOpenTaskManager
} from '../../selectors';

import * as Selectors from '../../selectors';

import API from '../../api/Api';
import { guid } from '../../services/utils';
import ReduxModels from '../../api/ReduxModels';
import { GRID_LAYOUTS, LAYOUT_IDS } from '../../data/grid';


const backendSyncReducer = (state, action) => {
  const newState = { ...state };
  //First put the new capabilityResponse into the state store
  newState[API.backEnd._key] = { ...newState[API.backEnd._key] };
  newState[API.backEnd._key][API.backEnd.stateData] = action.payload.data;
  newState[API.backEnd._key][API.backEnd.lastStateTimestamp] = Date.now();

  if (state[API.user._key][API.user.isTaskManagerOpen]) {
    const workitems = Selectors.getWorkItemsV2(state);
    if (!Object.keys(workitems).includes(state[API.user._key][API.user.taskManagerPanelSet])) {
      newState[API.user._key][API.user.isTaskManagerOpen] = null;
    }
  }

  //Then map the ui elements by calling these functions one after another.
  // Note: deletion of panels (especially status related ones, like idlepanel, breakpanel) is unecessary if there is no stage that contains any panelset that would contain said panel, then it wouldn't be rendered
  // Same thing goes for panelSets
  // Hence the following functions could be much more simpler
  if (getInitialDataLoaded(newState) && checkBackendLoggedIn(newState)) {
    return removeEmptyStages(
      removeDeletedPanelSetsFromStages(
        createStageForPanelSets(
          syncTeamsCallsWithBackendState(
            mapAgentStateWithStatePanels(
              mapAgentStateWithStatePanelSets(newState)
            )
          )
        )
      )
    );
  }

  //force logout happened - set user logout requested to handle initial login correctly
  if(!checkBackendLoggedIn(newState) && checkBackendLoggedIn(state)){
    newState[API.user._key][API.user.logoutRequested] = true;
  }

  return newState;
}

const mapConversationWithPanelType = channelType => {
  switch (channelType.toLowerCase()) {
    case API.conversationChannelTypes.voice:
      return API.panel.types.teamsCall
    case API.conversationChannelTypes.email:
      return API.panel.types.email
    case API.conversationChannelTypes.virtual:
      return API.panel.types.campaign
    default:
      return API.panel.types.chat
  }
}


const syncPanelSetsWorkItemsV2 = (newState) => {
  const workItems = Selectors.getWorkItemsV2(newState);
  const workItemIds = Object.getOwnPropertyNames(workItems);
  const workItemPanelSetIds = Selectors.getWorkItemV2PanelSets(newState);
  const panelStore = newState[ReduxModels.nodes.panel];
  const panelSetStore = newState[ReduxModels.nodes.panelSet];

  workItemPanelSetIds.forEach(panelSetId => {
    if (panelSetStore[panelSetId]) {
      const panelSet = panelSetStore[panelSetId];
      const workItemId = panelSet[API.panelSet.workItemIdV2];

      if (!(workItemIds.includes(workItemId))) {
        panelSet[API.panelSet.panels].forEach(panelGroup => {
          delete panelStore[panelGroup[API.panelGroup.id]];
        })
        delete panelSetStore[panelSetId];
      }
    }

  });

  workItemIds.forEach(workItemId => {
    const panelSetId = workItemId;
    if (!(panelSetStore[panelSetId])) {
      const customerPanelId = "customerPanel_" + workItemId;
      panelSetStore[panelSetId] = {
        [API.panelSet.id]: panelSetId,
        [API.panelSet.status]: API.panelSet.statuses.inprogress,
        [API.panelSet.layout]: {},
        [API.panelSet.color]: reservePanelSetColor(newState),
        [API.panelSet.panels]: [],
        [API.panelSet.workItemIdV2]: workItemId,
        [API.panelSet.createdAt]: Date.parse(workItems[workItemId][API.workItemV2.createdAt])
      };
      if (workItems[workItemId][API.workItemV2.workItemState] !== API.workItemV2.workItemStates.lookUp) {
        panelStore[customerPanelId] = {
          [API.panel.id]: customerPanelId,
          [API.panel.type]: API.panel.types.customerview,
          [API.panel.workItemId]: workItemId
        };
        panelSetStore[panelSetId][API.panelSet.panels] = [
          {
            [API.panelGroup.id]: customerPanelId,
            [API.panelGroup.panels]: [customerPanelId],
            [API.panelGroup.active]: customerPanelId
          }
        ];
      }


    }
    //lookup is a request without taskManager, rest is inprogress
    panelSetStore[panelSetId][API.panelSet.status] = workItems[workItemId][API.workItemV2.workItemState] === API.workItemV2.workItemStates.lookUp ? API.panelSet.statuses.callLookup : API.panelSet.statuses.inprogress;
  });
  return newState;
}

const syncTeamsCallsWithBackendState = (newState) => {
  newState = syncPanelSetsWorkItemsV2(newState);
  const workItems = Selectors.getWorkItemsV2(newState);
  const workItemIds = Object.getOwnPropertyNames(workItems);

  const panelStore = newState[API.panel._key];
  const panelSetStore = newState[API.panelSet._key];
  const scriptingReduxNode = newState[ReduxModels.nodes.scripting];
  const chatStore = { ...newState[ReduxModels.nodes.chat] }
  newState[ReduxModels.nodes.chat] = chatStore;
  workItemIds.forEach(workItemId => {
    const workItem = workItems[workItemId];
    const panelSetId = workItemId;
    const panelSet = panelSetStore[panelSetId];
    var panelGroups = panelSetStore[panelSetId][API.panelSet.panels].slice();
    panelSetStore[panelSetId][API.panelSet.panels] = panelGroups;

    for (const panelId in panelStore) {
      if (panelStore.hasOwnProperty(panelId)) {
        const panel = panelStore[panelId];
        if (panel[API.panel.workItemId] === workItemId) {
          if (panel[API.panel.conversationId]) {
            if (!((Object.getOwnPropertyNames(workItem[API.workItemV2.conversations])).includes(panel[API.panel.conversationId]))) {
              delete chatStore[panel[API.panel.conversationId]];
              delete panelStore[panelId];
              panelGroups = panelGroups.filter(x => x[API.panelGroup.id] !== panelId);

              panelSet[API.panelSet.panels] = panelGroups;
              if (panelGroups.length) {
                const firstPanelGroup = { ...panelGroups[0] };
                panelGroups[0] = firstPanelGroup;
                firstPanelGroup[API.panelGroup.active] = firstPanelGroup[API.panelGroup.panels][0];
                panelSet[API.panelSet.layout] = {};
              }

            }
          }
          if (panel[API.panel.type] === panel[API.panel.types.callLookup] && workItem[API.workItemV2.workItemState] !== workItem[API.workItemV2.workItemStates.lookUp]) {

          }
        }

      }
    }

    const callLookupPanelId = "callLookup_" + workItemId;
    panelGroups = panelSetStore[panelSetId][API.panelSet.panels];
    if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.lookUp) {
      if (!panelGroups.length) {

        panelStore[callLookupPanelId] =
        {
          [API.panel.id]: callLookupPanelId,
          [API.panel.type]: API.panel.types.callLookup,
          [API.panel.workItemId]: workItemId
        };

        panelGroups.push({
          [API.panelGroup.id]: callLookupPanelId,
          [API.panelGroup.panels]: [callLookupPanelId],
          [API.panelGroup.active]: callLookupPanelId
        });
      }
    }
    else {
      if (callLookupPanelId in panelStore) {
        delete panelStore[callLookupPanelId];
        panelGroups = panelGroups.filter(x => x[API.panelGroup.id] !== callLookupPanelId);
        panelSet[API.panelSet.panels] = panelGroups;
        panelSet[API.panelSet.layout] = {};
      }

      if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.afterwork) {
        const afterWorkPanelId = "afterwork_" + workItemId;
        if (!(afterWorkPanelId in panelStore)) {
          panelStore[afterWorkPanelId] =
          {
            [API.panel.id]: afterWorkPanelId,
            [API.panel.type]: API.panel.types.closedCall,
            [API.panel.workItemId]: workItemId
          };
          panelGroups.push({
            [API.panelGroup.id]: afterWorkPanelId,
            [API.panelGroup.panels]: [afterWorkPanelId],
            [API.panelGroup.active]: afterWorkPanelId
          });
        }

      }
    }

    for (const conversationId in workItem[API.workItemV2.conversations]) {
      if (workItem[API.workItemV2.conversations].hasOwnProperty(conversationId)) {
        const conversation = workItem[API.workItemV2.conversations][conversationId];


        const panelId = conversationId;


        const panelType = mapConversationWithPanelType(conversation[API.conversation.channelType]);

        var foundThePanel = false;
        if (panelGroups.length > 0) {

          panelGroups.forEach(panelGroup => {
            if (panelGroup[API.panelGroup.id] === panelId) {
              foundThePanel = true;
              const panel = panelStore[panelId];
              panel[API.panel.type] = panelType;
              panel[API.panel.conversationId] = conversationId;
            }
          })
        }

        if (!foundThePanel) {
          panelStore[panelId] =
          {
            [API.panel.id]: panelId,
            [API.panel.type]: panelType,
            [API.panel.conversationId]: conversationId,
            [API.panel.workItemId]: workItemId
          };

          const newPanelGroup = {
            [API.panelGroup.id]: panelId,
            [API.panelGroup.panels]: [panelId]
          };

          if (panelGroups.length == 0) {
            panelGroups.push(newPanelGroup);
          }
          else {
            if (panelStore[panelGroups[0][API.panelGroup.id]][API.panel.conversationId]) {
              panelGroups.splice(1, 0, newPanelGroup);
              panelSetStore[panelSetId][API.panelSet.layout] = GRID_LAYOUTS[LAYOUT_IDS.leftColumnSplit]

            }
            else {
              panelGroups.splice(0, 0, newPanelGroup);
            }
          }


        }

        if (conversationId && (!(workItemId in scriptingReduxNode))) {
          scriptingReduxNode[workItemId] = {
            [ReduxModels.scripting.loading]: true
          }
        }

      }
    }

    if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.closed && panelGroups.length === 0) {

      const panelId = "closed" + workItemId;
      panelGroups.push({
        [API.panelGroup.id]: panelId,
        [API.panelGroup.panels]: [panelId]
      })

      panelStore[panelId] = {
        [API.panel.id]: panelId,
        [API.panel.type]: API.panel.types.closedCall,
        [API.panel.workItemId]: workItemId
      }
    }

    //auto-open taskmanager if the workitem has just been moved to afterworkstate	
    if (autoOpenTaskManager(newState, workItem)) {
      newState[ReduxModels.nodes.user][API.user.taskManagerPanelSet] = workItemId;
      newState[ReduxModels.nodes.user][API.user.isTaskManagerOpen] = true;
    }


  });

  return newState;
}


/**
 * Maps agent state with state-related panels (creates or deletes them)
 * Checks what status the agent is in, then removes every state-panels which aren't the correct one, then creates the correct one
 * @param {*} state 
 */
const mapAgentStateWithStatePanels = (state) => {

  const newState = { ...state };
  const panelStore = { ...newState[API.panel._key] };
  newState[API.panel._key] = panelStore;
  const userStatus = getUserStatus(newState);

  if (panelStore.hasOwnProperty(API.panel.types.startShift)) {

    delete panelStore[API.panel.types.startShift];
  }

  switch (userStatus) {

    case API.agentStatusTypes.idle: {

      if (panelStore.hasOwnProperty(API.panel.types.break)) {

        delete panelStore[API.panel.types.break];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingLogout)) {

        delete panelStore[API.panel.types.pendingLogout];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingBreak)) {

        delete panelStore[API.panel.types.pendingBreak];
      }

      if (panelStore.hasOwnProperty(API.panel.types.idle)) {
        //Already in store
      }
      else {

        const idlePanel = {
          [API.panel.id]: API.panel.types.incoming,
          [API.panel.type]: API.panel.types.incoming,
        };
        panelStore[API.panel.types.incoming] = idlePanel;
      }

      break;
    }

    case API.agentStatusTypes.break: {

      if (panelStore.hasOwnProperty(API.panel.types.incoming)) {

      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingLogout)) {

        delete panelStore[API.panel.types.pendingLogout];
      }

      if (panelStore.hasOwnProperty(API.panel.types.break)) {
        //Already in store
      }
      else {

        const breakPanel = {
          [API.panel.id]: API.panel.types.break,
          [API.panel.type]: API.panel.types.break,
        };
        panelStore[breakPanel.id] = breakPanel;
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingBreak)) {

        delete panelStore[API.panel.types.pendingBreak];
      }

      break;
    }

    case API.agentStatusTypes.busy: {

      if (panelStore.hasOwnProperty(API.panel.types.incoming)) {

        delete panelStore[API.panel.types.incoming];
      }

      if (panelStore.hasOwnProperty(API.panel.types.break)) {

        delete panelStore[API.panel.types.break];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingBreak)) {

        delete panelStore[API.panel.types.pendingBreak];
      }

      break;
    }

    case API.agentStatusTypes.pendingBreak: {

      if (panelStore.hasOwnProperty(API.panel.types.break)) {

        delete panelStore[API.panel.types.break];
      }

      if (panelStore.hasOwnProperty(API.panel.types.idle)) {

        delete panelStore[API.panel.types.idle];
      }

      if (panelStore.hasOwnProperty(API.panel.types.startShift)) {

        delete panelStore[API.panel.types.startShift];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingLogout)) {

        delete panelStore[API.panel.types.pendingLogout];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingBreak)) {
        //in store already
      }
      else {

        const pendingBreakPanel = {
          [API.panel.id]: API.panel.types.pendingBreak,
          [API.panel.type]: API.panel.types.pendingBreak,
        };
        panelStore[pendingBreakPanel.id] = pendingBreakPanel;
      }
      break;
    }

    case API.agentStatusTypes.pendingLogout: {

      //Todo: add PendingLogout panel and remove other ones
      if (panelStore.hasOwnProperty(API.panel.types.break)) {

        delete panelStore[API.panel.types.break];
      }

      if (panelStore.hasOwnProperty(API.panel.types.idle)) {

        delete panelStore[API.panel.types.idle];
      }

      if (panelStore.hasOwnProperty(API.panel.types.startShift)) {

        delete panelStore[API.panel.types.startShift];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingBreak)) {

        delete panelStore[API.panel.types.pendingBreak];
      }

      if (panelStore.hasOwnProperty(API.panel.types.pendingLogout)) {
        //Already in store
      }
      else {

        const pendingLogoutPanel = {
          [API.panel.id]: API.panel.types.pendingLogout,
          [API.panel.type]: API.panel.types.pendingLogout,
        };
        panelStore[API.panel.types.pendingLogout] = pendingLogoutPanel;
      }

      break;
    }

    default:
      break;
  }
  return newState;
}

/**
 * Removes the panelSet from the store
 * If the current panelSet was the one that got deleted, then a new one is written in its place
 * Same thing goes for TaskMananager's selected panelSet
 * 
 * !!!Mutates the state!!!
 * !!!Mutates the panelSetStore!!!
 * 
 *
 * @param {*} newState 
 * @param {*} panelSetId 
 */
const removePanelSet = (newState, panelSetId) => {

  const panelSetStore = newState[API.panelSet._key];

  if (panelSetStore.hasOwnProperty(panelSetId)) {

    //panelSetStore will be updated, newState's reference to it has to be updated as well
    newState[API.panelSet._key] = panelSetStore;
    delete panelSetStore[panelSetId];

    const currentPanelSetId = newState[API.user._key][API.user.currentPanelSet];

    if (currentPanelSetId === panelSetId) {

      var foundANewId = false;
      const newUser = { ...newState[API.user._key] };
      newState[API.user._key] = newUser;

      for (var randomPanelSetId in panelSetStore) {

        if (panelSetStore.hasOwnProperty(randomPanelSetId)) {

          foundANewId = true;
          newUser[API.user.currentPanelSet] = randomPanelSetId;

          // If the taskManager was open with this panel selected, we need to change this as well.
          const taskManagerPanelSetId = newState[API.user._key][API.user.taskManagerPanelSet];

          if (taskManagerPanelSetId === panelSetId) {

            newUser[API.user.taskManagerPanelSet] = randomPanelSetId;
            newUser[API.user.isTaskManagerOpen] = newUser[API.user.isTaskManagerOpen] && panelSetStore[randomPanelSetId].hasOwnProperty(API.panelSet.workItemId);
          }
        }
      }

      if (foundANewId !== true) {

        delete newUser[API.user.currentPanelSet];
      }
    }
  }

  return newState;
}

/**
 * Maps agent state with the state-relevant panelSets (creates/removes them)
 * Checks what status the agent is in, then removes every state-panelsets that isn't the correct one, then creates the correct one
 * @param {*} state 
 */
const mapAgentStateWithStatePanelSets = (state) => {

  var newState = removePanelSet(state, API.panelSet.ids.dashboard);
  const panelSetStore = { ...newState[API.panelSet._key] };
  newState[API.panelSet._key] = panelSetStore;
  const userStatus = getUserStatus(newState);

  newState[API.uiState._key] = { ...newState[API.uiState._key] }
  const colorStore = newState[API.uiState._key][API.uiState.unusedPanelSetColors].slice()
  newState[API.uiState._key][API.uiState.unusedPanelSetColors] = colorStore;

  switch (userStatus) {

    case API.agentStatusTypes.idle: {

      newState = removePanelSet(newState, API.panelSet.ids.breakPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingLogoutPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingBreakPanelSet);
      const panelSetStore = { ...newState[API.panelSet._key] };
      newState[API.panelSet._key] = panelSetStore;

      if (panelSetStore[API.panelSet.ids.idlePanelSet]) {
        //Already in store
      }
      else {

        const idlePanelSet = {
          [API.panelSet.id]: API.panelSet.ids.idlePanelSet,
          [API.panelSet.status]: API.panelSet.statuses.incoming,
          [API.panelSet.layout]: {},
          [API.panelSet.panels]: [
            {
              [API.panelGroup.id]: API.panel.types.incoming,
              [API.panelGroup.panels]: [
                API.panel.types.incoming
              ]
            }
          ]
        };
        panelSetStore[idlePanelSet.id] = idlePanelSet;
      }

      break;
    }

    case API.agentStatusTypes.break: {

      newState = removePanelSet(newState, API.panelSet.ids.idlePanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingLogoutPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingBreakPanelSet);
      const panelSetStore = { ...newState[API.panelSet._key] };
      newState[API.panelSet._key] = panelSetStore;

      if (panelSetStore.hasOwnProperty(API.panelSet.ids.breakPanelSet)) {

      }
      else {

        const breakPanelSet = {
          [API.panelSet.id]: API.panelSet.ids.breakPanelSet,
          [API.panelSet.status]: API.panelSet.statuses.request,
          [API.panelSet.layout]: {}
        };

        breakPanelSet[API.panelSet.panels] = [
          {
            [API.panelGroup.id]: API.panel.types.break,
            [API.panelGroup.panels]: [
              API.panel.types.break
            ]
          }
        ]

        panelSetStore[breakPanelSet.id] = breakPanelSet;
      }

      break;
    }

    case API.agentStatusTypes.busy: {

      newState = removePanelSet(newState, API.panelSet.ids.idlePanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.breakPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingLogoutPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingBreakPanelSet);
      break;
    }

    case API.agentStatusTypes.pendingBreak: {

      newState = removePanelSet(newState, API.panelSet.ids.idlePanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.breakPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingLogoutPanelSet);
      const panelSetStore = { ...newState[API.panelSet._key] };
      newState[API.panelSet._key] = panelSetStore;

      if (panelSetStore[API.panelSet.ids.pendingBreakPanelSet]) {
        //Already in store
      }
      else {

        const pendingBreakPanelSet = {
          [API.panelSet.id]: API.panelSet.ids.pendingBreakPanelSet,
          [API.panelSet.status]: API.panelSet.statuses.request,
          [API.panelSet.layout]: {},
          [API.panelSet.color]: reservePanelSetColor(newState),
          [API.panelSet.panels]: [
            {
              [API.panelGroup.id]: API.panel.types.pendingBreak,
              [API.panelGroup.panels]: [
                API.panel.types.pendingBreak
              ]
            }
          ]
        };
        panelSetStore[pendingBreakPanelSet.id] = pendingBreakPanelSet;
      }
      break;
    }

    case API.agentStatusTypes.pendingLogout: {

      //Todo: add PendingLogout panelSet and remove other ones
      newState = removePanelSet(newState, API.panelSet.ids.dashboard);
      newState = removePanelSet(newState, API.panelSet.ids.idlePanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.breakPanelSet);
      newState = removePanelSet(newState, API.panelSet.ids.pendingBreakPanelSet);
      const panelSetStore = { ...newState[API.panelSet._key] };
      newState[API.panelSet._key] = panelSetStore;

      if (panelSetStore[API.panelSet.ids.pendingLogoutPanelSet]) {
        //Already in store
      }
      else {

        const pendingLogoutPanelSet = {
          [API.panelSet.id]: API.panelSet.ids.pendingLogoutPanelSet,
          [API.panelSet.status]: API.panelSet.statuses.request,
          [API.panelSet.layout]: {},
          [API.panelSet.color]: reservePanelSetColor(newState),
          [API.panelSet.panels]: [
            {
              [API.panelGroup.id]: API.panel.types.pendingLogout,
              [API.panelGroup.panels]: [
                API.panel.types.pendingLogout
              ]
            }
          ]
        };
        panelSetStore[pendingLogoutPanelSet.id] = pendingLogoutPanelSet;
      }

      break;
    }

    default:
      break;
  }
  return newState;
}

/**
 * Removes those panelsets from stages that are not in the panelSet part of the store (because they were deleted)
 * @param {*} state 
 */
const removeDeletedPanelSetsFromStages = (state) => {

  var newState = { ...state };
  const stageIds = getStageIds(newState);
  newState[API.user._key] = { ...newState[API.user._key] };
  var stageStore = newState[API.user._key][API.user.stages].slice();
  newState[API.user._key][API.user.stages] = stageStore;
  const panelSetStore = newState[API.panelSet._key];

  stageIds.forEach(stageId => {

    const index = getStageIndex(newState, stageId);
    const stage = { ...stageStore[index] }
    stageStore[index] = stage;
    var oldStagePanelSets = stage[API.stage.panelSets];
    var newStagePanelSets = [];
    oldStagePanelSets.forEach(panelSetId => {

      if (panelSetStore[panelSetId]) {

        newStagePanelSets.push(panelSetId);
      }
    });
    stage[API.stage.panelSets] = newStagePanelSets;

  });

  return newState;
}

/**
 * goes through each stage, if their panelSet property is an empty list, them removes that stage
 * WARNING: this returns a new shallow-copy of the state! make sure to refresh your references of the state properties!
 * @param {*} state 
 */
const removeEmptyStages = (state) => {

  var newState = { ...state };
  newState[API.user._key] = { ...newState[API.user._key] };
  var stageStore = newState[API.user._key][API.user.stages].slice();
  newState[API.user._key][API.user.stages] = stageStore;

  // First grab the indexes of those stages which have 0 panels attached to them, in reverse
  const emptyStageIndexes = [];

  for (var i = 0; i < stageStore.length; i++) {

    const stage = stageStore[i];

    if (stage[API.stage.panelSets].length < 1) {

      const index = getStageIndex(newState, stage[API.stage.id]);
      emptyStageIndexes.unshift(index);
    }
  }

  // now remove them one by one
  emptyStageIndexes.forEach(index => {

    removeStage(newState, index);
  })

  return newState;
}

/**
 * Removes a stage specified by its INDEX (not ID!)
 * WARNING: MUTATES THE STATE!
 * make sure to shallow copy the state, state.user, state.user.stages before calling this method!
 * @param {*} state 
 * @param {*} stageIndex 
 */
const removeStage = (state, stageIndex) => {

  const stageId = state[API.user._key][API.user.stages][stageIndex][API.stage.id];
  const currentStage = state[API.user._key][API.user.currentStage];
  const stageStore = state[API.user._key][API.user.stages];
  stageStore.splice(stageIndex, 1);


  if (stageId === currentStage) {
    // if the currently rendered stage is the one getting deleted	
    if (stageStore.length > 0) {
      //if there is any other stage, switch to render that one
      state[API.user._key][API.user.currentStage] = state[API.user._key][API.user.stages][0][API.stage.id];
    }
    else {
      //mark that we can't render anything currently.
      //when a new stage will be created, we need set this property to that new stage's id
      state[API.user._key][API.user.currentStage] = null;
    }
  }


}

/**
 * Creates a stage for each panelSets which don't have an attached stage
 * @param {*} state 
 */
const createStageForPanelSets = (state) => {

  const newState = { ...state };
  const panelSetIds = getPanelSetIdsWithoutStages(newState);
  newState[ReduxModels.nodes.user] = { ...newState[ReduxModels.nodes.user] };
  const stageStore = newState[ReduxModels.nodes.user][API.user.stages].slice();
  newState[ReduxModels.nodes.user][API.user.stages] = stageStore;

  panelSetIds.forEach(panelSetId => {
    const stageId = ("stage_" + guid('') + "_" + panelSetId)
    const newStage = {
      [API.stage.id]: stageId,
      [API.stage.layout]: {},
      [API.stage.panelSets]: [panelSetId]
    }
    stageStore.push(newStage);
    //if (newState[ReduxModels.nodes.user][API.user.currentStage] !== API.stage.ids.supervisor){
    newState[ReduxModels.nodes.user][API.user.currentStage] = stageId;
    newState[ReduxModels.nodes.user][API.user.currentPanelSet] = panelSetId;
    //}
  });

  //if currently no stage is set to be rendered, set the newly created one
  if (newState[ReduxModels.nodes.user][API.user.currentStage] == null && stageStore.length > 0) {
    newState[ReduxModels.nodes.user][API.user.currentStage] = stageStore[0][API.stage.id];
    newState[ReduxModels.nodes.user][API.user.currentPanelSet] = stageStore[0][API.stage.panelSets][0];
  }
  return newState;
}

/**
 * Reserves a color from the colorpalette
 * WARNING: MUTATES THE STATE!
 * make sure to shallow copy the state, state.uistate, state.uistate.unusedPanelSetColors before calling this method!
 * @param {*} newState 
 */
const reservePanelSetColor = (newState) => {

  const colorStore = newState[API.uiState._key][API.uiState.unusedPanelSetColors];
  const color = colorStore[0];
  colorStore.shift();

  if (colorStore.length < 1) {

    //Note:
    //panelColor selection lowered due to Bug 16705: BP AgentFrontEnd - Chat notification bubbles not shown properly
    //in Task 21865: Quick fix - lower possible panel colors from 8 to 6
    colorStore.push.apply(colorStore, [1, 2, 3, 4, 5, 6])
  }
  return color;
}

export default backendSyncReducer;
