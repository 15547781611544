
export const PollSpeed = {
	Fast: 1000,
	Normal: 2000,
    Slow: 4000,
    SuperSlow: 10000
};

let instance = null;

export default class Config {

    constructor() {

        if (!instance) {

            instance = this;
        }

        //This should be moved to the redux store
        this._env = null;
        this._useSecureCookies = true;
        this._consoleLogEnabled = false;      
        this._backEndPollSpeed = PollSpeed.Fast;
        this._tokenCheckPollInterval = 1000*60*1 // miliseconds * seconds * minutes
        this._tokenExpirationDateLimit = 1000*60*2 // miliseconds * seconds * minutes
        return instance;
    }

    get useSecureCookies() {

        return this._useSecureCookies;
    }

    set useSecureCookies(value) {

        this._useSecureCookies = value;
    }


    get consoleLogEnabled() {

        return this._consoleLogEnabled;
    }

    set consoleLogEnabled(value) {

        this._consoleLogEnabled = value;
    }

    get environment(){

        return this._env;
    }

    set environment(value){

        this._env = value
    }

    get backEndPollSpeed(){

        return this._backEndPollSpeed;
    }

    set backEndPollSpeed(value){

        this._backEndPollSpeed = value;
    }

    /**
     * defines how often should we check whether the authentication token has expired 
     */
    get tokenCheckPollInterval(){
        return this._tokenCheckPollInterval;
    }

    /**
     * defines the miminum time difference between the token's expiration date and the current time.
     * If the value is set to 10 minutes and the token will expire in 9 minutes from now, then the token will be refreshed
     * should be a higher value than the tokenCheckPollInterval
     */
    get tokenExpirationDateLimit(){
        return this._tokenExpirationDateLimit;
    }
}
