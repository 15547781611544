import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';

import Icon, {ICONS} from '../Icon/Icon';

import messages from './UserProfile.messages';

import './UserProfile.css';
import Timer from '../Timer/Timer';

class UserProfile extends PureComponent {

    render() {

		const {intl, name, profileImage, statusText, statusColor, extension, agentId, voiceEnabled, statusStartTime} = this.props;
		const {formatMessage} = intl;
		
		let statusColorClass = null;

		switch(statusColor) {

			case 'red':
				statusColorClass = 'user-profile__status__red';
				break;
			
			case 'green':
				statusColorClass = 'user-profile__status__green';
				break;
			
			case 'yellow':
				statusColorClass = 'user-profile__status__yellow';
				break;

			case 'gray':
			default:
				statusColorClass = 'user-profile__status__gray';
				break;
		}

		const extensionMessage = formatMessage(messages['UserProfile_extension_abbreviated']);
		const agentIdMessage = formatMessage(messages['UserProfile_agentId_abbreviated']);

		return (
			<div className="header__group user-profile">

				<div className="user-profile__details" style={{textAlign:'right'}}>
					<div id={"agentNameId"} className="user-profile__name">{name} </div>
					<div id={"agentStatusId"} className="user-profile__mode" >{statusText}</div>
					<Timer key={statusStartTime} size={"top"} autoStart={true} startTime={statusStartTime} />
					{(voiceEnabled === true) &&
						<div id={"agentExtensionId"} className="user-profile__settings">{extensionMessage}:&nbsp;{extension}, {agentIdMessage}:&nbsp;{agentId}</div>
					}
				</div>

				<div className={"user-profile__image-container " + statusColorClass} >
					<div className="user-profile__image">
						{(profileImage && profileImage.length) > 0 ? (
							<img src={profileImage} alt={name}/>
						) : (
							<Icon name={ICONS.user} title={name}/>
						)}
					</div>
				</div>
			</div>
		);
    }

    static propTypes = {
			intl: intlShape.isRequired,
			name: PropTypes.string.isRequired,
			profileImage: PropTypes.string.isRequired,
			statusText: PropTypes.string.isRequired,
			statusColor: PropTypes.string.isRequired,
			extension: PropTypes.string,
			agentId: PropTypes.string,
			voiceEnabled: PropTypes.bool,
			statusStartTime: PropTypes.number
    };

    static defaultProps = {
		name: 'Agent',
		profileImage: '',
		statusText: '',
		statusColor: 'gray',
		extension: '',
		agentId: '',
		voiceEnabled: false,
		statusStartTime: 0
	};
}

export default injectIntl(UserProfile);
