
export const GRID_MARGIN = 10;
export const CELLSIZE = 1;

export const LAYOUT_IDS = {
	fullsize: 'fullsize',

	twoColumns: 'twoColumns',
	twoRows: 'twoRows',

	rightColumnSplit: 'rightColumnSplit',
	leftColumnSplit: 'leftColumnSplit',
	topRowSplit: 'topRowSplit',
	bottomRowSplit: 'bottomRowSplit',

	crossSplit: 'crossSplit'
};

export const STAGE_POSITON = {
	side: 'side',
	bottom: 'bottom'
};

export const LAYOUT_BY_ITEMS = [
	[LAYOUT_IDS.fullsize],
	[LAYOUT_IDS.twoColumns, LAYOUT_IDS.twoRows],
	[LAYOUT_IDS.rightColumnSplit, LAYOUT_IDS.leftColumnSplit, LAYOUT_IDS.topRowSplit, LAYOUT_IDS.bottomRowSplit],
	[LAYOUT_IDS.crossSplit]
];

export const EXTENDS_TO_BOTTOM = [
	LAYOUT_IDS.fullsize, LAYOUT_IDS.twoColumns, LAYOUT_IDS.rightColumnSplit, LAYOUT_IDS.leftColumnSplit
];

export const EXTENDS_TO_SIDE = [
	LAYOUT_IDS.fullsize, LAYOUT_IDS.twoRows, LAYOUT_IDS.topRowSplit, LAYOUT_IDS.bottomRowSplit
];

export const PANEL_EXTENDS_TO_SIDE = {
	[LAYOUT_IDS.fullsize]: [0],
	[LAYOUT_IDS.twoRows]: [0, 1],
	[LAYOUT_IDS.topRowSplit]: [2],
	[LAYOUT_IDS.bottomRowSplit]: [0]
};

export const PANEL_EXTENDS_TO_BOTTOM = {
	[LAYOUT_IDS.fullsize]: [0],
	[LAYOUT_IDS.twoColumns]: [0, 1],
	[LAYOUT_IDS.leftColumnSplit]: [2],
	[LAYOUT_IDS.rightColumnSplit]: [0]
};

export const LAYOUT_TRANSITIONS = {
	[LAYOUT_IDS.fullsize]: [
		{
			[STAGE_POSITON.side]: LAYOUT_IDS.twoColumns,
			[STAGE_POSITON.bottom]: LAYOUT_IDS.twoRows
		}
	],

	[LAYOUT_IDS.twoColumns]: [
		{
			[STAGE_POSITON.bottom]: LAYOUT_IDS.leftColumnSplit
		},
		{
			[STAGE_POSITON.bottom]: LAYOUT_IDS.rightColumnSplit
		}
	],

	[LAYOUT_IDS.twoRows]: [
		{
			[STAGE_POSITON.side]: LAYOUT_IDS.topRowSplit
		},
		{
			[STAGE_POSITON.side]: LAYOUT_IDS.bottomRowSplit
		}
	],

	[LAYOUT_IDS.leftColumnSplit]: [
		{},
		{},
		{
			[STAGE_POSITON.bottom]: LAYOUT_IDS.crossSplit
		}
	],

	[LAYOUT_IDS.rightColumnSplit]: [
		{
			[STAGE_POSITON.bottom]: LAYOUT_IDS.crossSplit
		}
	],

	[LAYOUT_IDS.topRowSplit]: [
		{},
		{},
		{
			[STAGE_POSITON.side]: LAYOUT_IDS.crossSplit
		}
	],

	[LAYOUT_IDS.bottomRowSplit]: [
		{
			[STAGE_POSITON.side]: LAYOUT_IDS.crossSplit
		}
	]
};

export const LAYOUT_TRANSITIONS_REVERSE = {
	[LAYOUT_IDS.crossSplit]: [
		LAYOUT_IDS.rightColumnSplit,
		LAYOUT_IDS.leftColumnSplit,
		LAYOUT_IDS.rightColumnSplit,
		LAYOUT_IDS.leftColumnSplit
	],

	[LAYOUT_IDS.leftColumnSplit]: [
		LAYOUT_IDS.twoColumns,
		LAYOUT_IDS.twoColumns,
		LAYOUT_IDS.twoRows
	],

	[LAYOUT_IDS.rightColumnSplit]: [
		LAYOUT_IDS.twoRows,
		LAYOUT_IDS.twoColumns,
		LAYOUT_IDS.twoColumns
	],

	[LAYOUT_IDS.topRowSplit]: [
		LAYOUT_IDS.twoRows,
		LAYOUT_IDS.twoRows
	],

	[LAYOUT_IDS.bottomRowSplit]: [
		LAYOUT_IDS.twoRows,
		LAYOUT_IDS.twoRows
	],

	[LAYOUT_IDS.twoRows]: [
		LAYOUT_IDS.fullsize,
		LAYOUT_IDS.fullsize
	],

	[LAYOUT_IDS.twoColumns]: [
		LAYOUT_IDS.fullsize,
		LAYOUT_IDS.fullsize
	],

	[LAYOUT_IDS.fullsize]: [
		LAYOUT_IDS.fullsize
	]
};

export const STAGE_TRANSITIONS = {
	[LAYOUT_IDS.fullsize]: {
		[STAGE_POSITON.side]: LAYOUT_IDS.twoColumns,
		[STAGE_POSITON.bottom]: LAYOUT_IDS.twoRows
	},

	[LAYOUT_IDS.twoColumns]: {
		[STAGE_POSITON.bottom]: LAYOUT_IDS.topRowSplit
	},

	[LAYOUT_IDS.twoRows]: {
		[STAGE_POSITON.side]: LAYOUT_IDS.leftColumnSplit
	},

	[LAYOUT_IDS.leftColumnSplit]: {
		[STAGE_POSITON.bottom]: LAYOUT_IDS.crossSplit
	},

	[LAYOUT_IDS.rightColumnSplit]: {
		[STAGE_POSITON.bottom]: LAYOUT_IDS.crossSplit
	},

	[LAYOUT_IDS.topRowSplit]: {
		[STAGE_POSITON.side]: LAYOUT_IDS.crossSplit
	},

	[LAYOUT_IDS.bottomRowSplit]: {
		[STAGE_POSITON.side]: LAYOUT_IDS.crossSplit
	}
};





export const GRID_LAYOUTS = {

	/* Single case
	 ┏━━━━━━━━┓
	 ┃        ┃
	 ┃        ┃
	 ┃        ┃
	 ┃        ┃
	 ┗━━━━━━━━┛
	 */

	[LAYOUT_IDS.fullsize]: {
		id: LAYOUT_IDS.fullsize,
		grid: [{w: 1, h: 1}]
	},


	/* Two cases
	 ┏━━━┓┏━━━┓  ┏━━━━━━━━┓
	 ┃   ┃┃   ┃  ┃        ┃
	 ┃   ┃┃   ┃  ┗━━━━━━━━┛
	 ┃   ┃┃   ┃  ┏━━━━━━━━┓
	 ┃   ┃┃   ┃  ┃        ┃
	 ┗━━━┛┗━━━┛  ┗━━━━━━━━┛
	 */

	[LAYOUT_IDS.twoColumns]: {
		id: LAYOUT_IDS.twoColumns,
		grid: [
			{w: 0.5, h: 1},
			{w: 0.5, h: 1}
		]
	},


	[LAYOUT_IDS.twoRows]: {
		id: LAYOUT_IDS.twoRows,
		grid: [
			{w: 1, h: 0.5},
			{w: 1, h: 0.5}
		]
	},


	/* Three cases
	 ┏━━━┓┏━━━┓  ┏━━━┓┏━━━┓  ┏━━━┓┏━━━┓  ┏━━━━━━━━┓
	 ┃   ┃┃   ┃  ┃   ┃┃   ┃  ┃   ┃┃   ┃  ┃        ┃
	 ┃   ┃┗━━━┛  ┗━━━┛┃   ┃  ┗━━━┛┗━━━┛  ┗━━━━━━━━┛
	 ┃   ┃┏━━━┓  ┏━━━┓┃   ┃  ┏━━━━━━━━┓  ┏━━━┓┏━━━┓
	 ┃   ┃┃   ┃  ┃   ┃┃   ┃  ┃        ┃  ┃   ┃┃   ┃
	 ┗━━━┛┗━━━┛  ┗━━━┛┗━━━┛  ┗━━━━━━━━┛  ┗━━━┛┗━━━┛
	 */

	[LAYOUT_IDS.rightColumnSplit]: {
		id: LAYOUT_IDS.rightColumnSplit,
		grid: [
			{w: 0.5, h: 1},
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5}
		]
	},

	[LAYOUT_IDS.leftColumnSplit]: {
		id: LAYOUT_IDS.leftColumnSplit,
		grid: [
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 1}
		]
	},

	[LAYOUT_IDS.topRowSplit]: {
		id: LAYOUT_IDS.topRowSplit,
		grid: [
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5},
			{w: 1, h: 0.5}
		]
	},

	[LAYOUT_IDS.bottomRowSplit]: {
		id: LAYOUT_IDS.bottomRowSplit,
		grid: [
			{w: 1, h: 0.5},
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5}
		]
	},


	/* Four cases
	 ┏━━━┓┏━━━┓
	 ┃   ┃┃   ┃
	 ┗━━━┛┗━━━┛
	 ┏━━━┓┏━━━┓
	 ┃   ┃┃   ┃
	 ┗━━━┛┗━━━┛
	 */

	[LAYOUT_IDS.crossSplit]: {
		id: LAYOUT_IDS.crossSplit,
		grid: [
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5},
			{w: 0.5, h: 0.5}
		]
	}
};
