import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import API from '../../api/Api';

import './StartShiftLayout.css';

/**
 * Used by the StartShift panel, this containes the actual html stuff
 */
class StartShiftLayout extends PureComponent {
    render() {
        const {waves, statusType, title, instructions, timer, autoModeButton, requestBreakButtons, requestBreakButtonsTitle, logoutButton}  = this.props;

        const classes = classNames({
			'startshift': true,
			'startshift--requestbreak': statusType === API.agentStatusTypes.pendingBreak || statusType === API.agentStatusTypes.break,
			'startshift--requestlogout': statusType === API.agentStatusTypes.pendingLogout
		});

		return (
			<section className={classes}>
				{waves}
				<div className="grid-content scrollbar">

					<div id={"breakTypeId"} className="startshift__title">{title}</div>
					<div className="startshift__timer">
						<div className="startshift__instructions">
							<div className="startshift__instructions-text">{instructions}</div>
							{timer && timer}
						</div>
					</div>

					<div className="startshift__actions table">
						<div className="row">
							<div className="cell">
								{autoModeButton}
							</div>
						</div>

						{
							requestBreakButtons && requestBreakButtons.length > 0 &&
								<div className="startshift__break row">
									<div className="cell">{requestBreakButtonsTitle}</div>
								</div>
						}

						{
							requestBreakButtons && requestBreakButtons.length > 0 &&
								_.map(_.chunk(requestBreakButtons, 2), (buttonsInRow, rowIndex) => {
									return (
										<div key={'row_' + rowIndex} className="row">
											{
												_.map(buttonsInRow, (button, index) => {
													return (
														<div key={'button_' + index} className="cell">
															{button}
														</div>
													);
												})
											}
										</div>
									);
								})
						}
					</div>

					<div className="startshift__actions--secondary">
						{logoutButton}
					</div>
				</div>
			</section>
		);
	}

    static propTypes = {
		waves: PropTypes.node,
		title: PropTypes.string.isRequired,
		instructions: PropTypes.string,
		timer: PropTypes.node,
		autoModeButton: PropTypes.node,
		requestBreakButtons: PropTypes.node,
		logoutButton: PropTypes.node,
		requestBreakButtonsTitle: PropTypes.string,
		statusType: PropTypes.string
    };
}

export default StartShiftLayout;
