import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActionButton, {BUTTON_STYLE} from '../ActionButton/ActionButton';
import ICONS from '../../data/icons';
import './PanelTab.css';
class PanelTab extends PureComponent {

	constructor(props) {
		super(props);
		this.tabRef = null;
		this.elementWidth = 0;
	}

	componentDidMount() {
		if (this.props.onMount) {
			this.elementWidth = this.tabRef.clientWidth;
			this.props.onMount(this);
		}
	}

	offset() {
		return this.tabRef.offsetTop;
	}

	width() {
		return this.elementWidth;
	}

	id() {
		return this.props.interactionId;
	}

	render() {

		const {singleTab, interactionId, label, active, onActivate, onClose, debugModeEnabled } = this.props;
		const labelNoSpace = label.replace(/\s+/g, '')
		const uniquePanelId = `${labelNoSpace}_${interactionId}`

		const classes = classNames({
			'interaction-tab': true,
			'interaction-tab--active': active,
			'interaction-tab--single': singleTab
		});

		return (
			<div key={'tabcontent_' + interactionId} className={classes} ref={(el) => {this.tabRef = el;}} >
				<ActionButton key={'tablabel_' + interactionId} clickHandler={onActivate} text={label} buttonStyle={[BUTTON_STYLE.transparent]} />				
				{
					debugModeEnabled && 
					<p id={uniquePanelId} style={{display: "inline"}}> {"panelId: " + interactionId} </p>
				}	
				{
					onClose &&
					<ActionButton id={`closeButton_${uniquePanelId}`} key={'tabclose_' + interactionId} clickHandler={onClose} icon={ICONS.close} buttonStyle={[BUTTON_STYLE.transparent]} />
				}	
			</div>
		);

	}

	static propTypes = {
		singleTab: PropTypes.bool,
		interactionId: PropTypes.string,
		label: PropTypes.string,
		active: PropTypes.bool,
		onActivate: PropTypes.func,
		onClose: PropTypes.func,
		onMount: PropTypes.func,
		debugModeEnabled : PropTypes.bool
	};
}

export default PanelTab;