import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './GridItem.css';

class GridItem extends PureComponent {
    render() {
        const {children, coords, panelSetLayout, caseContainer} = this.props;

		const classes = classNames({
			'grid-item': true,
			'grid-item--case': caseContainer,
			'grid-item--interaction': !caseContainer,
			'grid-item--gutter': panelSetLayout
		});

		return (
			<div className={classes} style={{left: `${coords.x * 100}%`, top: `${coords.y * 100}%`, width: `${coords.width * 100}%`, height: `${coords.height * 100}%`}}>
				{children}
			</div>
		);
    }

    static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]),
		coords: PropTypes.object.isRequired,
		panelSetLayout: PropTypes.bool.isRequired,
		caseContainer: PropTypes.bool.isRequired
    };

    static defaultProps = {
		children: null,
		coords: {x: 0, y: 0, width: 1, height: 1},
		panelSetLayout: true,
		caseContainer: false
	};
}

export default GridItem;
