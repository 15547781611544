import API from "../api/Api";
import ReduxModels from "../api/ReduxModels";
import { checkBackendLoggedIn, getWorkItemIds } from "./capability";
import { getInitialDataLoaded } from "./uiState";
import { getWorkItemsV2 } from "./conversations";

export const getScriptingReduxObject = (state, workItemId) => {
    return state[ReduxModels.nodes.scripting][workItemId]
}

export const getExternalUrlThatNeedsToBeRetrieved = (state) => {
    const result = [];
    if (getInitialDataLoaded(state) && checkBackendLoggedIn(state)){
        const workItemIds = getWorkItemIds(state);
        const workItems = getWorkItemsV2(state);
        workItemIds.forEach(workItemId => {
            const conversationId = workItems[workItemId][API.workItemV2.primaryConversationId];
            if (conversationId){
                if (workItemId in state[ReduxModels.nodes.scripting]){
                    const scriptingReduxObject = state[ReduxModels.nodes.scripting][workItemId];
                    if (scriptingReduxObject[ReduxModels.scripting.loading] === true){
                        result.push({
                            workItemId,
                            conversationId
                        }); 
                    }
                }
                else{
                    result.push({
                        workItemId,
                        conversationId
                    });
                }
            }

        
        });
    }
    return result;
}