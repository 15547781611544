import {defineMessages} from 'react-intl';

const messages = defineMessages({

	LoginContent_Greeting_morning: {
		defaultMessage: 'Good morning!',
		id: 'LoginContent_Greeting_morning',
		description: 'Login / greeting in the morning'
	},

	LoginContent_Greeting_afternoon: {
		defaultMessage: 'Good afternoon!',
		id: 'LoginContent_Greeting_afternoon',
		description: 'Login / greeting in the afternoon'
	},

	LoginContent_Greeting_evening: {
		defaultMessage: 'Good evening!',
		id: 'LoginContent_Greeting_evening',
		description: 'Login / greeting in the evening'
	},

	LoginContent_Instructions: {
		defaultMessage: 'Log in with:',
		id: 'LoginContent_Instructions',
		description: 'Login / Instructions text above login buttons'
	},

	LoginButtons_AzureActiveDirectory: {
		defaultMessage: 'Microsoft',
		id: 'LoginButtons_AzureActiveDirectory',
		description: 'Login / Microsoft Azure Active Directory account button'
	}
});

export default messages;