import { backEndAccessToken } from './authentication';

export const getAuthHeader = (state) => {
  return {
    'Authorization': 'Bearer ' + backEndAccessToken(state)
  };
}

export const getWorkflowUrl = () => process.env.REACT_APP_WORKFLOW_URL

export const getOdataUrl = () => process.env.REACT_APP_ODATA_URL

export const getBlenderServiceUrl = () => process.env.REACT_APP_BLENDER_SERVICE_URL

export const getAksApiUrl = () => process.env.REACT_APP_AKS_API_URL

export const getGumApiUrl = () => process.env.REACT_APP_GUM_API_URL

export const getDocumentStoreUrl = () => process.env.REACT_APP_DOCUMENT_STORE_URL

export const getFragmentUrl = () => process.env.REACT_APP_FRAGMENT_URL

export const getSIPConnectorUrl = () => process.env.REACT_APP_SIP_CONNECTOR_URL

export const getCampaignManagerUrl = () => process.env.REACT_APP_CAMPAIGN_MANAGER_URL

export const getGaraUrl = () => process.env.REACT_APP_GARA_URL

export const getPortalUrl = () => process.env.REACT_APP_PORTAL_URL