import {createReducer} from 'redux-act';
import _ from 'lodash';

import * as Actions from '../../actions';
import API from '../../api/Api';
import DEFAULT_STATE from '../../data/states';


export default createReducer({

	/**
	 * It stores the break names into the Redux state by creating deep copies of them.
	 * 
	 * NOTE: Break names are stored as a simple JavaScript 
	 * array into the Redux store under the proper key.
	 */
	[Actions.setBreakNames]: (state, payload) => {

		const {breakNames} = payload;
		// const newState = _.cloneDeep(breakNames);

		// return newState;
		return breakNames;
	}
	
}, DEFAULT_STATE[API.breakNames._key]);