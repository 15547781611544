import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import API from '../../api/Api';
import UserMessage from '../../components/UserMessage/UserMessage';
import { getMessages } from '../../selectors';
import './UserMessageBar.css';

/**
 * This component displays different type of messages to the user.
 */
class UserMessageBar extends PureComponent {

  render() {

    const { messages, maxMessagesToDisplay } = this.props;

    return (
      <div className="usermessagebar">
        {messages.slice(0, maxMessagesToDisplay).map(message => (
          <div key={message[API.message.id]}>
            <UserMessage
              message={message[API.message.text]}
              link={message[API.message.link]}
              timestamp={message[API.message.timestamp]}
              clickHandler={() => this.props.onDismissMessage(message[API.message.id])}
            />
          </div>
        ))}
        {messages.length > maxMessagesToDisplay &&
          <div key={"moreMessagesIndicator"}>
            <UserMessage message="..." />
          </div>
        }
      </div>
    );
  }

  static propTypes = {
    messages: PropTypes.array,
    maxMessagesToDisplay: PropTypes.number.isRequired
  };

  static defaultProps = {
    messages: [],
    maxMessagesToDisplay: 3
  };
}

const mapStateToProps = (state) => {

  const messages = getMessages(state);

  if (!_.isNil(messages) && messages.length > 0) {

    // sort the messages based on timestamp
    messages.sort((m1, m2) => {

      const m1Time = moment(m1[API.message.timestamp]);
      const m2Time = moment(m2[API.message.timestamp]);

      if (m1Time.isBefore(m2Time)) {
        return 1;
      }

      if (m1Time.isAfter(m2Time)) {
        return -1;
      }

      return 0;
    });
  }

  // TODO: read this from some config if it bothers anyone
  const maxMessagesToDisplay = 3;

  return {
    messages,
    maxMessagesToDisplay
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDismissMessage: bindActionCreators(Actions.removeMessage, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMessageBar);
