import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { loggers } from 'redux-act';

import sagas from '../sagas';
import reducers from '../reducers';

import Config from '../config/config';
import DEFAULT_STATE from '../data/states';
import API from '../api/Api'


function create(state, logger) {

	let conf = new Config();

	const sagaMiddleware = createSagaMiddleware();
	const middleware = [sagaMiddleware];

	let isDev = process.env.NODE_ENV === 'development';
	const env = "development";
	conf.environment = env;
	conf.consoleLogEnabled = (process.env.REACT_APP_LOGGING === "true");
	if (logger && conf.consoleLogEnabled) {

		const log = createLogger({
			actionTransformer: loggers.reduxLogger.actionTransformer,
			logger: loggers.reduxLogger.logger
		});

		middleware.push(log);
	}
	const composeEnhancers = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
	const store = createStore(reducers, state, composeEnhancers(applyMiddleware(...middleware)));
	sagaMiddleware.run(sagas);

	return store;
}

const store = create(DEFAULT_STATE, process.env.REACT_APP_LOGGING === "true");
export default store;