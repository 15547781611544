import API from "../api/Api";
import { getWorkItemsV2 } from "./conversations";
import ReduxModels from "../api/ReduxModels";
import { getConversations } from ".";

export const getNewWorkItemCount = (state) => {
    var result = 0;
    const lastCheckDate = state[API.notifications._key][API.notifications.lastFocusDate];
    const workItems = getWorkItemsV2(state);
    const workItemIds = Object.getOwnPropertyNames(workItems);

    workItemIds.forEach(workItemId => {
        const workItem = workItems[workItemId];

        if (workItem[API.workItemV2.workItemState] != API.workItemV2.workItemStates.preview) { return; }

        for (var conversationId in workItem[API.workItemV2.conversations]) {
            if (conversationId in workItem[API.workItemV2.conversations]) {
                const conversation = workItem[API.workItemV2.conversations][conversationId]

                if (conversation[API.conversation.channelType] === API.conversationChannelTypes.voice) {
                    continue;
                }
                if (workItem[API.workItemV2.lastStateChangeDate]) {
                    const lastStateChangeDate = new Date(workItem[API.workItemV2.lastStateChangeDate]).getTime();
                    if (lastStateChangeDate > lastCheckDate) {
                        result++;
                    }
                }
            }
        }
    });

    return result;
}

export const getTotalUnreadMessageCount = (state) => {
    var result = 0;
    for (var convId in state[ReduxModels.nodes.chat]) {
        if (convId in state[ReduxModels.nodes.chat]) {
            result += state[ReduxModels.nodes.chat][convId];
        }
    }
    return result;
}

export const getUnreadMessageCountForPanelSet = (state, panelSetId) => {
    const panelSet = state[ReduxModels.nodes.panelSet][panelSetId];
    if (panelSet) {
        const workItemId = panelSet[API.panelSet.workItemId];
        if (workItemId) {
            const workItem = getWorkItemsV2(state)[workItemId];
            if (workItem) {
                var result = 0;
                for (var conversationId in workItem[API.workItemV2.conversations]) {
                    if (conversationId in workItem[API.workItemV2.conversations]) {
                        if (conversationId in state[ReduxModels.nodes.chat]) {
                            result += (state[ReduxModels.nodes.chat][conversationId])
                        }
                    }
                }
                return result;
            }
        }
    }
    return 0;
}