import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import ICONS from '../../data/icons';

import './Icon.css';

class Icon extends PureComponent {

	render() {
		
		const {name, title} = this.props;
		const classes = classNames({
			'icon-wrapper': true,
			[`icon-${name}`]: name
		});
		const ariaProps = title ? {'aria-label': title, title: title} : {'aria-hidden': 'true'};

		return (
			<i className={classes} {...ariaProps}>
				<svg focusable="false" role="img" viewBox="0 0 48 48">
					{title && <title>{title}</title>}
					{/*<use xlinkHref={`${'http://' + window.location.hostname + ':' + window.location.port + SVG_ROOT}#icon-${name}`}/>*/}
					{/*<use xlinkHref={`${SVG_ROOT}#icon-${name}`}/>*/}
					<use xlinkHref={`#icon-${name}`}/>
				</svg>
			</i>
		);
	}

	static propTypes = {
		name: PropTypes.oneOf(_.values(ICONS)).isRequired,
		title: PropTypes.string
	};

	static defaultProps = {
		name: 'settings'
	};

}

export default Icon;
export {ICONS};