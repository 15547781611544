
export const BREAKPOINT_IDS = {
	widescreen: 'widescreen',
	desktop: 'desktop',
	tablet: 'tablet',
	mobile: 'mobile',
	mobileLegacy: 'mobileLegacy'
};

export const COMPONENT_BREAKPOINTS = {
	searchFilters: {
		narrow: 320
	},
	searchResults: {
		narrow: 640
	}
};

export default {
	[BREAKPOINT_IDS.mobileLegacy]: 320,
	[BREAKPOINT_IDS.mobile]: 600,
	[BREAKPOINT_IDS.tablet]: 900,
	[BREAKPOINT_IDS.desktop]: 1200,
	[BREAKPOINT_IDS.widescreen]: 1800
};