import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditCaseLayout from '../EditCaseLayout/EditCaseLayout';
import Label from '../../components/Label/Label';
import {
  checkBackendState,
  wasLogOutRequested,
  checkBackendLoggedIn,
  checkReceivedBackendStateAtLeastOnce,
  getOidcUser
} from '../../selectors';
import WebSiteNotification from '../WebSiteNotification/WebSiteNotification';
import userManager from "../../services/userManager";
import { useHistory } from "react-router-dom";
import './MainLayout.css';

/**
 * This component makes sure that the client is connected to the backend and is logged in
 * Then it renders the EditCaseLayout
 * If logout was requested and executed, then this redirects to the LoginPage
 */
const MainLayout = (props) => {
  const { connectedAtLeastOnce, loggedin, logoutRequested, agentStateKnown, oidcUser } = props;
  let history = useHistory();
  if (!oidcUser) {
    userManager.signinRedirect()
  }
  let tempLayout = null;
  if (!connectedAtLeastOnce) {
    //there was never a valid connection before
    tempLayout = (
      <div>
        <Label text={"Connecting to backend..."} />
      </div>
    );
  }
  else {
    //we are connected
    if (agentStateKnown) {
      //we know the agent's state
      if (loggedin) {
        //Agent is logged in with a known state
        tempLayout = (
          <div>
            <WebSiteNotification />
            <EditCaseLayout />
          </div>
        );
      }
      else {
         //agent is not logged in
         if (logoutRequested) {
        // logout was requested once, now the agent is logged out
        //time to redirect
        userManager.removeUser();
        history.push("/logout");
      }
        else {
          //agent did not request the logout yet, so wait for the login process to finish
          tempLayout = (
            <div>
              <Label text={"Waiting for login..."} />
            </div>
          );
        }
      }
    }
    else {
      //Agent state unknown, but there is a connection to the fabric
      tempLayout = (
        <div>
          <Label text={"Agent State Unknown"} />
        </div>
      );
    }
  }

  const layout = tempLayout;

  return (layout);
}

MainLayout.propTypes = {
  loggedin: PropTypes.bool.isRequired,
  logoutRequested: PropTypes.bool.isRequired,
};

MainLayout.defaultProps = {
  loggedin: false,
  logoutRequested: false,
};


const mapStateToProps = (state) => {

  const connectedAtLeastOnce = checkReceivedBackendStateAtLeastOnce(state);
  const loggedin = checkBackendLoggedIn(state);
  const logoutRequested = wasLogOutRequested(state);
  var agentStateKnown = false;
  if (connectedAtLeastOnce) {
    agentStateKnown = checkBackendState(state);
  }
  const oidcUser = getOidcUser(state);


  return {
    connectedAtLeastOnce,
    loggedin,
    logoutRequested,
    agentStateKnown,
    oidcUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
