import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Icon, {ICONS} from '../Icon/Icon';
import PanelIcon from '../PanelIcon/PanelIcon';

import './IdlePanel.css';

class IdlePanel extends PureComponent {
	render() {
		const {timer, interactionType, title, profileImage, customerName, infoBlocks, children} = this.props;

		return (
			<div className="incoming-panel">
				<div className="incoming-panel__left">
					{
						(profileImage || customerName) &&
							<div className="profile-image">
								{
									profileImage && profileImage.length > 0
										? <img src={profileImage} alt={customerName}/>
										: <Icon name={ICONS.user} title={customerName}/>
								}
							</div>
					}

					<PanelIcon interactionType={interactionType} label="" />

					{timer}
				</div>

				<div className="incoming-panel__right">
					{
						title &&
							<div id={"idlePanelId"} className="incoming-panel__title">{title}</div>
					}

					{
						_.map(infoBlocks, (info) => {
							return (
								<div className="block" key={'info_block_' + info.label}>
									<div className="incoming-panel__label">{info.label}</div>
									<div className="incoming-panel__info incoming-panel__info--name">{info.value}</div>
								</div>
							);
						})
					}

					{children}

				</div>

			</div>
		);
	}

	static propTypes = {
		timer: PropTypes.node,
		interactionType: PropTypes.string,
		title: PropTypes.string,
		profileImage: PropTypes.string,
		customerName: PropTypes.string,
		infoBlocks: PropTypes.array,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		])
	};
}

export default IdlePanel;
