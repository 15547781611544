import {call, takeLatest, select} from 'redux-saga/effects';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { httpPost } from '../services/http';

/**
 * request to cancel an outbound call lookup
 */
function* logout() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/Logout?api-version=3";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}
	
}

/**
 * request to cancel an outbound call lookup
 */
function* leaveBreak() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/Activate?api-version=3";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}	
}

function* goToBreak(action){
	yield goToBreakGeneral(action.payload.breakName);	
}

/**
 * request to cancel an outbound call lookup
 */
function* goToBreakGeneral(breakName) {
	
	var url =  (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/MoveToBreak?api-version=3";
	if (breakName){
		url = url + "&breakName="+ breakName;
	}
		
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}	
}

function* goToSVBreak() {
	yield goToBreakGeneral("Supervisor Mode");	
}

/**
 * request to cancel an outbound call lookup
 */
function* login() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/LogIn?api-version=3";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}	
}


export default function* () {
	
	yield takeLatest(Actions.logIn.getType(), login)
	yield takeLatest(Actions.goToBreak.getType(), goToBreak)
	yield takeLatest(Actions.moveToSupervisorBreak.getType(), goToSVBreak)
	yield takeLatest(Actions.logOut.getType(), logout)
	yield takeLatest(Actions.leaveBreak.getType(), leaveBreak)
}
