import { all } from 'redux-saga/effects';
import User from './User';
import Stages from './Stages';
import BackEndConnection from './BackEndConnection';
import CapabilityHandler from './CapabilityHandler';
import BreakNames from './BreakNames';
import WorkItemManagement from './WorkItemManagement';
import Scripting from './Scripting';
import ResourceManagement from './ResourceManagement';
import Login from './Login';

export default function* () {
	yield all([
		User(),
		Stages(),
		Login(),
		BackEndConnection(),
		CapabilityHandler(),
		BreakNames(),
		WorkItemManagement(),
		Scripting(),
		ResourceManagement()
	]);
}