import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../services/userManager";
import { useHistory } from "react-router-dom";
import * as Actions from '../../actions';
import { bindActionCreators } from 'redux';
import * as Selectors from '../../selectors';
import store from '../../services/createStore';
import { isSupervisor } from '../../services/accessToken';
import { AgentBusyWithVoice } from '../../data/mockedDebugData'
import { User } from "oidc-client";


const CallbackPage = (props) => {
  let history = useHistory();
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        if (isSupervisor(user.access_token) == null) {
          history.push("/")
        } else {
          store.dispatch(Actions.initializeAuthWorker(user.access_token));
          store.dispatch(Actions.loadInitialElements(isSupervisor(user.access_token)));

          if (process.env.REACT_APP_MOCKEDDATA === "true") {
            store.dispatch(Actions.stateDataRetrieved(AgentBusyWithVoice));
            console.log('todoo')
          }
          else {
            props.onBackEndPollInit(process.env.REACT_APP_STATEURL);
          };
          history.push("/client")
        }
      }
      }
      errorCallback={error => console.error(error)}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
}
const mapStateToProps = (state) => {
  const accessToken = Selectors.backEndAccessToken(state);
  return { accessToken };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onBackEndPollInit: bindActionCreators(Actions.backEndPollInit, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
