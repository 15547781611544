import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import API from '../../api/Api';

import PanelIcon from '../PanelIcon/PanelIcon';
import Icon, { ICONS } from '../Icon/Icon';

import './CaseListItem.css';
import Badge from '@material-ui/core/Badge'
class CaseListItem extends PureComponent {

	openTaskManager = (e) => {
		e.nativeEvent.stopImmediatePropagation();
		e.preventDefault();
		this.props.onOpenTaskManager(this.props.panelSetId);
	};

	render() {
		const { panelSetId, panelSetColor, active, isTaskManagerOpen, taskManagerPanelSet, panels, title, subtitle, timer, onActivate, onCloseTaskManager, taskManagerArrowEnabled, counter } = this.props;

		const classes = classNames({
			'caselist-item': true,
			'caselist-item--active': active,
			'caselist-item--taskmanager-open': isTaskManagerOpen && taskManagerPanelSet === panelSetId,
			[`caselist-item--color-${panelSetColor}`]: !_.isNil(panelSetColor)
		});

		const panelIcons = _.map(panels, (panel) => {
			const panelType = panel[API.panel.type];
			const channelId = panel[API.panel.channelId];
			if ((panelType !== API.panel.types.chat && panelType !== API.panel.types.email) || counter === null) {
				return (
					<PanelIcon key={'interaction_icon_' + panel[API.panel.id]} label="Chat placeholder text" interactionType={panelType} channelId={channelId} />
				);
			}
			else {
				return (
					<Badge key={'badge_' + panel[API.panel.id]} badgeContent={counter} classes={{ badge: `caselist-item--color-${panelSetColor}__badge` }}>
						<PanelIcon key={'interaction_icon_' + panel[API.panel.id]} label="Chat placeholder text" interactionType={panelType} channelId={channelId} />
					</Badge>
				);
			}

		});

		const caseListId = `caseListItem_${panelSetId}`
		return (
			<li className={classes}>
				<button id={caseListId} type="button" className="panelsetlist-item__button" onClick={onActivate}>
					<span className="caselist-item__customer-name">{title}</span>
					<span className="caselist-item__panelsettype">{subtitle}</span>
					<span className="caselist-item__panelsettype">{timer}</span>
					<span className="caselist-item__interactions">
						{panelIcons}
					</span>
				</button>
				{
					taskManagerArrowEnabled &&
					<button type="button" className="caselist-item__arrow" onClick={isTaskManagerOpen && taskManagerPanelSet === panelSetId ? onCloseTaskManager : this.openTaskManager}>
						<Icon name={ICONS.rightarrow} />
					</button>
				}
			</li>
		);
	}

	static propTypes = {
		panelSetId: PropTypes.string,
		active: PropTypes.bool,
		panels: PropTypes.array.isRequired,
		title: PropTypes.string,
		timer: PropTypes.object,
		subtitle: PropTypes.string,
		panelSetColor: PropTypes.number,
		isTaskManagerOpen: PropTypes.bool,
		taskManagerPanelSet: PropTypes.string,
		onActivate: PropTypes.func.isRequired,
		onOpenTaskManager: PropTypes.func,
		onCloseTaskManager: PropTypes.func,
		counter: PropTypes.number

	};
}

export default CaseListItem;
