import { defineMessages } from 'react-intl';

const messages = defineMessages({
	HeaderComposed_Layout_fullsize: {
		defaultMessage: 'Full size',
		id: 'HeaderComposed_Layout_fullsize',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_twoColumns: {
		defaultMessage: 'Two columns',
		id: 'HeaderComposed_Layout_twoColumns',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_twoRows: {
		defaultMessage: 'Two rows',
		id: 'HeaderComposed_Layout_twoRows',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_rightColumnSplit: {
		defaultMessage: 'Right column split',
		id: 'HeaderComposed_Layout_rightColumnSplit',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_leftColumnSplit: {
		defaultMessage: 'Left column split',
		id: 'HeaderComposed_Layout_leftColumnSplit',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_topRowSplit: {
		defaultMessage: 'Top row split',
		id: 'HeaderComposed_Layout_topRowSplit',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_bottomRowSplit: {
		defaultMessage: 'Bottom row split',
		id: 'HeaderComposed_Layout_bottomRowSplit',
		description: 'Layout mode text'
	},

	HeaderComposed_Layout_crossSplit: {
		defaultMessage: 'Cross split',
		id: 'HeaderComposed_Layout_crossSplit',
		description: 'Layout mode text'
	}
});

export default messages;