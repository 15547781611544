import {createReducer} from 'redux-act';
import _ from 'lodash';
import * as Actions from '../../actions';

import API from '../../api/Api';

export default createReducer({

	[Actions.languageSet]: (state, payload) => ({...state, [API.uiState.translations]: payload.translations}),
	//Note:
	//panelColor selection lowered due to Bug 16705: BP AgentFrontEnd - Chat notification bubbles not shown properly
	//in Task 21865: Quick fix - lower possible panel colors from 8 to 6
	[Actions.loadInitialElements]: (state) => ({...state, [API.uiState.initialDataLoaded]: true, [API.uiState.unusedPanelSetColors]: [1,2,3,4,5,6]}),
	[Actions.increaseWavePhase]: (state) => ({...state, [API.uiState.wavePhase]: state[API.uiState.wavePhase] + _.random(250, 500)}),
	[Actions.closeSupervisorBreakCard]: (state) => {

		const newState = {...state};
		newState[API.uiState.loadSuperVisorBreakCard] = false;
		return newState;
	},
	[Actions.moveToSupervisorBreak]: (state) => {

		const newState = {...state};
		newState[API.uiState.loadSuperVisorBreakCard] = false;
		return newState;
	},
	[Actions.logIn]: (state, payload) => {

		const newState = {...state};
		if (payload.superVisor){
			newState[API.uiState.loadSuperVisorBreakCard] = true;
		}
		
		return newState;
	},
}, {});

