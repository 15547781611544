import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../actions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as Selectors from '../../selectors'
import API from '../../api/Api';

const title_standard = 'Buzzeasy Agent'
const favicon_normal = 'https://geomantassetsprod.azureedge.net/static-files/bp_favicon_normal.ico';
const favicon_notification = 'https://geomantassetsprod.azureedge.net/static-files/bp_favicon_notification.ico';
const notificationIcon = 'https://geomantassetstoreprod.blob.core.windows.net/static-files/buzzeasy-icon.png';
let lastWiCount = 0;
let wiRaised = false;
let lastUnreadMessageCount = 0;
let msgRaised = false;
let lastNotificationRaisedAt = Math.round(new Date().getTime() / 1000) - 100;
const oneMsg = " unread message; click here to go to Buzzeasy!"
const moreMsg = " unread messages; click here to go to Buzzeasy!"


/**
 * This class edits the website title and favicon based on what notifications we want to render
 * Also raise notification for the user if new workitem or unread message arrive to the ui
 */
class WebSiteNotification extends React.PureComponent {

	componentDidMount() {
		window.addEventListener("focus", this.onFocus)
		window.addEventListener("blur", this.onBlur)
		this.interval = setInterval(() => { document.title = this.props.title; }, 1000);
		Notification.requestPermission();
	}

	componentWilUnmount() {
		window.removeEventListener("focus", this.onFocus)
		window.removeEventListener("blur", this.onBlur)
		clearInterval(this.interval);
	}

	onFocus = () => {
		this.props.onFocus(moment().valueOf());
	}

	onBlur = () => {
		this.props.onBlur(moment().valueOf());
	}

	render() {
		return (null)
	}
}

const changeFavicon = (src) => {
	let link = document.createElement('link'),
		oldLink = document.getElementById('dynamic-favicon');
	link.id = 'dynamic-favicon';
	link.rel = 'icon';
	link.type = 'image/x-icon';
	link.href = `${src}?v=${new Date().valueOf()}`;

	if (oldLink) {
		if (oldLink.href && oldLink.href.startsWith(src)) {
			return
		}
		document.head.removeChild(oldLink);
	}

	document.head.appendChild(link);
}

const calculateTitle = (newWiCount, unreadMessageCount, isFocused) => {

	if ((!isFocused && newWiCount > 0) || unreadMessageCount > 0) {
		changeFavicon(favicon_notification);
	}
	else {
		changeFavicon(favicon_normal);
		lastWiCount = 0;
	}
	notifyAgent(newWiCount, unreadMessageCount, isFocused);

	if (unreadMessageCount === 0) {
		lastUnreadMessageCount = 0;
		return title_standard
	}
	else {
		return `(${unreadMessageCount.toString()}) ${title_standard}`
	}

}

function notifyAgent(newWiCount, unreadMessageCount, isFocused) {

	if (!isFocused && (newWiCount || unreadMessageCount)) {
		if (newWiCount == 0) {
			wiRaised = true;
		} else if (lastWiCount < newWiCount) {
			wiRaised = false;
		}

		if (unreadMessageCount != 0 && lastUnreadMessageCount < unreadMessageCount) {
			msgRaised = false;
		} else if (unreadMessageCount == 0) {
			msgRaised = true;
		}

		if (!wiRaised) {
			wiRaised = true;
			raiseNotification("Click here to go to Buzzeasy!");
			lastWiCount = newWiCount;
		}

		if (!msgRaised) {
			msgRaised = true;
			let msg = unreadMessageCount == 1 ? oneMsg : moreMsg;
			raiseNotification(unreadMessageCount + msg);
			lastUnreadMessageCount = unreadMessageCount;
		}
	}
	else {
		lastWiCount = newWiCount;
		lastUnreadMessageCount = unreadMessageCount;
	}
}

const raiseNotification = (message) => {

	let permission = Notification.permission;
	if (permission === "granted" && lastNotificationRaisedAt < new Date().getTime() / 1000 - 7) {
		showNotification(message);
	}
	else if (permission === "default") {
		requestAndShowNotification(message);
	}
}

function showNotification(message) {
	let Title = "New Buzzeasy activity!";
	let options = {
		body: message,
		renotify: false,
		icon: notificationIcon
	}

	let notification = new Notification(Title, options)
	notification.onclick = () => {
		notification.close();
		window.parent.focus();

	}
	notification.onshow = () => {
		lastNotificationRaisedAt = Math.round(new Date().getTime() / 1000);
	}
}

function requestAndShowNotification(message) {
	Notification.requestPermission(function (permission) {
		if (permission === "granted" && lastNotificationRaisedAt < new Date().getTime() / 1000 - 7) {
			showNotification(message);
		}
	});
}

const mapStateToProps = (state) => {

	const newWiCount = Selectors.getNewWorkItemCount(state);
	const isFocused = state[API.notifications._key][API.notifications.isFocused];
	const unreadMessageCount = Selectors.getTotalUnreadMessageCount(state);
	const title = calculateTitle(newWiCount, unreadMessageCount, isFocused)
	return {
		title
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFocus: bindActionCreators(Actions.windowIsInFocus, dispatch),
		onBlur: bindActionCreators(Actions.windowIsNotInFocus, dispatch)

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSiteNotification);