import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {injectIntl, intlShape} from 'react-intl';
import classNames from 'classnames';
import statusMessages from '../UserStatus/UserStatus.messages';
import {getUserName, getUserPhoto, getUserStatus, 
		getUserExtension, getUserAgentId, getUserVoiceEnabled,
		hasOpenWorkItem, getUserStateSince} from '../../selectors';
import TaskManager from '../TaskManager/TaskManager';
import UserProfile from '../../components/UserProfile/UserProfile';
import UserStatus from '../UserStatus/UserStatus';
import API from '../../api/Api';
import './HeaderComposed.css';

/**
 * Header component that contains the profilimage, name, status
 */
class HeaderComposed extends PureComponent {

	mapUserStatusToColor(status) {

		switch(status) {

			case API.agentStatusTypes.busy:
			case API.agentStatusTypes.pendingBreak:
			case API.agentStatusTypes.pendingLogout:
				return 'red';
			
			case API.agentStatusTypes.idle:
				return 'green';
			
			case API.agentStatusTypes.break:
				return 'yellow'
			
			case API.agentStatusTypes.logout:
			default:
				return 'gray';
		}
	}

  render() {

		const {intl, name, profileImage, showTaskManager, status, extension, agentId, voiceEnabled, stateSince} = this.props;
		const {formatMessage} = intl;

		const classes = classNames({
			'header': true,
			//fix userprofile to the right side
			//'header--no-taskmanager': !showTaskManager
		});

		const statusMessage = statusMessages['UserStatus_' + status];
		const statusText = statusMessage ? formatMessage(statusMessage) : '';
		return (
			<header className={classes}>
				{
					showTaskManager && <TaskManager />					
				}

				<UserProfile name={name} profileImage={profileImage} statusText={statusText} statusColor={this.mapUserStatusToColor(status)} 
					extension={extension} agentId={agentId} voiceEnabled={voiceEnabled} statusStartTime={stateSince}/>
				<UserStatus />
			</header>
		);
  }

  static propTypes = {
		intl: intlShape.isRequired,
		name: PropTypes.string.isRequired,
		profileImage: PropTypes.string,
		showTaskManager: PropTypes.bool,
		status: PropTypes.string,
		extension: PropTypes.string,
		agentId: PropTypes.string,
		voiceEnabled: PropTypes.bool,
		stateSince: PropTypes.number
 };
}

const mapStateToProps = (state) => {

	const name = getUserName(state);
	const status = getUserStatus(state);
	const profileImage = getUserPhoto(state);
	const extension = getUserExtension(state);
	const agentId = getUserAgentId(state);
	const voiceEnabled = getUserVoiceEnabled(state);
	const stateSince = getUserStateSince(state);

	let tempShowTaskManager = false;

	if(hasOpenWorkItem(state)) {

		tempShowTaskManager = true;
	}

	const showTaskManager = tempShowTaskManager;

	return {
		name,
		profileImage,
		showTaskManager,
		status,
		extension,
		agentId,
		voiceEnabled,
		stateSince
	};
};

export default connect(mapStateToProps)(injectIntl(HeaderComposed));