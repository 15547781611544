import { defineMessages } from 'react-intl';

const messages = defineMessages({

	TaskManager_DispositionCodeNotSpecified: {
		defaultMessage: 'Not Specified',
		id: 'TaskManager_DispositionCodeNotSpecified',
		description: 'Default option for the disposition code dropdown'
	},

	TaskManager_DispositionCodesDisabled: {
		defaultMessage: 'Codes are disabled',
		id: 'TaskManager_DispositionCodesDisabled',
		description: 'Default and only option to select when disposition codes are disabled'
	},	

	TaskManager_DispositionCodeLabel: {
		defaultMessage: 'Disposition Code',
		id: 'TaskManager_DispositionCodeLabel',
		description: 'Label for disposition code dropdown'
	},

	TaskManager_Title: {
		defaultMessage: 'Task Manager',
		id: 'TaskManager_Title',
		description: 'Title for Task Manager'
	},

	TaskManager_SelectDispositionCode: {
		defaultMessage: 'Select a Code',
		id: 'TaskManager_SelectDispositionCode',
		description: 'Title for the prompt to select a disposition code'
	},

	TaskManager_AddNotes: {
		defaultMessage: 'Add Notes',
		id: 'TaskManager_AddNotes',
		description: 'Add Notes'
	},

	TaskManager_CloseTask: {
		defaultMessage: 'Close Task',
		id: 'TaskManager_CloseTask',
		description: 'Close Task button label'
	},
	
	TaskManager_DisabledCloseTask: {
		defaultMessage: 'You must leave your active conversation first',
		id: 'TaskManager_DisabledCloseTask',
		description: 'Disabled Close Task button label'
	}
});

export default messages;
