import {createReducer} from 'redux-act';
import * as Actions from '../../actions';

export default createReducer({
	[Actions.setEnvironment]: (state, payload) => {
		const {environment} = payload
		if (environment && environment.length){
			const newState = environment.slice();
			return newState;
		}
		return {...state};
    }
}, {});