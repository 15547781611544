import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Selectors from '../../selectors';
import Icon, { ICONS } from '../../components/Icon/Icon';
import './AgentPerformanceDashboard.css'

const closeAPDFuncName = "closeAPD";

class AgentPerformanceDashboard extends Component {
	
	constructor(props) {
		super(props);

		this.fragmentRef = React.createRef();
		
		let isAPDVisible = localStorage.getItem('isAPDVisible');
		if(isAPDVisible)
		{
			this.state = {isVisible : true}
		}
		else{ this.state = {isVisible : false}}

	}

	componentDidMount() {
    this.fragmentRef.current
			.addEventListener(closeAPDFuncName, () => {
				this.setState({ isVisible: false });
				localStorage.removeItem('isAPDVisible');
			});
  }


	render() {
		const { accessToken } = this.props;
		return (
			<div className={`agent-dash__container ${this.state.isVisible ? 'agent-dash__container-open' : ''}`}>
				{
					!this.state.isVisible && (
						<button
							className="agent-dash__open-btn"
							title="Open Performance Dashboard"
							onClick={() => {
								this.setState({ isVisible: true });
								localStorage.setItem('isAPDVisible', 'true');
								}}
							>
							<Icon name={ICONS.leftarrow} />
						</button>
					)
				}
				<div id='agentDashPanel' className='agent-dash card-shadow' style={{ display: !this.state.isVisible ? 'none' : undefined }}> 
					<agent-performance-dashboard-fragment
						ref={this.fragmentRef}
						token={accessToken}
						showCloseButton={true}
					/> 
				</div>
			</div>
		);
	}

	static propTypes = {
		accessToken: PropTypes.string
	};
}

const mapStateToProps = (state) => {
	const accessToken = Selectors.backEndAccessToken(state);
	return {
		accessToken,
	}

};

export default connect(mapStateToProps)(AgentPerformanceDashboard);
