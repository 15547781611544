import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../containers/Login/Login';
import CallbackPage from '../containers/CallbackPage/CallbackPage';
import MainLayout from '../containers/MainLayout/MainLayout';
import PopUpPage from '../containers/PopUpPage/PopUpPage';
import Authorize from '../containers/Authorize/Authorize';
import TeamsAuthCallback from '../containers/TeamsAuthCallback/TeamsAuthCallback';
import * as microsoftTeams from '@microsoft/teams-js';

export default function Routes(props) {
    microsoftTeams.initialize();
    return (
        <Router>
            <Switch>
                <Route exact path="/client" component={MainLayout} />
                <Route exact path="/callback" component={CallbackPage} />
                <Route exact path="/popup" component={PopUpPage} />
                <Route exact path="/authorize" component={Authorize} />
                <Route exact path="/cb" component={TeamsAuthCallback} />
                <Route exact path="/logout" component={() => {
                    window.location.href = window.location.origin + "/loggedOut.html";
                    return null;
                }} />
                <Route exact path="/" component={() => <Login isUnauthorized={props.isUnauthorized} />} />
                <Route path='*' component={() => {
                    window.location.href = window.location.origin + "/404page.html";
                    return null;
                }} />
            </Switch>
        </Router>
    );

}
