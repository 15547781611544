import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import './PanelTitle.css';

class PanelTitle extends PureComponent {
    render() {
		const {label, icon, clickHandler} = this.props;
		const labelNoSpace = label.replace(/\s+/g, '')
		const panelTitleId = `${labelNoSpace}_Id`

		return (
			<button onClick={clickHandler} id={panelTitleId} type="button" title={label} className="panel-title">
				{icon.length > 0 && <Icon name={icon} />}
				<span className="panel-title__label">{label}</span>
			</button>
		);
    }

    static propTypes = {
		label: PropTypes.string.isRequired,
		icon: PropTypes.string,
		clickHandler: PropTypes.func
    };

    static defaultProps = {
		label: '',
	}
}

export default PanelTitle;
