import {defineMessages} from 'react-intl';

const messages = defineMessages({

	StartShift_welcome: {
		defaultMessage: 'Hello Agent!',
		id: 'StartShift_welcome',
		description: 'StartShift / Welcome title'
	},

	StartShift_shiftStarts: {
		defaultMessage: 'Please wait, application is loading...',
		id: 'StartShift_shiftStarts',
		description: 'StartShift / shift starts instructions'
	},

	StartShift_shiftEnds: {
		defaultMessage: 'Your shift ends in:',
		id: 'StartShift_shiftEnds',
		description: 'StartShift / shift ends instructions'
	},

	StartShift_startInAutoMode: {
		defaultMessage: 'Start in Auto mode',
		id: 'StartShift_startInAutoMode',
		description: 'StartShift / Auto mode button label'
	},

	StartShift_startInSearchMode: {
		defaultMessage: 'Start in Search mode',
		id: 'StartShift_startInSearchMode',
		description: 'StartShift / Search mode button label'
	},

	StartShift_continueInAutoMode: {
		defaultMessage: 'Continue in Auto mode',
		id: 'StartShift_continueInAutoMode',
		description: 'Request / Auto mode button label'
	},

	StartShift_continueInSearchMode: {
		defaultMessage: 'Continue in Search mode',
		id: 'StartShift_continueInSearchMode',
		description: 'Request / Search mode button label'
	},

	StartShift_requestBreakButtonsTitle: {
		defaultMessage: 'Request break:',
		id: 'StartShift_requestBreakButtonsTitle',
		description: 'StartShift / Request break buttons title'
	}
});

export default messages;