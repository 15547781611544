import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import './PanelTabsSubmenu.css';
import ActionButton, {BUTTON_STYLE} from '../../components/ActionButton/ActionButton';
import classNames from 'classnames';

class PanelTabsSubmenu extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			tabsPanelVisible: false
		}
	}

	handleClickOutside = (e) => {
		this.setState({tabsPanelVisible: false});
	};

	toggleTabsPanel = () => {
		this.setState((prevState) => {
			return {tabsPanelVisible: !prevState.tabsPanelVisible};
		});
	};

	render() {

		//readd 'disabled' to props
		const {icon, side, dividers, children, disabled, id} = this.props;
		const {tabsPanelVisible} = this.state;

		const classes = classNames({
			'submenu': true,
			'submenu--active': tabsPanelVisible,
			'submenu--dividers': dividers,
			'submenu--floating': side,
			[`submenu--floating-${side}`]: side
		});

		//Revert ActionButton disabled back to disabled

		return (
			<div className={classes}>

				<ActionButton id={id} disabled={disabled} clickHandler={this.toggleTabsPanel} icon={icon} buttonStyle={[BUTTON_STYLE.transparent]} />

				{
					tabsPanelVisible &&
						<div onClick={this.handleClickOutside} className="submenu__panel toolbar-shadow">
							<div className="submenu__menuitems">
								{children}
							</div>
						</div>
				}
			</div>
		);
	}

	static propTypes = {
		id: PropTypes.string,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]),
		disabled: PropTypes.bool,
		dividers: PropTypes.bool,
		icon: PropTypes.string,
		side: PropTypes.string
	};
}

export default onClickOutside(PanelTabsSubmenu);
