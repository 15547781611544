import createCachedSelector from 're-reselect';
import _ from 'lodash';

import API from '../api/Api';
import { LAYOUT_BY_ITEMS } from '../data/grid';
import ReduxModels from '../api/ReduxModels';
import { getWorkItemsV2 } from './conversations';
import { getScriptingReduxObject } from './scripting';


const state = (state) => state;
const panelSetsSelect = (state) => state[API.panelSet._key];
const getStages = (state) => state[API.user._key][API.user.stages];
const panelSetIdSelect = (state, panelSetId) => panelSetId;
const stageIdSelect = (state, stageId) => stageId;
const infoTypeSelect = (state, panelSetId, infoType) => infoType;
const PanelSetByIdSelect = (allPanelSets, id) => allPanelSets[id];
const PanelSetInfoSelect = (panelSet, infoType) => panelSet[infoType];

export const getPanelSetsById = createCachedSelector(
	[panelSetsSelect, panelSetIdSelect],
	(allPanelSets, panelSetIds) => _.map(panelSetIds, (id) => PanelSetByIdSelect(allPanelSets, id))
)(
	(state, panelSetIds) => 'getPanelSetsById_' + panelSetIds.join('_')
);

export const getPanelSetsHashById = createCachedSelector(
	[panelSetsSelect, panelSetIdSelect],
	(allPanelSets, panelSetIds) => _.pick(allPanelSets, panelSetIds)
)(
	(state, panelSetIds) => 'getPanelSetsHashById_' + panelSetIds.join('_')
);

export const getPanelSet = createCachedSelector(
	[panelSetsSelect, panelSetIdSelect],
	(allPanelSets, id) => PanelSetByIdSelect(allPanelSets, id)
)(
	(state, panelSetId) => 'panelSet_' + panelSetId
);

export const getCurrentLayoutIdOfPanelSet = createCachedSelector(
	[getPanelSet],
	(panelSetItem) => { //panel set item is the sidebar indicator of a panel set
		const interactionsGroupCount = panelSetItem[API.panelSet.panels].length;
		const layoutId = _.get(panelSetItem, `${API.panelSet.layout}.id`, LAYOUT_BY_ITEMS[interactionsGroupCount - 1][0]);
		return layoutId;
	}
)(
	(state, panelSetId) => 'currentLayoutId_of_panelSet' + panelSetId
);

export const getAvailablePanelSetLayouts = createCachedSelector(
	[getPanelSet, getCurrentLayoutIdOfPanelSet],
	(panelSetItem, currentLayoutId) => {

		// Find possible other layouts
		const layoutGroupIndex = _.findIndex(LAYOUT_BY_ITEMS, (group) => _.includes(group, currentLayoutId));
		const otherLayouts = _.without(LAYOUT_BY_ITEMS[layoutGroupIndex], currentLayoutId);

		return otherLayouts;

	}
)(
	(state, panelSetId) => 'availablePanelSetLayouts_' + panelSetId
);

export const getPanelSetInfo = createCachedSelector(
	[getPanelSet, infoTypeSelect],
	(selectedCase, infoType) => PanelSetInfoSelect(selectedCase, infoType)
)(
	(state, panelSetId, infoType) => 'panelSetInfo_' + panelSetId + infoType
);

// The following selectors are all about panels, not interactions!

export const getInteractionIdsOfCases = createCachedSelector(
	[state, panelSetIdSelect],
	(state, panelSetIds) => _.compact(_.flatMap(panelSetIds, (panelSetId) => getInteractionIds(state, panelSetId)))
)(
	(state, panelSetIds) => 'interactionIdsOfCases_' + panelSetIds.join('_')
);

export const getInteractions = createCachedSelector(
	[state, panelSetIdSelect],
	(state, panelSetId) => getPanelSetInfo(state, panelSetId, API.panelSet.panels)
)(
	(state, panelSetId) => 'caseInteractions_' + panelSetId
);

export const getInteractionGroupPanels = createCachedSelector(
	[getPanelSet, infoTypeSelect],
	(selectedCase, interactionGroupId) => _.find(PanelSetInfoSelect(selectedCase, API.panelSet.panels), { [API.panel.id]: interactionGroupId })[API.panel.panels]
)(
	(state, panelSetId, interactionGroupId) => 'caseInteractionGroupPanels_' + panelSetId + '_' + interactionGroupId
);

export const getInteractionIds = (state, panelSetId) => {

	const interactions = getInteractions(state, panelSetId);

	return _.flatMap(interactions, (interaction) => interaction[API.panel.panels]);
};

export const getAvailableInteractionTypes = () => [];

export const getOtherStagePanelSets = createCachedSelector(
	[getStages, stageIdSelect],
	(stages, stageId) => {
		const otherStages = _.filter(stages, (stage) => {
			const otherStageId = stage[API.stage.id];
			return !_.includes([stageId, API.stage.defaultStage], otherStageId);
		});

		const openPanelSets = _.flatMap(otherStages, API.stage.panelSets);
		return openPanelSets;
	}
)(
	(state, stageId) => 'getOtherStagePanelSets_without_' + stageId
);

export const getOpenPanelSetList = createCachedSelector(
	[state, getOtherStagePanelSets],
	(state, panelSetIds) => {
		const panelSetItems = getPanelSetsById(state, panelSetIds);

		const panelSetList = _.compact(_.map(panelSetItems, (panelSetItem) => {
			const panelSetId = panelSetItem[API.panelSet.id];
			const panelSetStatus = getPanelSetInfo(state, panelSetId, API.panelSet.status);
			const workItemId = getPanelSetInfo(state, panelSetId, API.panelSet.workItemId);
			const panelSetColor = getPanelSetInfo(state, panelSetId, API.panelSet.color);

			return {
				[API.panelSet.id]: panelSetId,
				[API.panelSet.status]: panelSetStatus,
				[API.panelSet.panels]: panelSetItem[API.panelSet.panels],
				[API.panelSet.workItemId]: workItemId,
				[API.panelSet.color]: panelSetColor
			};
		}));

		return panelSetList;
	}
)(
	(state, stageId) => 'openPanelSetList_without_stage_' + stageId
);

/**
 * returns the Ids of those panelSets, whos workItem property is not null
 * @param {*} state 
 */
export const getWorkItemPanelSetIds = (state) => {
	const panelSets = panelSetsSelect(state);
	const panelSetIds = [];
	for (var panelSetId in panelSets) {
		if (panelSets.hasOwnProperty(panelSetId)) {
			const panelSet = panelSets[panelSetId];
			if (panelSet.hasOwnProperty(API.panelSet.workItemId) && (panelSet[API.panelSet.workItemId] !== null) && (panelSet[API.panelSet.workItemId] !== undefined)) {
				panelSetIds.push(panelSetId);
			}
		}
	}
	return panelSetIds;
}

/**
 * returns those panelSets that are not attached to any stage
 */
export const getPanelSetIdsWithoutStages = (state) => {

	//this is the result array, will push panelSetIds if no stage contains that
	const orhpanedPanelSetIds = [];

	const panelSets = panelSetsSelect(state);

	//first get every panelSetId
	const panelSetIds = [];
	for (var panelSetId in panelSets) {
		if (panelSets.hasOwnProperty(panelSetId)) {
			panelSetIds.push(panelSetId);
		}
	}

	//then get every stage
	const stages = getStages(state);

	//nested linear iteration. Not the best in terms of performance.
	panelSetIds.forEach(panelSetId => {
		let found = false;
		stages.forEach(stage => {
			if (stage[API.stage.panelSets].includes(panelSetId)) {
				found = true;
			}
		})
		if (found === false) {
			orhpanedPanelSetIds.push(panelSetId);
		}
	})

	return orhpanedPanelSetIds;
}

/**
 * returns the id of the workitem the panelset is attached to
 * @param {*} state 
 */
export const getPanelSetWorkItemId = (state, panelSetId) => {

	const panelSet = panelSetsSelect(state)[panelSetId];

	if (panelSet) {

		return panelSet[API.panelSet.workItemId];
	}

	return null;
}

/**
 * returns the id of the panelSet that is attached to the workItem
 * @param {*} state 
 */
export const getPanelSetOfWorkItem = (state, workItemId) => {

	const panelSets = state[API.panelSet._key];
	for (var panelSetId in panelSets) {
		if (panelSets.hasOwnProperty(panelSetId)) {
			const panelSet = panelSets[panelSetId];
			if (panelSet[API.panelSet.workItemId] === workItemId) {
				return panelSet[API.panelSet.id]
			}
		}
	}

	return null;
}

/**
 * Calculates which panel types can be opened under this panelSet
 * For WorkItem-related panelSets, it is using the state response from AgentFabric
 * For Conversation-related panelSets, the only rule is that customerView can be opened, but only once
 * @param {*} state 
 * @param {*} panelSetId 
 */
export const calculateAvailablePanelTypes = (state, panelSetId) => {



	const panelSet = state[API.panelSet._key][panelSetId];
	if (!panelSet) {
		return [];
	}
	const workItemId = panelSet[API.panelSet.workItemId];
	if (!workItemId) {
		return [];
	}
	const workItem = getWorkItemsV2(state)[workItemId];
	if (!workItem) {
		return [];
	}
	if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.lookUp) {
		return []
	}
	const availablePanelTypes = [];
	//this is a panelSet for the new conversation window management logic
	//panelHandling is done entirely clientside, the only logic we have is customerView can be only opened once
	var customerViewAlreadyOpened = false;

	const panels = panelSet[API.panelSet.panels];

	panels.forEach(panel => {
		//Todo: this is in fact panelGroup but panelGroup and panelId are the same 
		const panelId = panel[API.panel.id];
		if (state[API.panel._key][panelId][API.panel.type] === API.panel.types.customerview) {
			customerViewAlreadyOpened = true;
		}
	})

	if (customerViewAlreadyOpened === false) {
		availablePanelTypes.push(API.panel.types.customerview);
	}

	const scriptingReduxObject = getScriptingReduxObject(state, workItemId);
	if (scriptingReduxObject && scriptingReduxObject[ReduxModels.scripting.url]) {
		const panels = panelSet[API.panelSet.panels];
		var scriptingAlreadyOpened = false;

		panels.forEach(panel => {
			const panelId = panel[API.panel.id];
			if (state[API.panel._key][panelId][API.panel.type] === API.panel.types.scripting) {
				scriptingAlreadyOpened = true;
			}
		})

		if (scriptingAlreadyOpened === false) {
			availablePanelTypes.push(API.panel.types.scripting)
		}
	}



	return availablePanelTypes;
}

/**
 * Returns the panelSet that contains the panel
 * Todo: refactor panel <-> panelSet coupling so that we won't require linear search to get this.
 * @param {*} state 
 * @param {*} panelId 
 */
export const getPanelSetOfPanel = (state, panelId) => {
	var result = null;
	const panelSets = state[ReduxModels.nodes.panelSet];
	for (var panelSetId in panelSets) {
		if (panelSets.hasOwnProperty(panelSetId)) {
			const panelSet = panelSets[panelSetId];
			panelSet[API.panelSet.panels].forEach(panelGroup => {
				//Todo: support multiple panels inside panelGroups
				if (panelId === panelGroup[API.panelGroup.id]) {
					result = panelSet;
					return;
				}
			});
		}
	}
	return result;
}

export const getWorkItemV2PanelSets = (state) => {
	var result = [];
	const panelSets = state[ReduxModels.nodes.panelSet];
	for (var panelSetId in panelSets) {
		if (panelSets.hasOwnProperty(panelSetId)) {
			const panelSet = panelSets[panelSetId];
			if (panelSet[API.panelSet.workItemIdV2]) {
				result.push(panelSetId);
			}
		}
	}
	return result;
}

export const getPanelSetById = (state, panelSetId) => {
	const panelSets = state[ReduxModels.nodes.panelSet];
	if (panelSetId in panelSets) {
		return panelSets[panelSetId];
	}
	return null;
}