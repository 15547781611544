import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

import { setLoginPhase } from '../../actions';
import { LOGIN_PHASE } from '../../data/states';
import { getTimeOfDay } from '../../services/datetime';

import LoginMessages from './Login.messages';
import Logo, { LOGO_PHASE } from '../../components/Logo/Logo';
import ActionButton, { BUTTON_STYLE } from '../../components/ActionButton/ActionButton';
import userManager from "../../services/userManager";
import { useHistory, useLocation } from "react-router-dom";
import { isSupervisor } from '../../services/accessToken';
import './Login.css'
import { userFound } from 'redux-oidc';
import * as Actions from '../../actions';
import * as Selectors from '../../selectors';
import * as microsoftTeams from '@microsoft/teams-js'
import { getUserName } from '../../services/userName';
import { User } from 'oidc-client';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = (props) => {
  let history = useHistory();
  let query = useQuery();
  const [authorized, setAuthorized] = useState(true);

  useEffect(async () => {
    if (query.get("mode") == "Teams") {
      microsoftTeams.initialize();
    }
    props.onSetLoginPhase(LOGIN_PHASE.init);
  }, []);

  const onLoginClick = () => {
    if (query.get("mode") == "Teams") {
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + "/#/authorize",
        width: 600,
        height: 535,
        successCallback: (storageString) => {
          const user = User.fromStorageString(storageString);
          if (!user.access_token) return;
          if (isSupervisor == null) { setAuthorized(false); }
          else {
            getUserName(user.access_token);
            props.initAuthWorker(user.access_token);
            props.loadInitElements(isSupervisor(user.access_token));
            props.onBackEndPollInit(process.env.REACT_APP_STATEURL);
            userManager.storeUser(user).then(() => history.push("/client"));
          }
        },
        failureCallback: (err) => console.error(err),
      });
    } else {
      userManager.signinRedirect();
    }
  }

  const { intl, phase, isUnauthorized } = props;
  const { formatMessage } = intl;

  let loginContent;

  switch (phase) {

    case LOGIN_PHASE.init:
      loginContent = (
        <section className="login scrollbar scrollbar--transparent scrollbar--full-width">
          <Logo key='logo' logoPhase={LOGO_PHASE.fadein} />
        </section>
      );
      break;

    case LOGIN_PHASE.login:
    case LOGIN_PHASE.loginForm: {

      const timeOfDay = getTimeOfDay(moment());
      const greeting = formatMessage(LoginMessages['LoginContent_Greeting_' + timeOfDay]);

      let loginForm, instructions;

      instructions = formatMessage(LoginMessages.LoginContent_Instructions);
      const aadAccount = formatMessage(LoginMessages.LoginButtons_AzureActiveDirectory);

      loginForm = (
        <div className="login-buttons" key="login_buttons">
          <ActionButton clickHandler={() => onLoginClick()} text={aadAccount} imageUrl={"../../assets/i/microsoft.svg"} buttonStyle={[BUTTON_STYLE.fullwidth, BUTTON_STYLE.accept, BUTTON_STYLE.raised]} />
        </div>
      );

      loginContent = (
        <section className="login scrollbar scrollbar--transparent scrollbar--full-width">
          <Logo key='logo' logoPhase={LOGO_PHASE.moveup} />
          <div className="login-content">
            <div className="login-content__welcome">{greeting}</div>
            {
              isUnauthorized &&
              <div className="login-content__unauthorized">{"You don't have the required permissions. Try to log in with a different account."}</div>
            }
            <div className="login-content__instructions">{instructions}</div>
            {loginForm}
            {authorized || <div> <h2 style={{ color: '#db0143' }}>Sorry, you are not authorized to view this page. Please contact your administrator.</h2> </div>}
          </div>
        </section>
      );
      break;
    }

    default:
      loginContent = <Logo />;

  }
  return (loginContent);


}
Login.propTypes = {
  accessToken: PropTypes.string,
  intl: intlShape.isRequired,
  phase: PropTypes.oneOf(_.values(LOGIN_PHASE)),
  onSetLoginPhase: PropTypes.func,
  onUserFound: PropTypes.func,
  initAuthWorker: PropTypes.func,
  loadInitElements: PropTypes.func,
  onBackEndPollInit: PropTypes.func,
  isUnauthorized: PropTypes.bool
};

const mapStateToProps = (state) => {
  const accessToken = Selectors.backEndAccessToken(state);
  return {
    phase: state.login.phase,
    accessToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetLoginPhase: bindActionCreators(setLoginPhase, dispatch),
    onUserFound: bindActionCreators(userFound, dispatch),
    initAuthWorker: bindActionCreators(Actions.initializeAuthWorker, dispatch),
    loadInitElements: bindActionCreators(Actions.loadInitialElements, dispatch),
    onBackEndPollInit: bindActionCreators(Actions.backEndPollInit, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));