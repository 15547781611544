import { createReducer } from 'redux-act';

import * as Actions from '../../actions';
import API from '../../api/Api';

export default createReducer({

	/*
	Creates the startshift panel. Used when initializing and there is no open work items appended to the user
	panelSet.js also has a reducer for this, which creates the dashboard panelSet
	*/
	[Actions.loadInitialElements]: (state, payload) => {

		//Todo: don't format the state, replace instead
		const newState = {};

		const startShiftPanel = {
			[API.panel.id]: API.panel.types.startShift,
			[API.panel.type]: API.panel.types.startShift,
			[API.panel.active]: true
		};

		newState[startShiftPanel[API.panel.id]] = startShiftPanel;

		if (payload.isSupervisor) {
			newState[API.panel.ids.supervisor] = {
				[API.panel.id]: API.panel.ids.supervisor,
				[API.panel.type]: API.panel.types.supervisor,
				[API.panel.active]: true
			};
		}

		return newState;
	},

	/**
	Adds the scripting panel
	 */
	[Actions.ScriptingUrlReceived]: (state, payload) => {

		const { workItemId } = payload;
		const newState = { ...state };
		const panelId = workItemId + "_scripting";
		newState[panelId] = {
			[API.panel.id]: panelId,
			[API.panel.type]: API.panel.types.scripting,
			[API.panel.active]: true
		};
		return newState;
	},

	/*
	Set a pending action flag of a panel.
	*/
	[Actions.setPanelPendingAction]: (state, payload) => {

		const { panelId, pendingActionName, pendingActionValue } = payload;
		const newState = { ...state };

		if (newState) {

			if (newState.hasOwnProperty(panelId)) {

				if (!newState[panelId].hasOwnProperty(API.panel.pendingActions._key)) {

					newState[panelId][API.panel.pendingActions._key] = {};
				}

				newState[panelId][API.panel.pendingActions._key][pendingActionName] = pendingActionValue;
			}
		}

		return newState;
	},


	[Actions.addPanelToPanelSet]: (state, payload) => {
		const { panelSetId, panelType } = payload;
		const newState = { ...state };

		const panelId = panelSetId + "_" + panelType;

		newState[panelId] = {
			[API.panel.id]: panelId,
			[API.panel.type]: panelType,
			[API.panel.workItemId]: panelSetId
		}
		return newState;
	}


}, {});

