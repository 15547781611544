import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {injectIntl, intlShape} from 'react-intl';

import messages from './StartShift.messages';
import {increaseWavePhase} from '../../actions';
import Timer from '../../components/Timer/Timer';
import Waves, {WAVE_TYPES} from '../Waves/Waves';
import StartShiftLayout from '../../layouts/StartShiftLayout/StartShiftLayout';

/**
 * Panel 'type'. Used initially before the break panel, looks similarly
 */
class StartShift extends Component {

	constructor(props) {
		super(props);
		this.waves = <Waves waveType={WAVE_TYPES.startShift} />;
	}

	componentDidMount() {
		this.props.onWave();
	}

	render() {

		const {intl} = this.props;
		const {formatMessage} = intl;

		const layoutProps = {};
		
		layoutProps.title = formatMessage(messages.StartShift_welcome);
		layoutProps.instructions = formatMessage(messages.StartShift_shiftStarts);		
		layoutProps.timer = <Timer key={'timer_'} autoStart={true} startFrom={0} startTime={Date.now()} />;
		layoutProps.waves = this.waves;
		return <StartShiftLayout {...layoutProps} />;
	}

	static propTypes = {
		intl: intlShape.isRequired,
		onWave: PropTypes.func,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		onWave: bindActionCreators(increaseWavePhase, dispatch)
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(StartShift));
