import { put, takeEvery, all, select, call, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import store from '../services/createStore';
import * as signalR from '@microsoft/signalr';

function* initSignalR() {
  const baseUrl = process.env.REACT_APP_STATEURL;

  const token = yield (select(Selectors.backEndAccessToken));
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(baseUrl, { accessTokenFactory: () => token })
    .withAutomaticReconnect([0, ...Array(20).fill(5000)])
    .build();
  connection.start().catch(function (err) {
    return console.error(err.toString());
  }).then(() => connection.invoke("HeartBeat").catch(err => {
    console.error(err.toString());
  }));
  connection.on("GetState", (data) => store.dispatch(Actions.stateDataRetrieved(data)));
  
  connection.on("StateTransitionRequested", (data) => store.dispatch(Actions.addMessage(
    {id: (new Date()).getTime(), timestamp: new Date(), text: 'Supervisor requested the following state: '+ data.toState, link:''})));
     
  yield put(Actions.saveSignalR(connection));
  yield put(Actions.backEndPollStart());
}


function* pollWorker(action) {

  while (true) {
    const connection = yield select(Selectors.getSignalR);
    if (connection && connection.state !== "Disconnected") {
      connection.invoke("HeartBeat").catch(err => {
        console.error(err.toString());
      });
    }
    yield call(delay, 30000);
  }

}


export default function* () {
  yield all([
    takeEvery(Actions.backEndPollInit, initSignalR),
    takeLatest(Actions.backEndPollStart.getType(), pollWorker)
  ]);
}