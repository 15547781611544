import React from 'react';
import PropTypes from 'prop-types';

function formatTime(seconds) {
	let h, m, s, result = '';

	if(!seconds)
	{
		return "00:00"
	}

	// Hours
	h = Math.floor(seconds / 3600);
	seconds -= h * 3600;
	if (h) {
		result = h < 10 ? '0' + h + ':' : h + ':';
	}

	// Minutes
	m = Math.floor(seconds / 60);
	seconds -= m * 60;
	result += m < 10 ? '0' + m + ':' : m + ':';

	// Seconds
	s = seconds % 60;
	result += s < 10 ? '0' + s : s;

	return result;
}

function timer(delay) {

	return function TimerHoc(TimedComponent) {
		class Timer extends React.Component {
			constructor(props) {
				super(props);
				this.delay = delay;
				this.state = {tick: props.startFrom || 0};

				this.synchronizeWith = props.synchronizeWith;
				this.endAt = props.endAt;
				this.startTime = props.startTime;
				this.onTimerFinished = props.onTimerFinished;
				this.synchronized = props.synchronizeWith !== undefined;
			}

			setTimeout = () => {
				const {delay, synchronizeWith} = this;
				const duration = delay - Math.abs(synchronizeWith - Date.now()) % delay;

				this.timer = setTimeout(() => {
					if (!this.stopped) this.setTimeout();
					this.setState(
						() => ({tick: (Math.floor((Date.now() - this.startTime) / 1000))}),
						() => {
							if (this.endAt !== undefined && this.state.tick === this.endAt) {
								this.stop();
								if (this.onTimerFinished) this.onTimerFinished();
							}
						}
					);
				}, duration);
			};

			start = () => {
				this.stopped = false;
				if (!this.synchronized) {
					this.synchronizeWith = Date.now();
				}
				if (!this.startTime || this.startTime == 0){ this.setState(() => ({tick: (0)}))} else
				this.setTimeout();
			};

			resume = () => {
				if (this.stopped) {
					this.start();
				}
			};

			reset = (value) => {
				this.setState(() => ({tick: this.props.startFrom || value || 0}));
			};

			stop = () => {
				this.stopped = true;
				clearTimeout(this.timer);
			};

			setDelay = (delay) => {
				this.delay = delay;
				if (!this.stopped) {
					this.stop();
					this.resume();
				}
			};

			get elapsed() {
				return this.state.tick;
			};

			componentDidMount() {
				if (this.props.autoStart) {
					this.start();
				}
			}

			componentWillUnmount() {
				this.stop();
			}

			render() {
				const {props, delay, start, stop, resume, setDelay} = this;
				const {tick} = this.state;
				const formatted = formatTime(this.state.tick);

				const timer = {delay, tick, formatted, start, stop, resume, setDelay};

				return React.createElement(TimedComponent, {...props, timer});
			}
		}

		Timer.propTypes = {
			synchronizeWith: PropTypes.number,
			autoStart: PropTypes.bool,
			startFrom: PropTypes.number,
			onTimerFinished: PropTypes.func,
			startTime: PropTypes.number
		};

		const componentName = TimedComponent.displayName || TimedComponent.name || 'Component';
		Timer.displayName = `Timer@${delay}[${componentName}]`;

		return Timer;
	};
}

export default timer;