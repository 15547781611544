import {put, takeLatest, all} from 'redux-saga/effects';

import * as Actions from '../actions';
 

function* changeLayout(action) {
	const { stageId, layoutId } = action.payload;
	yield put(Actions.stageLayoutChanged(stageId, layoutId));
}

export default function* () {
	yield all([
		takeLatest(Actions.changeLayout.getType(), changeLayout)
	]);
}




