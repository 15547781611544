import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';

import Icon from '../Icon/Icon';

import './ActionButton.css';

export const BUTTON_STYLE = {
	primary: 'primary',
	accept: 'accept',
	reject: 'reject',
	raised: 'raised',
	muted: 'muted',
	hold: 'hold',
	resume: 'resume',
	transparent: 'transparent',
	fullsize: 'fullsize',
	fullwidth: 'fullwidth',
	negative: 'negative',
	reverseOrder: 'reversed',
	settings: 'settings',
	effective: 'effective'
};

class ActionButton extends PureComponent {

	handleClickOutside = (e) => {
		e.stopPropagation();
		this.props.outsideClickHandler();
	};

	render() {
		const {label, text, icon, type, buttonStyle, disabled, children, clickHandler, id} = this.props;
		const classes = classNames({
			'action-button': true,
			'action-button--icon': !!icon,
			'action-button--text': !!text,
			'action-button--primary': _.includes(buttonStyle, BUTTON_STYLE.primary),
			'action-button--accept': _.includes(buttonStyle, BUTTON_STYLE.accept),
			'action-button--reject': _.includes(buttonStyle, BUTTON_STYLE.reject),
			'action-button--raised': _.includes(buttonStyle, BUTTON_STYLE.raised),
			'action-button--muted': _.includes(buttonStyle, BUTTON_STYLE.muted),
			'action-button--hold': _.includes(buttonStyle, BUTTON_STYLE.hold),
			'action-button--resume': _.includes(buttonStyle, BUTTON_STYLE.resume),
			'action-button--transparent': _.includes(buttonStyle, BUTTON_STYLE.transparent),
			'action-button--fullsize': _.includes(buttonStyle, BUTTON_STYLE.fullsize),
			'action-button--fullwidth': _.includes(buttonStyle, BUTTON_STYLE.fullwidth),
			'action-button--negative': _.includes(buttonStyle, BUTTON_STYLE.negative),
			'action-button--reverse': _.includes(buttonStyle, BUTTON_STYLE.reverseOrder),
			'action-button--settings': _.includes(buttonStyle, BUTTON_STYLE.settings),
			'action-button--effective': _.includes(buttonStyle, BUTTON_STYLE.effective),
			[`action-button--${icon}`]: icon ? true : false
		});
		
		return (
			<button id={id} type={type} className={classes} title={label || text} disabled={disabled} onClick={clickHandler} >
				{icon && <Icon key={'icon_' + icon} name={icon} />}
				{text && <span className="action-button__text">{text}</span>}
				{children && children}
			</button>
		);
	}

	static propTypes = {
		id: PropTypes.string,
		label: PropTypes.string,
		text: PropTypes.string,
		icon: PropTypes.string,
		type: PropTypes.string,
		buttonStyle: PropTypes.arrayOf(PropTypes.string),
		disabled: PropTypes.bool,
		clickHandler: PropTypes.func,
		outsideClickHandler: PropTypes.func,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		])
	};

	static defaultProps = {
		text: '',
		type: 'text',
		icon: null,
		buttonStyle: [],
		disabled: false
	}
}

export default ActionButton;
export const ActionButtonOutsideClick = onClickOutside(ActionButton);
