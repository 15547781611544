import { isEmpty, isNil } from 'lodash';
import API from '../api/Api';
import ReduxModels from '../api/ReduxModels';
import { getCapabilityResponse, getAgentProperties } from './capability';

export const getResourceReservedForCallLookup = (state) => {
  const agentProperties = getAgentProperties(state);
  const result = agentProperties[API.agentProperties.reservedForCallLookup];
  return result;
}

export const getConversations = (state) => {
  const capSnap = getCapabilityResponse(state);
  return capSnap[API.capabilityResponse.conversations];
}

export const getWorkItemsV2 = (state) => {
  const capSnap = getCapabilityResponse(state);
  return capSnap[API.capabilityResponse.workItemsV2];
}

export const getConversationIds = (state) => {
  const result = [];
  const workItems = getWorkItemsV2(state);
  //checking if an object is empty, the javascript way...
  for (var workItemId in workItems) {
    if (workItems.hasOwnProperty(workItemId)) {
      const workItem = workItems[workItemId];
      const conversationId = workItem[API.workItemV2.conversationId];
      if (conversationId) {
        result.push(conversationId);
      }
    }
  }
  return result;
}
export const getResourceReservedForCallLookupOrCall = (state) => {
  const capSnap = getCapabilityResponse(state);
  const workItems = capSnap[API.capabilityResponse.workItemsV2];

  for (var workItemId in workItems) {
    if (workItemId in workItems) {
      const workItem = workItems[workItemId];
      if (workItem[API.workItemV2.workItemState] === API.workItemV2.workItemStates.lookUp) {
        return true;
      }

      for (var conversationId in workItem[API.workItemV2.conversations]) {
        if (conversationId in workItem[API.workItemV2.conversations]) {
          const conversation = workItem[API.workItemV2.conversations][conversationId]

          if (conversation[API.conversation.channelType] === API.conversationChannelTypes.voice || conversation[API.conversation.channelType] === API.conversationChannelTypes.virtual) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export const getConversationChannelType = (state, conversationId, workItemId) => {
  if (workItemId) {
    const workItem = getWorkItemsV2(state)[workItemId];
    if (conversationId in workItem[API.workItemV2.conversations]) {
      return workItem[API.workItemV2.conversations][conversationId][API.conversation.channelType];
    }
  }
  return null;
}

export const getChannelIdForConversation = (state, panelId) => {
  const panel = state[ReduxModels.nodes.panel][panelId];
  const wiKey = panel[API.panel.workItemId];
  const workItems = getWorkItemsV2(state);
  if (isNil(workItems) || workItems.length == 0) {
    return null;
  }
  if (isNil(workItems[wiKey])) return null;
  return workItems[wiKey][API.workItemV2.channelId];
}

const hasConversation = (workItem, conversationId) => {
  return Object.keys(workItem[API.workItemV2.conversations]).includes(conversationId);
}