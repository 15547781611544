import { defineMessages } from 'react-intl';

const messages = defineMessages({
	PanelTitle_TaskList: {
		defaultMessage: 'Task list',
		id: 'PanelTitle_TaskList',
		description: 'Sidebar / task list title'
	},
	PanelTitle_TaskManager: {
		defaultMessage: 'Task manager',
		id: 'PanelTitle_TaskManager',
		description: 'Sidebar / task manager title'
	}
});

export default messages;