import { useEffect } from "react";
import * as microsoftTeams from '@microsoft/teams-js';

function getHashParams() {
  const hash = window.location.hash.substr(1).split('?')[1];
  return hash.split('&').reduce(function (res, item) {
    var parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});
}

function TeamsAuthCallback() {
  useEffect(
    () => {
      microsoftTeams.initialize();
      const hashParams = getHashParams();
      if (hashParams['error']) {
        microsoftTeams.authentication.notifyFailure(hashParams['error']);
      } else if (hashParams['access_token']) {
        const expectedState = localStorage.getItem('auth.state');
        if (expectedState !== hashParams['state']) {
          microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
        } else {
          microsoftTeams.authentication.notifySuccess(JSON.stringify(hashParams));
        }
      } else {
        microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
      }
    },
    []
  );

  return <div>Authenticating...</div>;
}

export default TeamsAuthCallback;