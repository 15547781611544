import React, {Component, cloneElement} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import {GRID_LAYOUTS, LAYOUT_IDS, LAYOUT_BY_ITEMS} from '../../data/grid';

import GridDivider, {DIVIDER_DIRECTION} from '../GridDivider/GridDivider';

import './GridView.css';

class GridView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			resizing: null,
			layout: null
		};
	}

	onResizeStart = ({node, deltaX, deltaY}, direction, gridIndex) => {

		const parentRect = node.offsetParent.getBoundingClientRect();
		const clientRect = node.getBoundingClientRect();

		let position = {
			direction: direction,
			gridIndex: gridIndex
		};

		position.x = clientRect.left - parentRect.left;
		position.y = clientRect.top - parentRect.top;
		position.width = parentRect.width;
		position.height = parentRect.height;

		this.setState(() => ({resizing: position}));
	};

	onResize = ({node, deltaX, deltaY}) => {

		let position = Object.assign({}, this.state.resizing, {offset: {}});
		let modifiedLayout = _.cloneDeep(this.state.layout);

		if (position.direction === DIVIDER_DIRECTION.horizontal) {
			position.x = position.x + deltaX;
			position.offset.x = _.clamp(position.x / position.width, 0, 1);
			modifiedLayout.grid[position.gridIndex].w = position.offset.x;
		} else {
			position.y = position.y + deltaY;
			position.offset.y = _.clamp(position.y / position.height, 0, 1);
			modifiedLayout.grid[position.gridIndex].h = position.offset.y;
		}

		this.setState(() => ({resizing: position, layout: modifiedLayout}));
	};

	onResizeStop = () => {
		this.setState(() => ({resizing: null}));
		this.props.onUpdateLayout(this.state.layout.grid);
		window.dispatchEvent(new Event('resize'));
	};

	toggleDivider = (gridIndex) => {
		const originalLayout = GRID_LAYOUTS[this.state.layout.id].grid[gridIndex];

		let modifiedLayout = _.cloneDeep(this.state.layout);
		modifiedLayout.grid[gridIndex].w = originalLayout.w;
		modifiedLayout.grid[gridIndex].h = originalLayout.h;

		this.storeLayout(modifiedLayout);
		this.onResizeStop();
	};

	ensureLayout = (layout, items) => {
		if (!layout || !layout.grid || items !== layout.grid.length) {
			let layoutId = LAYOUT_BY_ITEMS[items - 1][0];
			layout = GRID_LAYOUTS[layoutId];
		}

		return layout;
	};

	storeLayout = (layout) => {
		this.setState(() => ({layout: layout}));
	};

	componentDidMount() {
		let {layout, children} = this.props;
		layout = this.ensureLayout(layout, children.length);
		this.storeLayout(layout);
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		let {layout, children} = newProps;
		layout = this.ensureLayout(layout, children.length);

		if (!this.state.layout || !this.props.layout || layout.id !== this.props.layout.id) {
			this.storeLayout(layout);
		}
	}

	render() {

		const {children, panelSetLayout, width, height} = this.props;
		const {layout} = this.state;

		if (!layout) return null;

		const grid = layout.grid;
		const gridClasses = classNames({
			'grid': true,
			'grid--gutter': panelSetLayout
		});

		if (layout.id === LAYOUT_IDS.fullsize) {
			return (
				<div className={gridClasses}>
					{cloneElement(children[0], {panelSetLayout: panelSetLayout})}
				</div>
			);
		}

		let coords;
		let minWidth = 0;
		let minHeight = 0;
		let maxWidth = 1;
		let maxHeight = 1;

		// Calculate from element size
		if (width && height) {
			minWidth = (320 + 20) / width;
			maxWidth = 1 - minWidth;
			minHeight = (30 + 20) / height;
			maxHeight = 1 - minHeight;
		}

		switch (layout.id) {
			case LAYOUT_IDS.twoColumns: {

				const w = _.clamp(grid[0].w, minWidth, maxWidth);

				coords = [
					{x: 0, y: 0, width: w, height: 1},
					{x: w, y: 0, width: 1 - w, height: 1},
					{x: w, y: 0, width: '1px', height: 1}
				];
				break;
			}

			case LAYOUT_IDS.twoRows: {

				const h = _.clamp(grid[0].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: 1, height: h},
					{x: 0, y: h, width: 1, height: 1 - h},
					{x: 0, y: h, width: 1, height: '1px'}
				];
				break;
			}

			case LAYOUT_IDS.rightColumnSplit: {

				const w = _.clamp(grid[0].w, minWidth, maxWidth);
				const h = _.clamp(grid[1].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: w, height: 1},
					{x: w, y: 0, width: 1 - w, height: h},
					{x: w, y: h, width: 1 - w, height: 1 - h},
					{x: w, y: 0, width: '1px', height: 1},
					{x: w, y: h, width: 1 - w, height: '1px', index: 1}
				];
				break;

			}

			case LAYOUT_IDS.leftColumnSplit: {

				const w = _.clamp(grid[0].w, minWidth, maxWidth);
				const h = _.clamp(grid[0].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: w, height: h},
					{x: 0, y: h, width: w, height: 1 - h},
					{x: w, y: 0, width: 1 - w, height: 1},
					{x: 0, y: h, width: w, height: '1px'},
					{x: w, y: 0, width: '1px', height: 1}
				];
				break;
			}

			case LAYOUT_IDS.topRowSplit: {

				const w = _.clamp(grid[0].w, minWidth, maxWidth);
				const h = _.clamp(grid[0].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: w, height: h},
					{x: w, y: 0, width: 1 - w, height: h},
					{x: 0, y: h, width: 1, height: 1 - h},
					{x: w, y: 0, width: '1px', height: h},
					{x: 0, y: h, width: 1, height: '1px'}
				];
				break;
			}

			case LAYOUT_IDS.bottomRowSplit: {

				const w = _.clamp(grid[1].w, minWidth, maxWidth);
				const h = _.clamp(grid[0].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: 1, height: h},
					{x: 0, y: h, width: w, height: 1 - h},
					{x: w, y: h, width: 1 - w, height: 1 - h},
					{x: 0, y: h, width: 1, height: '1px'},
					{x: w, y: h, width: '1px', height: 1 - h, index: 1}
				];
				break;
			}

			case LAYOUT_IDS.crossSplit: {

				const w = _.clamp(grid[0].w, minWidth, maxWidth);
				const h = _.clamp(grid[0].h, minHeight, maxHeight);
				const h2 = _.clamp(grid[1].h, minHeight, maxHeight);

				coords = [
					{x: 0, y: 0, width: w, height: h},
					{x: w, y: 0, width: 1 - w, height: h2},
					{x: 0, y: h, width: w, height: 1 - h},
					{x: w, y: h2, width: 1 - w, height: 1 - h2},
					{x: w, y: 0, width: '1px', height: 1}, // |
					{x: 0, y: h, width: w, height: '1px'}, // --
					{x: w, y: h2, width: 1 - w, height: '1px', index: 1} // --
				];
				break;
			}

			default:
				throw new Error('Undefined layout requested: ' + layout.id);

		}

		const gridItems = _.map(coords, (coord, index) =>
			index < children.length ?
				cloneElement(children[index], {coords: coord, panelSetLayout: panelSetLayout}) :
				<GridDivider key={'divider_' + index} direction={_.isString(coord.width) ? DIVIDER_DIRECTION.horizontal : DIVIDER_DIRECTION.vertical} coords={coord} index={coord.index || 0} panelSetLayout={panelSetLayout} onStart={this.onResizeStart} onStop={this.onResizeStop} onDrag={this.onResize} onDoubleClick={this.toggleDivider}/>
		);


		return (
			<div className={gridClasses}>
				{gridItems}
			</div>
		);
	}

	static propTypes = {
		panelSetLayout: PropTypes.bool,
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]),
		width: PropTypes.number,
		height: PropTypes.number,
		onUpdateLayout: PropTypes.func,
		layout: PropTypes.object
	};

	static defaultProps = {
		panelSetLayout: false,
		children: null
	};
}

export default GridView;
