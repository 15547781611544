import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {DraggableCore} from 'react-draggable';
import classNames from 'classnames';
import _ from 'lodash';

import './GridDivider.css';

export const DIVIDER_DIRECTION = {
	horizontal: 'horizontal',
	vertical:  'vertical'
};

class GridDivider extends PureComponent {

	render() {
		const {direction, index, coords, panelSetLayout, onStart, onStop, onDrag, onDoubleClick} = this.props;

		const classes = classNames({
			'divider': true,
			'divider--horizontal': direction === DIVIDER_DIRECTION.horizontal,
			'divider--vertical': direction === DIVIDER_DIRECTION.vertical,
			'divider--gutter-resize': panelSetLayout
		});

		const width = _.isString(coords.width) ? coords.width : panelSetLayout ? `calc(${coords.width * 100}% - 20px)` : `${coords.width * 100}%`;
		const height = _.isString(coords.height) ? coords.height : panelSetLayout ? `calc(${coords.height * 100}% - 20px)` : `${coords.height * 100}%`;

		return (
			<DraggableCore onStart={(e, data) => onStart(data, direction, index)}
			onStop={() => onStop()} onDrag={(e, data) => onDrag(data)}>
				<div style={{left: `${coords.x * 100}%`, top: `${coords.y * 100}%`, width: width, height: height}}
					 className={classes}
					 onDoubleClick={() => onDoubleClick(index)}
				/>
			</DraggableCore>
		);
	}

	static propTypes = {
		direction: PropTypes.oneOf(_.values(DIVIDER_DIRECTION)),
		index: PropTypes.number,
		panelSetLayout: PropTypes.bool,
		onStart: PropTypes.func,
		onStop: PropTypes.func,
		onDrag: PropTypes.func,
		onDoubleClick: PropTypes.func,
		coords: PropTypes.object
	};
}

export default GridDivider;