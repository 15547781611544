import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import _ from 'lodash';

import * as Actions from '../../actions';
import API from '../../api/Api';
import DEFAULT_STATE from '../../data/states';
import { GRID_LAYOUTS, LAYOUT_BY_ITEMS } from '../../data/grid';
import { isSupervisor } from '../../services/accessToken';

const findStageIndex = function (state, panelSetId) {
	return _.findIndex(state[API.user.stages], (stage) => _.includes(stage[API.stage.panelSets], panelSetId));
};

const findStageIndexById = function (state, stageId) {
	return _.findIndex(state[API.user.stages], { [API.stage.id]: stageId });
};

const findStageId = function (state, panelSetId) {
	return _.find(state[API.user.stages], (stage) => _.includes(stage[API.stage.panelSets], panelSetId))[API.stage.id]
};

const createStage = function (panelSetId) {
	return {
		[API.stage.id]: _.uniqueId('stage_'),
		[API.stage.layout]: {},
		[API.stage.panelSets]: [panelSetId]
	};
};

/**
 * It removes the specified panelSet from the user's stages if it is located on a stage other than
 * the specified one. Then it destroys the stage - the panelSet is removed from - if it became empty.
 * 
 * @param {*} state 
 * @param {*} stageIndex 
 * @param {*} panelSetId 
 */
const removePanelSetFromOtherStages = function (state, stageIndex, panelSetId) {
	let newState = state;
	const otherStageIndex = _.findIndex(state[API.user.stages], (stage, index) => index !== stageIndex && _.includes(stage[API.stage.panelSets], panelSetId));

	if (otherStageIndex >= 0) {
		const panelSets = newState[API.user.stages][otherStageIndex][API.stage.panelSets];
		const newPanelSets = _.without(panelSets, panelSetId);

		newState = setPanelSets(newState, otherStageIndex, newPanelSets);
		newState = removeStageIfEmpty(newState, otherStageIndex);

	}

	return newState;
};

/**
 * It removes the specified panelSet from the user's stages. Then it destroys the
 * stage - the panelSet is removed from - if it became empty.
 * 
 * @param {*} state 
 * @param {*} panelSetId 
 */
export const removePanelSetFromStages = function (state, panelSetId) {

	let newState = state;
	const stageIndex = _.findIndex(state[API.user.stages], (stage, index) => _.includes(stage[API.stage.panelSets], panelSetId));

	if (stageIndex >= 0) {
		const panelSets = newState[API.user.stages][stageIndex][API.stage.panelSets];
		const newPanelSets = _.without(panelSets, panelSetId);

		newState = setPanelSets(newState, stageIndex, newPanelSets);
		newState = removeStageIfEmpty(newState, stageIndex);

	}

	return newState;
};

const removeStageIfEmpty = function (state, stageIndex) {

	let newState = state;
	const stages = state[API.user.stages];
	const stageCount = stages.length;

	if (stages[stageIndex][API.stage.panelSets].length === 0) {

		// Activate next available case
		const nextStageIndex = (stageIndex === stageCount - 1) ? stageIndex - 1 : stageIndex + 1;
		const nextStage = stages[nextStageIndex];
		const nextPanelSetId = nextStage[API.stage.panelSets][0];

		newState = update(newState, {
			[API.user.stages]: {
				$splice: [[stageIndex, 1]]
			}
		});

		newState = activatePanelSet(newState, nextPanelSetId);
	}

	return newState;
};

const setPanelSets = function (state, stageIndex, panelSetIds) {

	let newState = state;
	newState = update(newState, {
		[API.user.stages]: {
			[stageIndex]: {
				[API.stage.panelSets]: {
					$set: panelSetIds
				},
				[API.stage.layout]: {
					$set: GRID_LAYOUTS[LAYOUT_BY_ITEMS[panelSetIds.length][0]]
				}
			}
		}
	});

	return newState;
};

const activatePanelSet = function (state, panelSetId) {

	const stageId = findStageId(state, panelSetId);

	let newState = state;
	newState = update(newState, {
		[API.user.currentPanelSet]: {
			$set: panelSetId
		},
		[API.user.taskManagerPanelSet]: {
			$set: panelSetId
		},
		[API.user.currentStage]: {
			$set: stageId
		}
	});

	return newState;
};

export default createReducer({

	[Actions.logOut]: (state) => ({ ...state, [API.user.logoutRequested]: true }),

	[Actions.languageSet]: (state, payload) => ({ ...state, [API.user.language]: payload[API.user.language] }),

	[Actions.saveUserName]: (state, payload) => ({ ...state, [API.user.name]: payload[API.user.name] }),

	[Actions.saveUserPhoto]: (state, payload) => ({ ...state, [API.user.photo]: payload[API.user.photo] }),

	[Actions.initializeAuthWorker]: (state, payload) => {
		const newState = { ...state };
		const { accessToken } = payload;
		newState[API.user.accessToken] = accessToken;
		newState[API.user.isSupervisor] = isSupervisor(accessToken);

		return newState;
	},

	[Actions.addStageItem]: (state, payload) => {
		let newState = state;
		const { panelSetId, layoutId } = payload;

		if (layoutId) {
			const stageIndex = findStageIndexById(newState, newState[API.user.currentStage]);

			newState = update(newState, {
				[API.user.stages]: {
					[stageIndex]: {
						[API.stage.panelSets]: {
							$push: [panelSetId]
						},
						[API.stage.layout]: {
							$set: GRID_LAYOUTS[layoutId]
						}
					}
				}
			});

			newState = removePanelSetFromOtherStages(newState, stageIndex, panelSetId);
			newState = activatePanelSet(newState, panelSetId);

		} else {
			const newStage = createStage(panelSetId);
			newState = update(newState, {
				[API.user.stages]: {
					$push: [newStage]
				}
			});
		}

		return newState;
	},

	[Actions.stageLayoutChanged]: (state, payload) => {
		let newState = state;
		const { stageId, layoutId } = payload;

		const stageIndex = _.findIndex(state[API.user.stages], { [API.stage.id]: stageId });

		newState = update(newState, {
			[API.user.stages]: {
				[stageIndex]: {
					[API.stage.layout]: {
						$set: GRID_LAYOUTS[layoutId]
					}
				}
			}
		});

		return newState;
	},

	[Actions.extractPanelSet]: (state, payload) => {
		let newState = state;
		const { panelSetId } = payload;
		const stageIndex = findStageIndex(newState, panelSetId);
		const panelSets = state[API.user.stages][stageIndex][API.stage.panelSets];
		const panelSetIds = _.without(panelSets, panelSetId);
		const newStage = createStage(panelSetId);

		newState = setPanelSets(newState, stageIndex, panelSetIds);

		newState = update(newState, {
			[API.user.stages]: {
				$splice: [[stageIndex + 1, 0, newStage]]
			}
		});

		// Activate first case on the current stage
		newState = activatePanelSet(newState, panelSetIds[0]);

		return newState;
	},

	[Actions.swapGridItems]: (state, payload) => {
		const { stageId, index, newIndex } = payload;
		const stageIndex = findStageIndexById(state, stageId);
		const caseDragged = state[API.user.stages][stageIndex][API.stage.panelSets][index];
		const caseReplaced = state[API.user.stages][stageIndex][API.stage.panelSets][newIndex];

		const newState = update(state, {
			[API.user.stages]: {
				[stageIndex]: {
					[API.stage.panelSets]: {
						[newIndex]: {
							$set: caseDragged
						},
						[index]: {
							$set: caseReplaced
						}
					}
				}
			}
		});

		return newState;
	},

	[Actions.updateLayout]: (state, payload) => {
		const { panelSetId, grid, stage } = payload;
		let newState = state;

		if (stage) {
			const stageIndex = findStageIndexById(state, panelSetId);

			newState = update(newState, {
				[API.user.stages]: {
					[stageIndex]: {
						[API.stage.layout]: {
							grid: {
								$set: grid
							}
						}
					}
				}
			});
		}

		return newState;
	},

	// TODO: move these to uiState
	[Actions.openTaskManager]: (state, payload) => ({ ...state, [API.user.isTaskManagerOpen]: true, [API.user.taskManagerPanelSet]: payload.panelSetId }),
	[Actions.closeTaskManager]: (state) => ({ ...state, [API.user.isTaskManagerOpen]: null }),

	/*
	This one essentially creates the defaulStage with the dashboard panelset attached to it
	Plus the user as well
	*/
	[Actions.loadInitialElements]: (state, payload) => {

		const newState = { ...state };

		const stage = {
			[API.stage.id]: API.stage.ids.defaultStage,
			[API.stage.layout]: {},
			[API.stage.panelSets]: [API.panelSet.ids.dashboard]
		}



		newState[API.user.stages] = [stage];
		newState[API.user.currentStage] = stage.id;
		newState[API.user.currentPanelSet] = API.panelSet.ids.dashboard;
		newState[API.user.currentPanel] = API.panel.types.startShift;

		if (payload.isSupervisor) {
			newState[API.user.stages].push({
				[API.stage.id]: API.stage.ids.supervisor,
				[API.stage.layout]: {},
				[API.stage.panelSets]: [API.panelSet.ids.supervisor]
			});
			newState[API.user.currentStage] = API.stage.ids.supervisor;
			newState[API.user.currentPanelSet] = API.panelSet.ids.supervisor;
			newState[API.user.currentPanel] = API.panel.ids.supervisor;

		}


		return newState;
	},

	[Actions.activateTab]: (state, payload) => {

		const newState = { ...state };
		newState[API.user.currentPanel] = payload.currentPanel;
		newState[API.user.currentPanelSet] = payload.panelSetId;
		return newState;
	},

	[Actions.activatePanelSet]: (state, payload) => {
		return activatePanelSet(state, payload.panelSetId);
	},

	[Actions.moveToSupervisorBreak]: (state, payload) => {
		const newState = { ...state };
		newState[API.user.currentPanel] = API.panel.ids.supervisor;
		newState[API.user.currentPanelSet] = API.panelSet.ids.supervisor;
		newState[API.user.currentStage] = API.stage.ids.supervisor;
		return newState;
	},



}, DEFAULT_STATE[API.user._key]);

