import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import classNames from 'classnames';
import _ from 'lodash';

import API from '../../api/Api';

import './Waves.css';

export const WAVE_TYPES = {
	startShift: 'start-shift',
	incomingInteraction: 'incoming-interaction'
};

/**
*  Provides the background wave animation
*/
class Waves extends PureComponent {
	render() {
		const {phase, waveType} = this.props;

		const classes = classNames({
			'waves': true,
			[`waves--${waveType}`]: waveType ? true : false
		});

		return (
			<div className={classes} style={
				{
					backgroundPosition: `${phase}px bottom, ${-phase}px bottom, ${phase}px bottom, ${-phase}px bottom, left bottom`
				}
			} />
		);
	}

	static propTypes = {
		phase: PropTypes.number.isRequired,
		waveType: PropTypes.oneOf(_.values(WAVE_TYPES))
	};

	static defaultProps = {
		phase: 0
	};
}

const mapStateToProps = (state) => {
	return {
		phase: state[API.uiState._key][API.uiState.wavePhase]
	}
};

export default connect(mapStateToProps)(Waves);