import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Label extends PureComponent {
    render() {
      const {text} = this.props;

			return (
				<h1 style={{position: 'relative', textAlign: 'center'}}>{text}</h1>
			);
    }

    static propTypes = {
			text: PropTypes.string.isRequired
    };

    static defaultProps = {
			text: ''
		}
}

export default Label;
