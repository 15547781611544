import { defineMessages } from 'react-intl';

const messages = defineMessages({
	IncomingInteraction_WaitingTitle: {
		defaultMessage: 'Waiting for incoming interaction...',
		id: 'IncomingInteraction_WaitingTitle',
		description: 'Waiting for interaction / panel title'
	},

	IncomingInteraction_IncomingTitle: {
		defaultMessage: 'Incoming interaction',
		id: 'IncomingInteraction_IncomingTitle',
		description: 'Incoming interaction / panel title'
	},

	IncomingInteraction_ManualModeButton: {
		defaultMessage: 'Search mode',
		id: 'IncomingInteraction_ManualModeButton',
		description: 'Waiting for interaction / Search mode button label'
	},

	IncomingInteraction_BreakRequestButton: {
		defaultMessage: 'Request break',
		id: 'IncomingInteraction_BreakRequestButton',
		description: 'Waiting for interaction / break request button label'
	}
});

export default messages;