import {call, takeLatest, select} from 'redux-saga/effects';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { httpPost } from '../services/http';
/**
 * request to initiate an outbound call
 */
function* requestOutboundTeamsCall() {
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/ReserveForCallLookup?api-version=3";
	const authHeader = yield select(Selectors.getAuthHeader);
	try {
		yield call(httpPost, url, null, authHeader);
	} catch (error) {
		console.log(error);
	}
}

/**
 * request for closing the workItem
 * @param {*} action 
 */
function* closeWorkItem(action) {
	const authHeader = yield select(Selectors.getAuthHeader);
	const url = (yield select(Selectors.getBlenderServiceUrl)) + "/Resource/CompleteWorkItem?api-version=3";
	try {
		yield call(httpPost, url,  action.payload, authHeader);
	} catch (error) {
		console.log(error);
	}	
}


export default function* () {
	
	yield takeLatest(Actions.requestInitiateOutboundCall.getType(), requestOutboundTeamsCall)
	yield takeLatest(Actions.requestCancelOutboundCall.getType(), closeWorkItem)
	yield takeLatest(Actions.closeWorkItem.getType(), closeWorkItem)
}
