import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import './Logo.css';
import buzzeasy_logo_icon from '../../assets/i/buzzeasy_logo_b_blue.svg';
import buzzeasy_logo_long from '../../assets/i/buzzeasy_logo_full_blue.svg';
export const LOGO_PHASE = {
	fadein: 'fadein',
	moveup: 'moveup'
};

class Logo extends PureComponent {
	render() {
		const {logoPhase} = this.props;
		const classes = classNames({
			'logo': true,
			'logo--fade-in': logoPhase === LOGO_PHASE.fadein,
			'logo--move-up': logoPhase === LOGO_PHASE.moveup
		});

		return (
			<div className={classes}>

				<div className="logo__shape">
					<img src={buzzeasy_logo_icon} alt="Buzzeasy blue short icon"/>
				</div>

				<div className="logo__type">
					<img src={buzzeasy_logo_long} alt="Buzzeasy blue long logo"/>
				</div>

			</div>
		);
	}

	static propTypes = {
		logoPhase: PropTypes.oneOf(_.values(LOGO_PHASE))
	};
}

export default Logo;
