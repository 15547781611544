import {createReducer} from 'redux-act';
import * as Actions from '../../actions';
import ReduxModels from '../../api/ReduxModels';


export default createReducer({

	/**
	Marks the url as being loaded so we don't spam the backend
	 */
	[Actions.ScriptingUrlReceived]: (state, payload) => {

		const {workItemId, url} = payload;
		const newState = {...state};
		newState[workItemId] = {
			[ReduxModels.scripting.loading] : false,
			[ReduxModels.scripting.url] : url
		}
		return newState;
	},

}, {});