export const isSupervisor = (accessToken) => {
    const jwtPart = accessToken.split('.')[1];

    if (jwtPart) {
        let decodedStr = atob(jwtPart);
        let decodedObj = JSON.parse(decodedStr);
        let buzzPlusRole = decodedObj['buzzplus.role'];
        if (!buzzPlusRole) {
            return null;
        }
        return buzzPlusRole.toLowerCase() === 'supervisor';
    }
    return false;
}